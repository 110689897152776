import Cookies from "js-cookie";
import { CONFIG } from "./config";
import { addDays } from "date-fns";

export const setEncryptedCookie = (cookieKey: string, cookieValue: string): void => {
    
    try {
        const { ENCRYPTION_KEY } = CONFIG

        const encryptedCookieValue = encryptString(cookieValue, ENCRYPTION_KEY)
        Cookies.set(cookieKey, encryptedCookieValue, {
            secure: false,
            expires: addDays(new Date(), 7)
        })
    } catch (error: any) {
    }

}

export const getEncryptedCookie = (cookieKey: string): string => {
    try {
        const { ENCRYPTION_KEY } = CONFIG
        const encryptedCookieValue = Cookies.get(cookieKey)
        if (encryptedCookieValue) {
            return decryptString(encryptedCookieValue, ENCRYPTION_KEY)
        }
        return 'customer'
    } catch (error: any) {
        return 'customer'
    }
}

function encryptString(inputString : string, secretKey: any) {
    let encryptedString = '';
    for (let i = 0; i < inputString.length; i++) {
      const charCode = inputString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length);
      encryptedString += String.fromCharCode(charCode);
    }
    return encryptedString;
  }
  
  function decryptString(encryptedString: string, secretKey: any) {
    let decryptedString = '';
    for (let i = 0; i < encryptedString.length; i++) {
      const charCode = encryptedString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length);
      decryptedString += String.fromCharCode(charCode);
    }
    return decryptedString;
  }

async function encryptData(data: string, secretKey: any): Promise<string> {
    const key = await window.crypto.subtle.importKey(
        'raw',
        new Uint8Array(secretKey.match(/.{2}/g)!.map((byte: any) => parseInt(byte, 16))),
        { name: 'AES-CBC', length: 256 },
        true,
        ['encrypt']
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const encrypted = await window.crypto.subtle.encrypt(
        { name: 'AES-CBC', iv: iv },
        key,
        new TextEncoder().encode(data)
    );
    const encryptedArray = new Uint8Array(encrypted);
    const encryptedHex = Array.from(iv)
        .concat(Array.from(encryptedArray))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
    return encryptedHex;
}

// Function to decrypt data using the AES encryption algorithm and the secret key
async function decryptData(encryptedData: string, secretKey: any): Promise<string> {
    const key = await window.crypto.subtle.importKey(
        'raw',
        new Uint8Array(secretKey.match(/.{2}/g)!.map((byte: any) => parseInt(byte, 16))),
        { name: 'AES-CBC', length: 256 },
        true,
        ['decrypt']
    );
    const iv = new Uint8Array(
        encryptedData
            .substring(0, 32)
            .match(/.{2}/g)!
            .map(byte => parseInt(byte, 16))
    );
    const encrypted = new Uint8Array(
        encryptedData
            .substring(32)
            .match(/.{2}/g)!
            .map(byte => parseInt(byte, 16))
    );
    const decrypted = await window.crypto.subtle.decrypt(
        { name: 'AES-CBC', iv: iv },
        key,
        encrypted
    );
    const decryptedString = new TextDecoder().decode(decrypted);
    return decryptedString;
}
