import { DEFAULT_CREATIVE_TITLE_CHARACTER_LIMIT } from "./constants"

export const checkCharacterLimitAndSetFileName = (name: string): string => {
    if (name?.length > DEFAULT_CREATIVE_TITLE_CHARACTER_LIMIT) {
        return name?.substring(0, DEFAULT_CREATIVE_TITLE_CHARACTER_LIMIT);
    }
    return name
}


export const openUrlInNewTab = (url: string) => {
    window.open(url, '_blank');
}

export const getUrl = () => {
    return window.location.href;
}

export const getUtmValues = (url: string) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;
    
    const utm_source = searchParams.get('utm_source') || "";
    const utm_medium = searchParams.get('utm_medium') || "";
    const utm_campaign = searchParams.get('utm_campaign') || "";
    
    return {
      utm_source,
      utm_medium,
      utm_campaign,
    };
  };
  
  export const filterSelectedObjective = (objective: string): any => {
    const objectivesMap: { [key: string]: keyof typeof response } = {
        'Brand Awareness': 'is_brand_awareness',
        'Sales': 'is_sales',
        'App Promotion': 'is_app_promotion',
        'Email Leads': 'is_email_leads',
        'Website Traffic': 'is_website_traffic',
        'Social Following': 'is_social_following',
    };

    // Initialize all objectives to 0
    const response = {
        'is_brand_awareness': 0,
        'is_sales': 0,
        'is_app_promotion': 0,
        'is_email_leads': 0,
        'is_website_traffic': 0,
        'is_social_following': 0,
    };

    // Directly set the value to 1 for the matching objective, if exists
    const key = objectivesMap[objective];
    if (key) {
        response[key] = 1;
    }

    return response;
};

export const createRedirectUrl = (emailAddress:string, redirectUrlBase:string) => {
    const encodedEmail = encodeURIComponent(emailAddress);
    const redirectUrl = `${redirectUrlBase}${encodedEmail}`;
    return redirectUrl;
  }