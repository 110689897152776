import { gql } from '@apollo/client'

const mutationAddCreative = gql`
  mutation addCreative($url: String!, $size: String!, $name: String!, $creative_type: String!, $user_id: String!) {
    addCreative(url: $url, size: $size, name: $name, creative_type: $creative_type, user_id: $user_id) {
      _id
      name
      url
      creative_type
      size
      created_at
      updated_at
    }
  }
`
const queryCreativeList = gql`
  query creativeList($userId: String, $page: Float!, $limit: Float!, $campaignId: String) {
    creativeList(userId: $userId, page: $page, limit: $limit, campaignId: $campaignId) {
      creatives
      page
      limit
      total_records
    }
  }
`

const queryDeleteCreative = gql`
  query deleteCreative($_id: String,) {
    deleteCreative(_id: $_id, ) {
      _id
      name
      url
      size
      created_at
      updated_at
    }
  }
`
const mutationEditCreative = gql`
  mutation editCreative($_id: String!, $delete: Boolean, $url: String, $size: String, $name: String) {
    editCreative(_id: $_id, delete: $delete, url: $url, size: $size, name: $name) {
      _id
      name
      url
      creative_type
      size
      created_at
      updated_at
    }
  }
`

export { mutationAddCreative, queryCreativeList, queryDeleteCreative, mutationEditCreative }
