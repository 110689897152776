interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  align: 'left' | 'right' | 'center'
}

export const CreativeTableHeadCell: readonly HeadCell[] = [
  { id: 'name', disablePadding: true, label: 'Creative', align: 'left' },
  { id: 'size', disablePadding: true, label: 'Size', align: 'center' },
  { id: 'creative_type', disablePadding: true, label: 'Creative Type', align: 'center' }
]
export const CampaignHeadCell = [
  { id: 'name', disablePadding: true, label: 'Campaign', align: 'left' },
  { id: 'status', disablePadding: true, label: 'Status', align: 'center' },
  { id: 'objectives', disablePadding: true, label: 'Objectives', align: 'center' },
  { id: 'daily_budget', disablePadding: true, label: 'Daily Budget', align: 'center' },
  { id: 'budget_spend', disablePadding: true, label: 'Budget Spent', align: 'center' },
  { id: 'balance', disablePadding: true, label: 'Budget Remaining', align: 'center' },
  { id: 'total_cpc', disablePadding: false, label: 'CPC', align: 'center' },
  { id: 'total_ctr', disablePadding: true, label: 'CTR', align: 'center' },
  { id: 'Action', disablePadding: true, label: 'Action', align: 'center' }
]

export const UserHeadCell: readonly HeadCell[] = [
  { id: 'full_name', disablePadding: true, label: 'Customer', align: 'left' },
  { id: 'user_type', disablePadding: false, label: 'Type', align: 'left' },
  { id: 'updated_at', disablePadding: false, label: 'Last active date', align: 'left' },
  { id: 'spent_amount', disablePadding: false, label: 'Lifetime Spend', align: 'left' },
  { id: 'available_balance', disablePadding: false, label: 'Current Balance', align: 'left' },
  { id: 'id', disablePadding: false, label: 'Actions', align: 'center' }
]
