import store from '../..'
import * as DotGQL from '../../../services/graphQL'
import { isValidUrl, UserType } from '../../../util/constants'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'

import { loadingStart, loadingStop, notificationFail, notificationSuccess } from '../customization'
import { settingUser } from '../setting'
import {
  updateBalance,
  updateCampaign,
  updateCpmBid,
  updateCreativeGallery,
  updateDailyBudget,
  updateDestinationUrl,
  updateDevice,
  updateFinalDestinationUrl,
  updateGeo,
  updateHasApprovedOnce,
  updateHasChanges,
  updateImpression,
  updateInterest,
  updateMedium,
  updateName,
  updateObjective,
  updateOrigin,
  updatePlacement,
  updateSource,
  updateStatus,
  updateTimeFrame
} from './add'
import { getCampaignDetail } from './list'
export const EditCampaign = (client: any, req: any, navigate: any, draftCampaign: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationEditCampaign,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.ADD_CAMPAIGN_SUCCESS,
          data: response
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignEditSuccess))
        dispatch({
          type: actionTypes.RESET_CAMPAIGN
        })
        draftCampaign();
        dispatch(loadingStop())
        const user = store.getState().authReducer
        if (user?.userType === UserType.ADMIN || user?.userType === UserType.SUPER_ADMIN) {
          navigate('/')
        } else {
          navigate('/campaigns')
        }
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignFailed, error.message))
      })
  }
}

function getParameterByName(name: any, url: any) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const setAllNewCampData = (value: any) => {
  return (dispatch: any) => {
    dispatch(updateName(value.name))
    dispatch(updatePlacement(value.placement))
    dispatch(updateGeo(value.countries))
    dispatch(updateImpression(value.number_of_impression))
    dispatch(updateTimeFrame(value.time_frame))

    dispatch(updateDailyBudget(value.daily_budget))
    dispatch(updateCpmBid(value.cpm_bid))
    dispatch(updateObjective(value.objectives))
    dispatch(updateCreativeGallery(value.creative))
    dispatch(updateInterest(value.interest_categories))
    dispatch(updateBalance(value.balance))
    dispatch(updateDevice(value.device_targeting))
    dispatch(updateStatus(value.status))
    dispatch(updateHasApprovedOnce(value.has_approved_once))
    if (value?.utm_url) {
      // let pathmain = value?.utm_url?.split('?')[0]
      let pathmain = value?.utm_url
      let origin = 'https://'
      let val = ''
      if (isValidUrl(pathmain)) {
        const url: any = new URL(pathmain).origin
        if (url?.includes('https')) {
          origin = 'https://'
          val = pathmain.replace('https://', '')
        } else if (url?.includes('http')) {
          origin = 'http://'
          val = pathmain.replace('http://', '')
        }
      }
      dispatch(updateOrigin(origin))
      dispatch(updateDestinationUrl(val))
      dispatch(updateSource(''))
      // dispatch(updateMedium(getParameterByName('utm_medium', value?.utm_url)))
      // dispatch(updateCampaign(getParameterByName('utm_campaign', value?.utm_url)))
      dispatch(updateFinalDestinationUrl(value?.utm_url))
    }
    dispatch(updateHasChanges(false))
  }
}

export const ResetAddCampaignData = () => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_CAMPAIGN
    })
  }
}

export const getEditBalance = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationTransferBalance,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        if (req.transaction_type == 'credit') dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.transferBalance))
        else dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.addTransferBalance))

        const request = {
          campaign_id: req.camp_id
        }
        dispatch(getCampaignDetail(client, request) as any)
        dispatch(settingUser(client))
        dispatch({
          type: actionTypes.EDIT_BALANCE,
          data: response?.data
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.creativeAddFail, error.message))
      })
  }
}

export const addBalance = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    return client.mutate({
      mutation: DotGQL.mutationTransferBalance,
      fetchPolicy: 'no-cache',
      variables: req
    }).then((response: any) => {
      dispatch(loadingStop())
      if (req.transaction_type == 'credit') dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.transferBalance))
      else dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.addTransferBalance))

      dispatch({
        type: actionTypes.EDIT_BALANCE,
        data: response?.data
      })
    }).catch((error: any) => {
      dispatch(loadingStop())
      dispatch(notificationFail(NOTIFICATIONS.ERROR.operationFailed, error.message))
    })
  }

}
export const DraftCampaign = (client: any, req: any) => {
  return (dispatch: any) => {
    // dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationDraftCampaign,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.ADD_DRAFT_SUCCESS,
          payload: response?.data?.draftCampaign
        })
      })
      .catch((error: any) => {
        dispatch(notificationFail(NOTIFICATIONS.ERROR.draftCampaignFailed, error.message))
      })
  }
}

export const ClearDraft = () => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.CLEAN_UP_DRAFT
    })
  }
}