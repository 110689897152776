import { Box, Button, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCreativeListByCampaign } from '../../../../store/actions/Creative'
import AdvanceSection from './AdvanceSection'
import BasicSection from './BasicSection'
import BiddingSection from './BiddingSection'
import CreativeSection from './CreativeSection'
import NameSection from './NameSection'
import { CampaignStatus, UserType } from '../../../../util/constants'

const CampaignDetailsInfo = ({ campaignDetails }: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const client = useSelector((state: any) => state.apolloClient.client)
  const platFormUser = useSelector((state: any) => state.authReducer)
  // const campaignDetails = useSelector((state: any) => state.campaignDetailReducer.campaignDetail)

  const isChanged = (item: string): boolean => {

    // showing new screen to only admin
    if (platFormUser?.userType === UserType.CUSTOMER) {
      return false
    }

    if (campaignDetails?.previous_campaign_values === undefined || campaignDetails?.previous_campaign_values === null) {
      return false
    }

    // flagging items that have changed
    switch (item) {
      case 'name':
        return campaignDetails?.name !== campaignDetails?.previous_campaign_values?.name
      case 'countries':
        return (
          JSON.stringify(campaignDetails?.countries) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.countries)
        )
      case 'objectives':
        return (
          JSON.stringify(campaignDetails?.objectives) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.objectives)
        )
      case 'interest_categories':
        return (
          JSON.stringify(campaignDetails?.interest_categories) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.interest_categories)
        )
      case 'device_targeting':
        return (
          JSON.stringify(campaignDetails?.device_targeting) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.device_targeting)
        )
      case 'number_of_impression':
        return (
          JSON.stringify(campaignDetails?.number_of_impression) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.number_of_impression)
        )
      case 'time_frame':
        return (
          JSON.stringify(campaignDetails?.time_frame) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.time_frame)
        )
      case 'total_budget':
        return (
          JSON.stringify(campaignDetails?.total_budget) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.total_budget)
        )
      case 'daily_budget':
        return (
          JSON.stringify(campaignDetails?.daily_budget) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.daily_budget)
        )
      case 'cpm_bid':
        return (
          JSON.stringify(campaignDetails?.cpm_bid) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.cpm_bid)
        )
      case 'creative':
        return (
          JSON.stringify(campaignDetails?.creative) !==
          JSON.stringify(campaignDetails?.previous_campaign_values?.creative)
        )

      default:
        return false
    }
  }

  useEffect(() => {
    let req = {
      page: 1,
      limit: 100,
      campaignId: campaignDetails?._id
    }
    dispatch(getCreativeListByCampaign(client, req) as any)
  }, [campaignDetails])

  return (
    <Paper elevation={2} sx={{ m: 2, p: 2 }}>
      <NameSection
        name={campaignDetails?.name}
        status={campaignDetails?.status}
        user_type={platFormUser?.userType}
        channel={campaignDetails?.channel}
        request_type={campaignDetails?.request_type}
        isNameChanged={isChanged('name')}
      />
      <BasicSection
        countries={campaignDetails?.countries}
        objectives={campaignDetails?.objectives}
        interest_categories={campaignDetails?.interest_categories}
        isCountriesChanged={isChanged('countries')}
        isObjectivesChanged={isChanged('objectives')}
        isInterestCategoriesChanged={isChanged('interest_categories')}
      />

      <AdvanceSection
        device_targeting={campaignDetails?.device_targeting}
        number_of_impression={campaignDetails?.number_of_impression}
        time_frame={campaignDetails?.time_frame}
        isDeviceTargetingChanged={isChanged('device_targeting')}
        isNumberOfImpressionChanged={isChanged('number_of_impression')}
        isTimeFrameChanged={isChanged('time_frame')}
      />
      <BiddingSection
        balance={campaignDetails?.balance || 0}
        daily_budget={campaignDetails?.daily_budget || 0}
        cpm_bid={campaignDetails?.cpm_bid || 0}
        isTotalBudgetChanged={isChanged('total_budget')}
        isDailyBudgetChanged={isChanged('daily_budget')}
        isCpmBidChanged={isChanged('cpm_bid')}
      />
      <CreativeSection campaignId={campaignDetails?._id} isCreativeChanged={isChanged('creative')} />

      <Box display={'flex'} justifyContent={'flex-end'} gap={1}>
        <Button
          id="btnAnalytics"
          onClick={() => {
            navigate('/view-analytics/' + campaignDetails?._id)
          }}
          variant="outlined"
          color="inherit"
        >
          View Analytics
        </Button>
        <Button
          id="btnCampaign"
          onClick={() => {
            navigate('/edit-campaign/' + campaignDetails?._id)
          }}
          disabled={campaignDetails.status === CampaignStatus.APPROVED}
          variant="outlined"
          color="inherit"
        >
          Edit Campaign
        </Button>
      </Box>
    </Paper>
  )
}

export default CampaignDetailsInfo
