import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Transition } from '../../layout/ui-components/Transitions'
import { forgotPassword } from '../../store/actions'

const validateEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export default function ForgotPasswordDialog(props: any) {
  const { open, handleClose } = props
  const dispatch: any = useDispatch()
  const theme: any = useTheme()
  const [disableButton, setDisableButton] = React.useState(true)
  const [email, setEmail] = React.useState('')

  const handlePasswordReset = async (email: string) => {
    if (email) {
      dispatch(forgotPassword(email, handleClose))
    } else {
      alert('Please enter your email address')
    }
  }

  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value
    setEmail(email)
    if (validateEmail.test(email)) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      sx={{ maxWidth: '400px', margin: '0 auto' }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Box>
          <Typography variant="h2" fontSize="24px" sx={{ marginBottom: '5px', p: 0 }}>
            Forgot password?
          </Typography>
          <Box
            sx={{
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              marginTop: '10px',
              color: '#5A5A5A',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px'
            }}
          >
            No worries! Just enter your email and we'll send you a reset password link.
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 2, pb: 0 }}>
        <FormControl fullWidth required>
          <TextField
            id="UserEmail"
            type="email"
            variant="outlined"
            // onChange={(e: React.FormEvent<HTMLInputElement>)=>setEmail(e.currentTarget.value)}
            onChange={(event: any) => handleEmailChange(event)}
            placeholder="Email address"
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <FormControl fullWidth required>
          <Button
            id="submitForgot"
            onClick={() => handlePasswordReset(email)}
            type="submit"
            variant="contained"
            disabled={disableButton}
            sx={{
              width: '100% !important',
              height: '45px',
              background: theme.palette.secondary.green,
              '&:hover': {
                background: '#00e39cbf'
              }
            }}
          >
            Send Recovery Email
          </Button>
        </FormControl>
      </DialogActions>
    </Dialog>
  )
}
