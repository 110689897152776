import { Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { signup_initiated } from '../../../services/mixpanel'
const Email = process.env.PUBLIC_URL + '/images/email.webp'

export default function EmailLoginButton(props: any) {
  const theme = useTheme()
  const { setStepValue } = props

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box>
      <Button
        id="btn_email"
        disableElevation
        onClick={() => {
          setStepValue(3)
          signup_initiated({ signup_mode: 'email' })
        }}

        fullWidth
        size="large"
        variant="outlined"
        sx={{
          display: 'flex',
          width: '100% !important',
          borderRadius: '8px !important',
          boxShadow: '2px 2px 4px #ddd',
          borderColor: '#ddd',
          height: '45px',
          alignItems: 'center',
          color: '#7b7b7b',
          mb: 2,
          fontWight: '600'
        }}
      >
        <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
          <img
            src={Email}
            aria-label="For google"
            alt="not display google"
            width={20}
            height={18}
            style={{ marginRight: matchDownSM ? 8 : 16, marginTop: '6px' }}
          />
        </Box>
        Continue with Email
      </Button>
    </Box>
  )
}
