import { Box, Typography } from '@mui/material'
const GreenStar = process.env.PUBLIC_URL + '/images/greenstar.webp'

export default function TrustpilotRating(props: any) {
  return (
    <Box className="trustpilot" sx={{ display: 'flex', alignItems: 'center' }}>
      <img src={GreenStar} aria-label="For GreenStar" alt="not displayGreenStar" width="30" height="29" />
      <Typography variant="h2" sx={{ fontSize: '19px', marginTop: '5px', marginRight: '8px' }} color={props.color}>
        Trustpilot
      </Typography>
      <Box className="rating-Star">
        <span style={{ color: '#F59E0B' }}>&#9733;</span>
        <span style={{ color: '#F59E0B' }}>&#9733;</span>
        <span style={{ color: '#F59E0B' }}>&#9733;</span>
        <span style={{ color: '#F59E0B' }}>&#9733;</span>
        <span style={{ color: '#F59E0B' }}>&#9733;</span>
      </Box>
      <Typography
        variant="h5"
        sx={{ marginTop: '4px', marginLeft: '5px', fontWeight: '600' }}
        color={props.ratingColor}
      >
        4.5 Stars
      </Typography>
    </Box>
  )
}
