import { Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { LoginAuthProvider } from '../../../types/auth'
const Google = process.env.PUBLIC_URL + '/images/social-google.webp'

interface propType {
  setStepValue: number
  handleClose: () => void
  handleLoginOrSignUp: (provider: LoginAuthProvider, args?: { email: string; password: string }) => void
}

export default function GoogleLoginButton(props: any) {
  const theme = useTheme()
  const { handleLoginOrSignUp, setStepValue } = props

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box>
      <Button
        id="GoogleLoginButton"
        disableElevation
        onClick={() => handleLoginOrSignUp(LoginAuthProvider.Google)}
        fullWidth
        size="large"
        variant="outlined"
        sx={{
          display: 'flex',
          width: '100% !important',
          borderRadius: '8px !important',
          boxShadow: '2px 2px 4px #ddd',
          borderColor: '#ddd',
          height: '45px',
          alignItems: 'center',
          color: '#7b7b7b',
          fontWight: '600'
        }}
      >
        <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
          <img
            src={Google}
            aria-label="For google"
            alt="not displaygoogle"
            width={18}
            height={18}
            style={{ marginRight: matchDownSM ? 8 : 16, marginTop: '8px' }}
          />
        </Box>
        Log In with Google
      </Button>
    </Box>
  )
}
