import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmDialog(props: any) {
  const { open, handleClose, onConform } = props

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 'auto'
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{'Log out'}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mr: 4 }} id="alert-dialog-slide-description">
          Are you sure you want to Log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="btnCancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button id="btnConfirm" variant="contained" color="primary" onClick={onConform}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
