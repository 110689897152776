export const NOTIFICATIONS = {
  ERROR: {
    someThingWrong: 'Something went wrong',
    addNewUserFail: 'New user has not been added',
    passwordSentFail: 'Password has not been sent',
    passwordChangeFail: 'Password has not been changed. Please try again later',
    tooManyAttempts: 'Too many attempts! Please try again later',
    userStatusChangeFail: 'Status has not been changed. Please try again later',
    creativeAddFail: 'Creative could not be added',
    userNotActive: 'User not active! Please contact admin for activation',
    addTransactionFail: 'Transaction has not been added',
    creativeFetchFail: 'An error occurred while fetching creatives',
    deleteCreativeFail: 'Creative could not be deleted. Please try again later',
    editCreativeFail: 'Creative could not be edited!',
    userDetailsUpdateFail: 'User details could not be updated',
    userDeletedFail: 'User has not been deleted',
    cardAddFail: 'Card could not be added',
    userDisabled: 'Your account has been disabled',
    objectivesFail: 'An error occurred while fetching objectives',
    interestingFail: 'An error occurred while fetching interest categories',
    campaignListFail: 'An error occurred while fetching campaign list',
    campaignDetailFail: 'An error occurred while updating campaign',
    operationFailed: "Operation Failed",
    updateCampaignFailed: 'An Error Occurred while updating campaign',
    draftCampaignFailed: 'An Error Occurred while drafting campaign',
    addCampaignFailed: 'Campaign has not been added',
    deleteCampaignFailed: 'Campaign not deleted! Please try again later',
    draftSavedFailed: 'Draft was not saved',
    updateCampaignStatusFailed: 'Campaign status could not be updated',
  },
  SUCCESS: {
    campaignSpentFetched: 'Campaign spent fetched successfully',
    campaignStatusFetched: 'Campaign status fetched successfully',
    copyCampaignSuccess: 'Campaign copied successfully',
    loginSuccess: 'You are successfully logged in',
    editUserSuccess: 'Your account details have been saved',
    signupSuccess: 'You are successfully signed up',
    addNewUserSuccess: 'New user has been added',
    passwordSentSuccess: 'Password has been sent! Please check your email',
    passwordChangeSuccess: 'Password has been changed! Please login again',
    emailVerificationMailSent: 'Email verification mail has been sent! Please check your email',
    userStatusChange: 'Status updated successfully',
    creativeAddSuccess: 'Creative added successfully',
    addTransactionSuccess: 'Transaction details have been saved',
    creativeDeleteSuccess: 'Creative deleted',
    editCreativeSuccess: 'Creative updated',
    userDetailsUpdateSuccess: 'User details updated',
    userDeletedSuccess: 'User has been deleted',
    cardAddSuccess: 'Card added successfully',
    cardDeleteSuccess: 'Card was deleted successfully',
    campaignStatusChange: 'Campaign status updated',
    campaignAddSuccess: 'Campaign added',
    campaignEditSuccess: 'Campaign updated',
    campaignDeleteSuccess: 'Campaign deleted',
    updateCommissionCampaign: 'Commission updated',
    addTransferBalance: 'Update balance successful',
    transferBalance: 'Transfer balance successful',
    draftSaved: 'Draft Saved',
    campaignPaused: 'Campaign Paused',
    campaignResumed: 'Campaign Resumed',
  },
  WARNINGS: {
    campaignPauseRequired: 'Pause campaign before transferring balance',
    transferBalance: 'Transfer campaign balance before deleting',
    campaignBalanceZero: 'Insufficient campaign balance',
    campaignBalanceLowForGam: 'Insufficient campaign balance',
    cannotEditInThisStatus: 'Cannot edit in this status',
  }
}
