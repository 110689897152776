import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from '.././customization'

export const AddNewCampaign = (client: any, req: any, navigate: any, draftCampaign: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationAddCampaign,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.ADD_CAMPAIGN_SUCCESS,
          data: response
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignAddSuccess))
        dispatch({
          type: actionTypes.RESET_CAMPAIGN
        })
        draftCampaign();
        dispatch(loadingStop())
        navigate('/campaigns')
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addCampaignFailed, error.message))
      })
  }
}
export const getCopyCampaign = (client: any, req: any, navigate: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryCopyCampaign,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.COPY_CAMPAIGN_SUCCESS,
          data: response
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.copyCampaignSuccess))
        dispatch(loadingStop())
        navigate('/campaigns')
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const updateName = (value: any) => {
  return {
    type: actionTypes.ADD_NAME,
    payload: value
  }
}

export const updatePlacement = (value: any) => {
  return {
    type: actionTypes.ADD_PLACEMENT,
    payload: value
  }
}

export const updateGeo = (value: any) => {
  return {
    type: actionTypes.ADD_GEO,
    payload: value
  }
}

export const updateDevice = (value: any) => {
  return {
    type: actionTypes.ADD_DEVICE,
    payload: value
  }
}

export const updateImpression = (value: any) => {
  return {
    type: actionTypes.ADD_IMPRESSION,
    payload: value
  }
}

export const updateTimeFrame = (value: any) => {
  return {
    type: actionTypes.ADD_TIME_FRAME,
    payload: value
  }
}

export const updateDailyBudget = (value: number | string) => {
  return {
    type: actionTypes.ADD_DAILY_BUDGET,
    payload: value
  }
}

export const updateObjective = (value: any) => {
  return {
    type: actionTypes.SELECTED_OBJECTIVE,
    payload: value
  }
}

export const updateInterest = (value: any) => {
  return {
    type: actionTypes.SELECTED_INTEREST,
    payload: value
  }
}

export const updateCpmBid = (value: number | string) => {
  return {
    type: actionTypes.ADD_CPM_BID,
    payload: value
  }
}

export const updateCreativeGallery = (value: any) => {
  return {
    type: actionTypes.ADD_CREATIVE_GALLERY,
    payload: value
  }
}

export const updateDestinationUrl = (value: any) => {
  return {
    type: actionTypes.ADD_DESTINATION_URL,
    payload: value
  }
}

export const updateSource = (value: any) => {
  return {
    type: actionTypes.ADD_SOURCE,
    payload: value
  }
}

export const updateOrigin = (value: any) => {
  return {
    type: actionTypes.ADD_ORIGIN,
    payload: value
  }
}

export const updateMedium = (value: any) => {
  return {
    type: actionTypes.ADD_MEDIUM,
    payload: value
  }
}

export const updateCampaign = (value: any) => {
  return {
    type: actionTypes.ADD_CAMPAIGN,
    payload: value
  }
}

export const updateTerm = (value: any) => {
  return {
    type: actionTypes.ADD_TERM,
    payload: value
  }
}

export const updateFinalDestinationUrl = (value: any) => {
  return {
    type: actionTypes.ADD_FINAL_DESTINATION_URL,
    payload: value
  }
}

export const updateBalance = (value: number) => {
  return {
    type: actionTypes.ADD_BALANCE,
    payload: value
  }
}

export const updateCampaignName = (value: any) => {
  return {
    type: actionTypes.UPDATE_COPY_NAME,
    payload: value
  }
}

export const updateCampaignId = (value: any) => {
  return {
    type: actionTypes.UPDATE_COPY_ID,
    payload: value
  }
}

export const SelectRegion = (response: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.SELECTED_REGION,
      payload: response
    })
  }
}

export const updateStatus = (value: any) => {
  return {
    type: actionTypes.ADD_STATUS,
    payload: value
  }
}

export const updateHasApprovedOnce = (value: any) => {
  return {
    type: actionTypes.ADD_HAS_APPROVED_ONCE,
    payload: value
  }
}

export const updateHasChanges = (value: any) => {
  return {
    type: actionTypes.ADD_HAS_CHANGES,
    payload: value
  }
}
