import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import { login, signUpWithEmail } from '../../store/actions'
import { LoginAuthProvider } from '../../types/auth'
import LoginForm from './LoginForm'

export default function LoginWithEmail(props: any) {
  const { handleClose, step, setStepValue } = props

  const dispatch = useDispatch()

  const handleLoginOrSignUp = async (provider: LoginAuthProvider, args?: { email: string; password: string }) => {
    if (step === 3) {
      dispatch(signUpWithEmail(args) as any)
    } else {
      dispatch(login(provider, args) as any)
    }
  }

  return (
    <Box>
      <Box
        className="modal-header"
        sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '20px' }}
      >
        <Box>
          <Typography variant="h3" sx={{ fontSize: '24px' }}>
            Log in or sign up in seconds
          </Typography>
          <Box
            sx={{
              fontWeight: '600',
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              marginTop: '10px',
              color: '#5A5A5A',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px'
            }}
          >
            Select an option to continue
          </Box>
        </Box>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <LoginForm step={step} handleLoginOrSignUp={handleLoginOrSignUp} />
      <Box
        sx={{
          fontWeight: '600',
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          p: 2,
          color: '#5A5A5A',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px'
        }}
      >
        Don’t have an account?
        <Box onClick={() => setStepValue(2)} style={{ textDecoration: 'none', padding: '0 5px', color: '#673ab7' }}>
          Sign Up
        </Box>
      </Box>
    </Box>
  )
}
