import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail } from '../customization'

export const getCampaignAnalyticsData = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.getCampaignAnalytics,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_ANALYTICS_CAMPAIGN_SUCCESS,
          payload: response?.data?.campaignAnalytics?.data
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addCampaignFailed, error.message))
      })
  }
}
