import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useRoutes } from 'react-router-dom'
import MinimalLayout from '../layout/MinimalLayout'
import { logoutUser } from '../store/actions'

export default function AuthenticationRoutes() {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Cookies.get('dot-ad-token')) {
      navigate('/')
      dispatch(logoutUser())
    }
  }, [])
  const list = {
    path: '/',
    element: <MinimalLayout />,
    children: [{ path: '*', element: <MinimalLayout /> }]
  }
  return useRoutes([list])
}
