import DoneIcon from '@mui/icons-material/Done'
import { Box, Divider, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCreativeListByCampaign } from '../../../../store/actions/Creative'
import Creative from './creative'
import { CampaignStatus } from '../../../../util/constants'

const CreativeSection = ({ campaignId, isCreativeChanged }: any) => {
  const dispatch = useDispatch()
  const client = useSelector((state: any) => state.apolloClient.client)

  useEffect(() => {
    let req = {
      page: 1,
      limit: 100,
      campaignId: campaignId
    }
    dispatch(getCreativeListByCampaign(client, req) as any)
  }, [])

  const getTitle = (name: string) => {
    return (
      <Grid item sx={{ fontSize: '16px', display: 'flex', fontWeight: 'bold' }} md={4}>
        <DoneIcon color="success" sx={{ mr: 2 }} />
        <Box display={'flex'} flexDirection={'column'} sx={isCreativeChanged ? Style.chip : Style.noChip}>
          <Box>{name}</Box>
        </Box>
      </Grid>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        {getTitle('Creative Name')}
        <Grid item sx={{ mt: { xs: 2, md: 0 }, mb: { xs: 4, md: 0 }, width: '100%' }}>
          <Creative />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default CreativeSection

const Style = {
  item: {
    pt: { xs: '30px', md: '0' },
    pb: { xs: '30px', md: '0' },
    width: '100%',
    '.MuiChip-root': { fontSize: '16px' }
  },
  itemName: { fontSize: '16px', display: 'flex', fontWeight: 'bold' },
  chip: {
    display: 'inline-block',
    padding: '0.5em',
    borderRadius: '999px',
    backgroundColor: '#EDA6A6'
    // color: '#333',
    // fontSize: '0.9em',
    // marginRight: '0.5em'
  },
  noChip: {}
}
