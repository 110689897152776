// login action

import { sendNewPaymentEventToGTM } from '../../services/googleTagManager'
import * as DotGQL from '../../services/graphQL'
import { NOTIFICATIONS } from '../../util/notification'
import { EDIT_USER_SUCCESS, SETTING_USER_SUCCESS } from '../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from './customization'

export const settingUser = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryUserDetails,
        fetchPolicy: 'no-cache'
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: response?.data?.userDetails
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const getUserDetailSendGtmTrigger = (client: any) => {
  return (dispatch: any) => {
    client
      .query({
        query: DotGQL.queryUserDetails,
        fetchPolicy: 'no-cache'
      })
      .then((response: any) => {
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: response?.data?.userDetails
        })
        let data = {
          userEmail: response?.data?.userDetails?.email
        }
        sendNewPaymentEventToGTM(data)
      })
      .catch((error: any) => {
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const editUser = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryUpdate,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.editUserSuccess))
        dispatch({
          type: EDIT_USER_SUCCESS,
          data: response?.data?.editProfile
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addNewUserFail, error.message))
      })
  }
}

export const editUserWithoutNotification = (client: any, req: any) => {
  return (dispatch: any) => {
    // dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryUpdate,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        // dispatch(loadingStop())
        // dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.editUserSuccess))
        dispatch({
          type: EDIT_USER_SUCCESS,
          data: response?.data?.editProfile
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        // dispatch(notificationFail(NOTIFICATIONS.ERROR.addNewUserFail, error.message))
      })
  }
}

export const editUserAccount = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryUpdate,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.editUserSuccess))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addNewUserFail, error.message))
      })
  }
}