import ComputerIcon from '@mui/icons-material/Computer'
import DoneIcon from '@mui/icons-material/Done'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid'
import { Box, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FaAndroid, FaApple, FaLaptop, FaLinux, FaWindows } from 'react-icons/fa'

const AdvanceSection = ({
  device_targeting,
  number_of_impression,
  time_frame,
  isDeviceTargetingChanged,
  isNumberOfImpressionChanged,
  isTimeFrameChanged
}: any) => {
  const theme: any = useTheme()

  const getTitle = (name: string) => {
    return (
      <Grid item sx={{ fontSize: '16px', display: 'flex', fontWeight: 'bold' }} md={4}>
        <DoneIcon color="success" sx={{ mr: 2 }} />
        <Box
          sx={isDeviceTargetingChanged || isNumberOfImpressionChanged || isTimeFrameChanged ? Style.chip : Style.noChip}
        >
          {name}
        </Box>
      </Grid>
    )
  }

  const labelCss = {
    marginBottom: 1,
    color: theme.palette?.grey[500]
  }
  const IconButtonDesign = (name: any, icon: any) => {
    return (
      <Box
        key={'display' + name}
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: 2,
          m: 1,
          p: 1,
          border: '1px solid #EAECF0',
          color: theme.palette?.primary.main
        }}
      >
        {icon}
        <Box sx={{ ml: 1 }}> {name}</Box>
      </Box>
    )
  }

  //  desktop: ['Windows', 'Linux ', 'Mac OS'],
  //mobile: ['Android', 'iOS ', 'Other']

  const filterMobileDeviceIcon = (device: string) => {
    if (['Android', 'iOS', 'Other'].includes(device)) {
      switch (device) {
        case 'Android':
          return IconButtonDesign('Android', <FaAndroid />)
        case 'iOS':
          return IconButtonDesign('iOS', <FaApple />)
        case 'Other':
          return IconButtonDesign('Other', <FaApple />)
      }
    }
  }
  const filterWebDeviceIcon = (device: string) => {
    if (['Windows', 'Linux', 'Mac OS'].includes(device)) {
      switch (device) {
        case 'Windows':
          return IconButtonDesign('Windows', <FaWindows />)
        case 'Linux':
          return IconButtonDesign('Linux', <FaLinux />)
        case 'Mac OS':
          return IconButtonDesign('Mac OS', <FaLaptop />)
      }
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        {getTitle('Advanced Targeting')}
        <Grid item sx={{ width: '100%' }}>
          <Box>
            <Box>
              <Box
                sx={
                  isDeviceTargetingChanged
                    ? { ...labelCss, color: '#C10202' }
                    : { ...labelCss, color: theme.palette?.grey[500] }
                }
              >
                Device Targeting{' '}
              </Box>
              <Box>
                <Grid container sx={{ alignItems: 'center', marginBottom: '10px', marginTop: '15px' }}>
                  <Grid item xs={6} md={6} sx={{ fontSize: '16px', fontWeight: '500', color: '#19181A' }}>
                    Mobile
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="right" color="#000000">
                    <TabletAndroidIcon />
                  </Grid>
                </Grid>
                <Box sx={{ display: { md: 'flex', lg: 'flex', xs: 'inline-table' } }}>
                  {device_targeting?.map(
                    (device: string, index: number) =>
                      ['Android', 'iOS', 'Other'].includes(device) && filterMobileDeviceIcon(device)
                  )}
                </Box>

                <Grid container sx={{ alignItems: 'center', marginBottom: '10px', marginTop: '15px' }}>
                  <Grid item xs={6} md={6} sx={{ fontSize: '16px', fontWeight: '500', color: '#19181A' }}>
                    Desktop
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="right" color="#000000">
                    <ComputerIcon />
                  </Grid>
                </Grid>
                <Box sx={{ display: { md: 'flex', lg: 'flex', xs: 'inline-table' } }}>
                  {device_targeting?.map(
                    (device: string, index: number) =>
                      ['Windows', 'Linux', 'Mac OS'].includes(device) && filterWebDeviceIcon(device)
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box
                sx={
                  isTimeFrameChanged || isNumberOfImpressionChanged
                    ? { ...labelCss, color: '#C10202' }
                    : { ...labelCss, color: theme.palette?.grey[500] }
                }
              >
                Frequency Cap
              </Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Box
                  sx={{
                    mr: 2,
                    p: 1,
                    borderRadius: 2,
                    border: '1px solid #EAECF0',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {number_of_impression}
                </Box>
                <Box
                  sx={{
                    mr: 2,
                    p: 1,
                    borderRadius: 2,
                    border: '1px solid #EAECF0',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {time_frame}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default AdvanceSection

const Style = {
  chip: {
    // display: 'inline-block',
    borderRadius: '999px',
    padding: '0.5em',

    backgroundColor: '#EDA6A6'
  },
  noChip: {}
}
