// action - state management
import * as actionTypes from '../../actionTypes'

export const initialState = {
  dashboardCount: {}
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const dashboardCountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ADMIN_DASHBOARD:
      return {
        ...state,
        dashboardCount: action.data
      }
    default:
      return state
  }
}

export default dashboardCountReducer
