import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import SnackbarOrigin from './layout/ui-components/SnackbarOrigin'
import AdminRoutes from './routes/AdminRoutes'
import AuthenticationRoutes from './routes/AuthenticationRoutes'
import MainRoutes from './routes/MainRoutes'
import { getCountryList, getInterestList, getObjectiveList, settingUser } from './store/actions'
import mixpanel from 'mixpanel-browser';
import { config } from './services/mixpanel/config'
import { GoogleOAuthProvider } from '@react-oauth/google';

// defaultTheme
import themes from './themes'
import { UserType } from './util/constants'
import { OnBoardingRoutes } from './routes/OnBoardingRoutes'
import { CLIENT_ID } from './services/googleOneTapLogin/config'

mixpanel.init(config.project_token, config.project_config);

function App() {
  const dispatch: any = useDispatch()
  const user: any = useSelector((state: any) => state.authReducer)
  const [loading, setLoading] = useState(true)
  const client = useSelector((state: any) => state.apolloClient.client)


  useEffect(() => {
    if (Cookies.get('dot-ad-token')) {
      dispatch(settingUser(client))
    }
  }, [])

  useEffect(() => {
    if (user && Cookies.get('dot-ad-token')) {
      dispatch(getObjectiveList(client))
      dispatch(getInterestList(client))
      dispatch(getCountryList(client))
    }
  }, [user])

  useEffect(() => {
    const el = document.querySelector('.loader-container')
    if (el) {
      el.remove()
      setLoading(!loading)
    }
  }, [])

  if (loading) {
    return null
  }

  const renderRoutes = () => {
    const { isLoggedIn, userType, isNewUser, onboarding_flow_variant } = user;
    return isLoggedIn ? (
      userType === UserType.ADMIN || userType === UserType.SUPER_ADMIN
        ? <AdminRoutes />
        : isNewUser ? <OnBoardingRoutes variant={onboarding_flow_variant} /> : <MainRoutes />
    ) : <AuthenticationRoutes />;
  };


  return (
    <ThemeProvider theme={themes()}>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <BrowserRouter basename="/">
          <Box>
            <SnackbarOrigin />
            {renderRoutes()}
          </Box>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

export default App
