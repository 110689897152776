import { Box, Typography } from '@mui/material'
import UserAvatar from './UserAvatar'

export default function UserDetail({ profile_image, name, email }: any) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ padding: '16px 12px' }}>
        <UserAvatar name={name ? name : email} profile_image={profile_image} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px', color: '#101828' }}>
          {name}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px', color: '#475467' }}>
          {email}
        </Typography>
      </Box>
    </Box>
  )
}
