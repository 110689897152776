import MailIcon from '@mui/icons-material/Mail'
import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-scroll'
import FooterLogo from '../FooterLogo'

export default function TopFooter() {
  const theme: any = useTheme()

  return (
    <Box>
      <Grid container sx={{ padding: { xs: '50px 0px 0px', md: '110px 0px 40px' } }}>
        <Grid item xs={12} md={4} sx={{ pr: { xs: '0', md: 4 }, mb: { xs: '60px', md: 0 } }}>
          <Box>
            {/* <FooterLogo /> */}
            {/* <Typography variant="h5" sx={{ fontSize: '18px', lineHeight: '32px', mb: '30px', color: '#000000' }}>
              Grow Your Web3 Business. Converting audiences in Crypto, Gaming, Finance, Tech
            </Typography> */}
            <p style={{ textAlign: 'center' }}>
              <iframe
                style={{
                  border: '1px solid #EEE',
                  background: 'white',
                  width: '100%',
                  maxWidth: '480px',
                  height: '375px',
                  overflow: 'hidden'
                }}
                src="https://blog.dotaudiences.com/embed"
                title="Embedded Content"
              />
            </p>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={6} md={3}>
              <Typography variant="h4" sx={{ color: '#12141D', fontWeight: '600' }}>
                Advertisers
              </Typography>
              <List>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <Link
                    offset={-60}
                    activeClass="active"
                    to="Features"
                    spy={true}
                    smooth={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Features
                  </Link>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <Link
                    offset={-60}
                    activeClass="active"
                    to="Testimonials"
                    spy={true}
                    smooth={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Testimonials
                  </Link>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href="https://dotaudiences.com/advertise/pr" target="_blank" rel="noreferrer">
                    PR Articles
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <Link
                    offset={-60}
                    activeClass="active"
                    to="AboutUs"
                    spy={true}
                    smooth={true}
                    style={{ cursor: 'pointer' }}
                  >
                    About Us
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="h4" sx={{ color: '#12141D', fontWeight: '600' }}>
                Publishers
              </Typography>
              <List>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href="https://publisher.dotaudiences.com/login" target="_blank" rel="noreferrer">
                    Join our network
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href="https://dotaudiences.com/publisher-solutions/overview" target="_blank" rel="noreferrer">
                    Learn more
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="h4" sx={{ color: '#12141D', fontWeight: '600' }}>
                Support
              </Typography>
              <List>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href="https://dotaudiences.com/blog" target="_blank" rel="noreferrer">
                    Blog
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href="https://dotaudiences.com/advertiser-terms-of-service" target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={{ color: '#12141D', pl: 0 }}>
                  <a href=" https://dotaudiences.com/advertising-guidelines" target="_blank" rel="noreferrer">
                    Advertiser Guidelines
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="h4" sx={{ color: '#12141D', fontWeight: '600' }}>
                Contact Info
              </Typography>
              <List>
                <ListItem sx={{ color: '#12141D', pl: 0 }}>London, UK</ListItem>
                <ListItem sx={{ color: '#12141D', pl: 0 }}>
                  <MailIcon
                    sx={{
                      color: theme.palette.secondary.green,
                      marginRight: '7px',
                      width: '20px',
                      height: '20px',
                      '@media (max-width:767px)': {
                        marginRight: '2px',
                        width: '15px',
                        height: '15px'
                      }
                    }}
                  />
                  <a
                    className="mail-link"
                    href="mailto:hello@dotaudiences.com"
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      color: '#000000',
                      fontWeight: '600',
                      textDecoration: 'underline !important'
                    }}
                  >
                    hello@dotaudiences.com
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#000000', opacity: '0.1', mt: { xs: '0', md: 3 } }} />
    </Box>
  )
}
