// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
import DashboardIcon from '@mui/icons-material/Dashboard'
import { BiBarChartSquare } from 'react-icons/bi'
import { RiHome3Line } from 'react-icons/ri'

import { IoWalletOutline } from 'react-icons/io5'

const pages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'menu-dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: <RiHome3Line />,
      url: '/dashboard',
      breadcrumbs: false
    },

    {
      id: 'menu-campaigns',
      title: 'Campaigns',
      type: 'item',
      url: '/campaigns',
      icon: <BiBarChartSquare />,
      breadcrumbs: false
    },
    {
      id: 'menu-gallery',
      title: 'Creative Gallery',
      type: 'item',
      url: '/creative-gallery',
      icon: <DashboardIcon />,
      breadcrumbs: false
    },
    {
      id: 'menu-balance',
      title: 'Balance',
      type: 'item',
      url: '/balance',
      icon: <IoWalletOutline />,
      breadcrumbs: false
    }
  ]
}

export default pages
