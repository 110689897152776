// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ListIcon from '@mui/icons-material/List'
import { RiHome3Line } from 'react-icons/ri'

const adminPages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'menu-dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: <RiHome3Line />,
      url: '/dashboard',
      breadcrumbs: false
    },

    {
      id: 'menu-users',
      title: 'Accounts',
      type: 'item',
      url: '/accounts',
      icon: <AccountCircleOutlinedIcon />,
      breadcrumbs: false
    },
    {
      id: 'menu-requests',
      title: 'Requests',
      type: 'item',
      url: '/requests',
      icon: <DashboardIcon />,
      breadcrumbs: false
    },
    {
      id: 'menu-transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: <ListIcon />,
      breadcrumbs: false
    }
  ]
}

export default adminPages
