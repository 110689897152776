// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

interface actionType {
  type: string
  payload: any
}

export const initialState = {
  userDetail: null,
  loading: true,
  error: null
}

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.GETTING_USER_SUCCESS:
      return updateObject(state, {
        loading: false,
        userDetail: action.payload,
        error: null
      })

    case actionTypes.GETTING_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        userDetail: null,
        error: action.payload
      })

    default:
      return state
  }
}

export default accountReducer
