import Cookies from 'js-cookie'
import { lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useRoutes } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import Loadable from '../layout/ui-components/Loadable'
import { logoutUser } from '../store/actions'
import DetailCampaign from '../views/campaigns/detail-campaign'
import SearchResults from '../views/search'

const Dashboard = Loadable(lazy(() => import('../views/user/dashboard')))
const CreativeGallery = Loadable(lazy(() => import('../views/creative-gallery')))
const Balance = Loadable(lazy(() => import('../views/balance')))
const Settings = Loadable(lazy(() => import('../views/settings')))
const PageNotFound = Loadable(lazy(() => import('../views/extra/NotFound')))
const Campaigns = Loadable(lazy(() => import('../views/campaigns')))
const Analytics = Loadable(lazy(() => import('../views/view-analytics')))
const CopyFromExisting = Loadable(lazy(() => import('../views/campaigns/copy-from-existing')))
const AddCampaigns = Loadable(lazy(() => import('../views/campaigns/add-campaigns')))

// ==============================|| MAIN ROUTING ||============================== //

export default function MainRoutes() {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Cookies.get('dot-ad-token')) {
      navigate('/')
      dispatch(logoutUser())
    }
  }, [])

  const list = {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'campaigns', element: <Campaigns /> },
      { path: 'add-campaigns', element: <AddCampaigns /> },
      { path: 'copy-campaign', element: <AddCampaigns FromOld={true} /> },
      { path: 'edit-campaign/:id', element: <AddCampaigns /> },
      { path: 'detail-campaign/:id', element: <DetailCampaign /> },
      { path: 'view-analytics/:id', element: <Analytics /> },
      { path: 'add-campaigns-from-existing', element: <CopyFromExisting /> },
      { path: 'creative-gallery', element: <CreativeGallery /> },
      {
        path: 'balance',
        element: <Balance />,
        children: [{ path: ':id', element: <Balance /> }]
      },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <PageNotFound /> },
      { path: '/searchResults', element: <SearchResults /> }
    ]
  }
  return useRoutes([list])
}
