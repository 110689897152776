import { TableCell, TableHead, TableRow } from '@mui/material'

interface EnhancedTableProps {
  headCells: any
}
function SearchTableHead(props: EnhancedTableProps) {
  const { headCells } = props
  return (
    <TableHead className="campaign-table-head-body" sx={{ background: '#F9FAFB' }}>
      <TableRow className="campaign-table-row">
        {headCells.map((headCell: any) => (
          <TableCell
            sx={{ p: 1 }}
            className="campaign-table-cell"
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default SearchTableHead
