import { gql } from '@apollo/client'

const mutationAddPayment = gql`
  mutation addPaymentLink($amount: Float!, $user_id: String!) {
    addPaymentLink(amount: $amount, user_id: $user_id)
  }
`
const mutationAddRecurringPayment = gql`
  mutation addRecurringPaymentLink($amount: Float!) {
    addRecurringPaymentLink(amount: $amount)
  }
`

const mutationManageSubscriptionLink = gql`
  mutation manageSubscriptionLink {
    manageSubscriptionLink
  }
  `
const queryAddCryptoPaymentLink = gql`
query addCryptoPaymentLink($amount: Float!,$uk_resident:Boolean!) {
  addCryptoPaymentLink(amount: $amount, uk_resident: $uk_resident)
}
`
const queryTransactionList = gql`
  query transactionList(
    $type: String
    $search: String
    $isExport: Boolean
    $order: String
    $columnName: String
    $page: Float!
    $limit: Float!
    $userId: String
  ) {
    transactionList(
      type: $type
      search: $search
      isExport: $isExport
      order: $order
      columnName: $columnName
      page: $page
      limit: $limit
      userId: $userId
    ) {
      transactions
      page
      limit
      total_records
    }
  }
`

const mutationTransferBalance = gql`
  mutation transferBalance($balance: Float!, $type: String!, $camp_id: String!) {
    transferBalance(balance: $balance, type: $type, camp_id: $camp_id) {
      message
      data
      status
    }
  }
`

export {
  mutationAddPayment,
  queryTransactionList,
  mutationTransferBalance,
  mutationAddRecurringPayment,
  mutationManageSubscriptionLink,
  queryAddCryptoPaymentLink
}
