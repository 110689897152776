// login action

import * as DotGQL from '../../services/graphQL'
import { NOTIFICATIONS } from '../../util/notification'
import * as actionTypes from '../actionTypes'
import { loadingStart, loadingStop, notificationFail } from './customization'


const cacheTime = 7 * 24 * 60 * 60 * 1000;
function storeDataInLocalStorage(key: any, data: any) {
  const cacheData = {
    data: data,
    timestamp: new Date().getTime()
  };
  localStorage.setItem(key, JSON.stringify(cacheData));
}
function isDataStale(key: any) {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const cacheData = JSON.parse(cachedData);
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - cacheData.timestamp > cacheTime;
  }
  return true;
}

//--------------------------------------------  getCountryList  ---------------------------------------------------
export const getCountryList = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart());
    const cacheKey = "countryList";
    if (!isDataStale(cacheKey)) {
      const cachedData = JSON.parse(localStorage.getItem(cacheKey) || '{}');
      dispatch(loadingStop());
      dispatch({
        type: actionTypes.COUNTRY_LIST,
        payload: cachedData.data.countries,
      });
      dispatch({
        type: actionTypes.REGION_LIST,
        payload: cachedData.data.subregion,
      });
    } else {
      client
        .query({
          query: DotGQL.queryCountriesList,
          fetchPolicy: 'no-cache',
        })
        .then((value: any) => {
          dispatch(loadingStop());
          storeDataInLocalStorage(cacheKey, {
            countries: value?.data?.countriesList?.countries,
            subregion: value?.data?.countriesList?.subregion,
          });

          dispatch({
            type: actionTypes.COUNTRY_LIST,
            payload: value?.data?.countriesList?.countries,
          });
          dispatch({
            type: actionTypes.REGION_LIST,
            payload: value?.data?.countriesList?.subregion,
          });
        })
        .catch((error: any) => {
          dispatch(loadingStop());
          dispatch(notificationFail(NOTIFICATIONS.ERROR.objectivesFail, error.message));
        });
    }
  };
};
//--------------------------------------------  getObjectiveList  ---------------------------------------------------

export const getObjectiveList = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart());

    const cacheKey = "objectiveList";

    if (!isDataStale(cacheKey)) {
      const cachedData = JSON.parse(localStorage.getItem(cacheKey) || '{}');
      dispatch(loadingStop());
      dispatch({
        type: actionTypes.OBJECTIVES_LIST,
        payload: cachedData.data.objectives,
      });
    } else {

      client
        .query({
          query: DotGQL.queryObjectiveList,
          fetchPolicy: 'no-cache',
        })
        .then((value: any) => {
          dispatch(loadingStop());
          storeDataInLocalStorage(cacheKey, {
            objectives: value?.data?.objectiveList?.objectives,
          });

          dispatch({
            type: actionTypes.OBJECTIVES_LIST,
            payload: value?.data?.objectiveList?.objectives,
          });
        })
        .catch((error: any) => {
          dispatch(loadingStop());
          dispatch(notificationFail(NOTIFICATIONS.ERROR.objectivesFail, error.message));
        });
    }
  };
};

//--------------------------------------------  getInterestList  ---------------------------------------------------

export const getInterestList = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart());
    const cacheKey = "interestList";
    if (!isDataStale(cacheKey)) {
      const cachedData = JSON.parse(localStorage.getItem(cacheKey) || '{}');
      dispatch(loadingStop());
      dispatch({
        type: actionTypes.INTEREST_LIST,
        payload: cachedData.data.interesting_categories,
      });
    } else {

      client
        .query({
          query: DotGQL.queryInterestingCategoryList,
          fetchPolicy: 'no-cache',
        })
        .then((value: any) => {
          dispatch(loadingStop());

          storeDataInLocalStorage(cacheKey, {
            interesting_categories: value?.data?.interestingCategoryList?.interesting_categories,
          });

          dispatch({
            type: actionTypes.INTEREST_LIST,
            payload: value?.data?.interestingCategoryList?.interesting_categories,
          });
        })
        .catch((error: any) => {
          dispatch(loadingStop());
          dispatch(notificationFail(NOTIFICATIONS.ERROR.interestingFail, error.message));
        });
    }
  };
};

export const getSearchResult = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryGlobalSearch,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch(loadingStop())
        dispatch({
          type: actionTypes.SEARCH_RESULTS,
          payload: value?.data?.globalSearch
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.interestingFail, error.message))
      })
  }
}

export const getResetSearch = (req: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_SEARCH,
      payload: req?.resetSearch
    })
  }
}
