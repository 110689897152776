import { Close } from '@mui/icons-material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import AuthModal from '../../../../views/modals/AuthDialog'
import LogoSection from '../../../MainLayout/LogoSection'

export default function SidebarHome({ sidebarOpen, toggleSidebar }: any) {
  const theme: any = useTheme()

  const [y, setY] = useState(window.scrollY)
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = (value: any) => {
    setStep(value)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  const custom = {
    padding: '10px 15px',
    width: '100%',
    display: 'inline-block',
    margin: '0 0 10px 0',
    fontSize: '20px',
    fontWeight: '500'
  }

  return (
    <Box>
      <nav
        className="animate"
        style={{
          height: '100%',
          background: 'white',
          position: 'fixed',
          top: '0',
          right: sidebarOpen ? '0px' : '-400px',
          zIndex: 9999
        }}
      >
        <Box
          sx={{
            padding: '20px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div className="flexNullCenter">
            <LogoSection />
          </div>
          <Button id="btnclose" onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
            <Close />
          </Button>
        </Box>
        <ul
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <li className="semiBold font15 pointer" style={{ width: '100%' }}>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              style={custom}
              to="Features"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Features
            </Link>
          </li>
          <li className="semiBold font15 pointer" style={{ width: '100%' }}>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              style={custom}
              to="Testimonials"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Testimonials
            </Link>
          </li>
          <li className="semiBold font15 pointer" style={{ width: '100%' }}>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              style={custom}
              to="AboutUs"
              spy={true}
              smooth={true}
              offset={-60}
            >
              About Us
            </Link>
          </li>
          <li className="semiBold font15 pointer" style={{ width: '100%' }}>
            <Button id="btnDemo" sx={{ marginLeft: '0.3rem', fontSize: '1.2rem' }}>
              <a href="https://dotaudiences.com/advertise/demo/" style={{ color: 'black' }}>
                Book Demo
              </a>
            </Button>
          </li>
          <li className="semiBold font15 pointer" style={{ width: '100%' }}>
            <Button id="btnArticles" sx={{ marginLeft: '0.3rem', fontSize: '1.2rem' }}>
              <a href="https://marketplace.dotaudiences.com/" style={{ color: 'black' }}>
                Marketplace
              </a>
            </Button>
          </li>
        </ul>
        <ul
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <li className="semiBold  pointer flexCenter">
            <Button
              id="btnloginin"
              color="primary"
              sx={{ mr: 1, fontSize: '20px !important' }}
              onClick={() => {
                handleClickOpen(1)
                toggleSidebar(!sidebarOpen)
              }}
            >
              Log in
            </Button>
          </li>
          <li className="semiBold  pointer flexCenter">
            <Button
              id="btnStartedd"
              title="Get Started"
              className="animate greenButton"
              variant="contained"
              onClick={() => {
                handleClickOpen(2)
                toggleSidebar(!sidebarOpen)
              }}
              sx={{
                padding: '10px 24px',
                fontSize: '18px',
                background: theme.palette.secondary.green,
                '&:hover': { background: '#00e39cc2' }
              }}
            >
              Get Started
              <ArrowCircleRightIcon sx={{ ml: '10px' }} />
            </Button>
          </li>
        </ul>
      </nav>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
