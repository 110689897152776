import React, { useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Typography, Button, Box, Grid, Card } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import dotWhiteLogo from '../../../assets/dotWhiteLogo.svg'
import successTick from '../../../assets/successTick.svg'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
// import { useNavigate } from 'react-router'
import { steps } from './stepsData'
import { DOT_REACH_OUT_MAIL, TERMS_OF_SERVICE } from '../../../util/constants'
import { LinkWithUrl } from '../../../util/links'
import { editUserWithoutNotification } from '../../../store/actions/setting'
import { platformUrl, redirectPage } from '../../../util/redirect'
import { DotRedirectLinks } from '../../../util/externalLinks';
import { openUrlInNewTab } from '../../../util/helperFunctions';
import { getAddPayment } from '../../../store/actions';
import { getAddRecurringPaymentLink } from '../../../store/actions/strip';
// import { textAlign } from '@mui/system';
import { topUpInitiated } from '../../../services/mixpanel/types';
import { onboarding_compeleted, topup_initiated } from '../../../services/mixpanel';
import { PaymentType } from '../../../util/constants'
const OnboardingFlowVariant1: React.FC = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const client = useSelector((state: any) => state.apolloClient.client)
  const userDetails = useSelector((state: any) => state.settingReducer.userDetails);
  const [selectedValue, setSelectedValue] = useState<PaymentType.RECURRING | PaymentType.CRYPTO | PaymentType.ONE_TIME>(PaymentType.RECURRING);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentImageStep, setCurrentImageStep] = useState(0)
  const [selectedCard, setSelectedCard] = useState<number>(0);
  const [showStep3b, setShowStep3b] = useState(false);
  const onboardingFlowVariant = userDetails.onboarding_flow_variant;
  const topUpOptions = [
    {
      id: 1,
      topUp: '0',
      description: "I'll top up later"
    },
    {
      id: 2,
      topUp: '99',
      description: "Get Started"
    },
    {
      id: 3,
      topUp: '1499',
      description: "Recommended"
    }
  ]
  const topUpValue = selectedCard !== 0 ? topUpOptions[selectedCard - 1].topUp : '';
  const topUpHandler: any = async (event: any) => {
    event?.preventDefault()
    var params
    let payment_mode: string;
    let payment_plan: string;

    switch (selectedValue) {
      case PaymentType.ONE_TIME:
        params = {
          amount: parseFloat(topUpValue),
          user_id: userDetails._id
        }
        dispatch(getAddPayment(client, params) as any)
        payment_mode = 'card'
        payment_plan = 'one_time'
        break

      case PaymentType.RECURRING:
        params = {
          amount: parseFloat(topUpValue)
          // user_id: userDetails._id
        }
        dispatch(getAddRecurringPaymentLink(client, params) as any)
        payment_mode = 'card'
        payment_plan = 'monthly'
        break

      case PaymentType.CRYPTO:
        openUrlInNewTab(DotRedirectLinks.cryptoPaymentLink)
        payment_mode = 'crypto'
        payment_plan = 'one_time'
        break
    }
    const topUpInitiatedData: topUpInitiated = {
      amount: parseFloat(topUpValue),
      payment_mode,
      payment_plan,
    }
    topup_initiated(topUpInitiatedData)
  }

  const handleNext = async () => {
    if (currentStep === 2) {
      if (showStep3b) {
        await topUpHandler()
        setCurrentStep(currentStep + 1);
      } else if (selectedCard !== 1) {
        setShowStep3b(true);
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep === 1 && currentImageStep < 4) {
      setCurrentImageStep(currentImageStep + 1);
    } else if (currentStep === steps.length - 1) {
      onboarding_compeleted({ platform_variant: onboardingFlowVariant })
      updateNewUserFalse()
      redirectPage(platformUrl(onboardingFlowVariant) as any)
    }
    else {
      setCurrentStep(currentStep + 1);
    }
  }

  const updateNewUserFalse = () => {
    const param = {
      _id: userDetails?._id,
      is_new_user: false
    }
    dispatch(editUserWithoutNotification(client, param) as any)
  }

  const getButtonLabel = (): string => {
    return currentStep === steps.length - 1 ? 'Access Dashboard' : 'Next'
  }

  const handleBack = () => {
    if (currentStep > 0) {
      if (currentStep === 2 && showStep3b) {
        setShowStep3b(false);
      }
      else if (currentStep === 1 && currentImageStep > 0) {
        setCurrentImageStep(currentImageStep - 1)
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  }
  const disableNext = (): boolean => {
    if (currentStep === 2) {
      return selectedCard === 0;
    }
    return false
  }

  const components = [
    <Step1 />,
    <Step2 currentImageStep={currentImageStep} />,
    <Step3 selectedCard={selectedCard} setSelectedCard={setSelectedCard} showStep3b={showStep3b} topUpOptions={topUpOptions} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />,
    <Step4 />
  ]

  return (
    <Grid container sx={{ height: '100vh' }} >
      {/* -------------------------   Grid Item to display the Sidebar  --------------------------- */}
      <Grid item md={4} xs={12}>
        <Box sx={Style.sideBarBox}>
          <List sx={Style.sideBarNavBox}>
            <Box sx={Style.logoBox}>
              <img src={dotWhiteLogo} alt="dotLogo" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' } }}>
              {steps.map((step, index) => (
                <ListItem key={index} sx={Style.sideBarNavItem}>
                  <Box sx={Style.stepNumberBox}>
                    <ListItemIcon
                      sx={Style.iconBox}
                      style={{
                        background:
                          currentStep === 3
                            ? '#00DB96'
                            : index === currentStep
                              ? 'var(--base-white, #FFF)'
                              : index < currentStep
                                ? '#00DB96'
                                : 'none'
                      }}
                    >
                      {index < currentStep || currentStep === steps.length - 1 ? (
                        <img src={successTick} alt="successIcon" />
                      ) : (
                        <Typography
                          sx={Style.stepNumber}
                          style={{
                            color:
                              index === currentStep
                                ? 'var(--background-light-primary-main, #5828C4)'
                                : 'var(--base-white, #FFF)'
                          }}
                        >
                          {index + 1}
                        </Typography>
                      )}
                    </ListItemIcon>
                    <Box sx={Style.line} style={{ display: index === steps.length - 1 ? 'none' : 'flex' }}></Box>
                  </Box>
                  <ListItemText
                    sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column' }}
                    primary={<Typography sx={Style.primaryText}>{step.title}</Typography>}
                    secondary={<Typography sx={Style.secondaryText}>{step.description}</Typography>}
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box sx={Style.sideBarFooter}>
            {/* Terms of service should be clickable and redirectable */}
            <Typography sx={Style.footerTypography}>
              {
                <LinkWithUrl url={TERMS_OF_SERVICE} style={{ color: 'var(--primary-200, #E9D7FE)' }}>
                  {' '}
                  <div>Terms of Service{' '}</div>
                </LinkWithUrl>
              }
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MailOutlineIcon sx={{ color: 'var(--primary-200, #E9D7FE)', width: '16px', height: '16px' }} />
              <Typography sx={Style.footerTypography}>{DOT_REACH_OUT_MAIL}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* -------------------------   Grid Item to display the steps  --------------------------- */}
      <Grid item md={8} xs={12}>
        <Box sx={currentStep === steps.length - 1 ? Style.stepContainerCenter : Style.stepContainer}>
          <Card sx={{
            ...Style.innerStepContainer,
            height: currentStep === steps.length - 1
              ? { xs: '250px', md: '250px' }
              : { xs: '90%', md: '85%' },
            width: currentStep === steps.length - 1
              ? { xs: '70%', md: '45%' }
              : { xs: '100%', md: '65%' },
          }}>
            <Box sx={{ width: '100%', height: '100%' }} style={{ overflowY: currentStep === steps.length - 1 ? "visible" : "auto" }}>
              {components[currentStep]}
            </Box>
            <Box sx={Style.buttonBox} style={{ justifyContent: currentStep === steps.length - 1 ? 'center' : 'space-between', backgroundColor: currentStep === steps.length - 1 ? '#FFFFFF' : 'var(--neutral-50, #FAFBFC)' }}>
              <Button
                sx={{
                  ...Style.button,
                  color: 'var(--text-icons-light-base-main, #323539)',
                  background: 'var(--background-light-base-main, #FFF)'
                }}
                onClick={handleBack}
                style={{
                  visibility: currentStep === 0 ? 'hidden' : 'visible',
                  display: currentStep === steps.length - 1 ? 'none' : 'flex'
                }}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
              <Button
                variant="contained"
                sx={Style.button}
                color="primary"
                onClick={handleNext}
                disabled={disableNext()}
              >
                {getButtonLabel()}
                <KeyboardArrowRight />
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid >
    </Grid >
  )
}
export default OnboardingFlowVariant1;

const Style = {
  sideBarBox: {
    width: '100%',
    height: { xs: '20vh', md: '100vh' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    background: 'var(--primary-600, #2D146F)'
  },
  logoBox: {
    display: 'flex',
    width: { xs: '100%', md: '192px' },
    height: '48px',
    padding: { md: '9px 95.502px 7px 13.378px' },
    alignItems: 'center',
    justifyContent: { xs: 'center', md: 'flex-start' }
  },
  sideBarNavBox: {
    height: '100%',
    width: { xs: '100%', md: '77%' },
    display: 'flex',
    padding: { xs: '24px 0px', md: '48px' },
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: { xs: 0, md: '80px' }
  },
  sideBarNavItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    alignItems: 'flex-start',
    gap: '17px',
    '&.MuiListItem-root': {
      padding: '0px 5px !important'
    }
  },
  sideBarFooter: {
    display: { xs: 'none', md: 'flex' },
    height: '96px',
    padding: '40px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch'
  },
  footerTypography: {
    color: 'var(--primary-200, #E9D7FE)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px'
  },
  stepNumberBox: {
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column' },
    alignItems: 'center',
    gap: { xs: '8px', md: '4px' },
    alignSelf: 'stretch'
  },
  stepNumber: {
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  line: {
    width: { xs: '20px', md: '2px' },
    background: '#F1F0F6',
    height: { xs: '2px', md: '40px' }
  },
  iconBox: {
    padding: 0,
    margin: 0,
    minWidth: 'unset',
    display: 'flex',
    width: '32px',
    height: '32px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid var(--base-white, #FFF)'
  },
  primaryText: {
    color: 'var(--base-white, #FFF)',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    whiteSpace: "nowrap"
  },
  secondaryText: {
    color: 'var(--base-white, #FFF)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    opacity: 0.6,
    whiteSpace: "nowrap"
  },
  stepContainer: {
    width: '100%',
    height: { xs: '80vh', md: '100vh' },
    display: { md: 'flex', lg: 'flex' },
    // alignItems: 'center',
    background: 'var(--background-light-base-second, #F8F9FB)',
  },
  stepContainerCenter: {
    width: '100%',
    height: { xs: '80vh', md: '100vh' },
    display: 'flex',
    // alignItems: 'center',
    background: 'var(--background-light-base-second, #F8F9FB)',
  },
  innerStepContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'space-between', md: 'center' },
    borderRadius: 'var(--roundness-round-outside, 12px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
  },
  buttonBox: {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  button: {
    display: 'flex',
    padding: 'var(--main-system-12-px, 12px) var(--main-system-20-px, 20px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-6-px, 6px)',
    borderRadius: 'var(--roundness-round-inside, 6px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
    cursor: 'pointer'
  }
}