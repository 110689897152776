import Landing from './Landing'
import './style/flexboxgrid.min.css'
import './style/index.css'
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { useDispatch } from 'react-redux'
import { notificationFail } from '../../store/actions'
import { NOTIFICATIONS } from '../../util/notification'
import { PROMPT_PARENT_ID } from '../../services/googleOneTapLogin/config'
import { oneTaplogin } from '../../store/actions/auth';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {

  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      dispatch(oneTaplogin(credentialResponse) as any)
    },
    onError: () => {
      dispatch(notificationFail(NOTIFICATIONS.ERROR.someThingWrong) as any);
    },
    auto_select: true,
    prompt_parent_id: isMobile ? undefined : PROMPT_PARENT_ID // Conditionally set prompt_parent_id
  });


  return (
    <Box sx={{ background: '#F7F6F6' }}>
      <Landing />
    </Box>
  )
}

export default MinimalLayout
