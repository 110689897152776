import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoginAuthProvider } from '../../types/auth'
import EmailLoginButton from './authbuttons/EmailLoginButton'
// import FacebookSocialButton from './authbuttons/FacebookSocialButton'
import GoogleLoginButton from './authbuttons/GoogleLoginButton'
import { signup } from '../../store/actions/auth'
import { useEffect } from 'react'
import { getUrl } from '../../util/helperFunctions'
import { signup_button_clicked, signup_initiated } from '../../services/mixpanel'

export default function Register(props: any) {
  const { setStepValue, handleClose } = props
  const dispatch: any = useDispatch()
  const url = getUrl();
  const page_name = 'landing';

  const handleLoginOrSignUp = async (provider: LoginAuthProvider, args?: { email: string; password: string }) => {
    signup_initiated({ signup_mode: provider })
    dispatch(signup(provider, args))
  }

  useEffect(() => {
    signup_button_clicked({ url, page_name })
  }, [])

  return (
    <Box>
      <Box
        className="modal-header"
        sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '20px' }}
      >
        <Box>
          <Typography variant="h3" sx={{ fontSize: '24px' }}>
            Log in or sign up in seconds
          </Typography>
          <Box
            sx={{
              fontWeight: '600',
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              marginTop: '10px',
              color: '#5A5A5A',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px'
            }}
          >
            Select an option to continue
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: '20px' }}>
        <EmailLoginButton
          handleClose={handleClose}
          handleLoginOrSignUp={handleLoginOrSignUp}
          setStepValue={setStepValue}
        />
        {/* <FacebookSocialButton
          handleClose={handleClose}
          handleLoginOrSignUp={handleLoginOrSignUp}
          setStepValue={setStepValue}
        /> */}
        <GoogleLoginButton
          handleClose={handleClose}
          handleLoginOrSignUp={handleLoginOrSignUp}
          setStepValue={setStepValue}
        />
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: '600',
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          marginTop: '10px',
          marginBottom: '10px',
          color: '#5A5A5A',
          alignItems: 'center',
          fontSize: '12px'
        }}
      >
        By continuing you agree to our
        <Link
          style={{ color: '#673ab7', marginLeft: '5px' }}
          target="_blank"
          to="https://dotaudiences.com/advertising-guidelines/"
        >
          Advertiser Guidelines
        </Link>
        ,
        <Link
          style={{ color: '#673ab7', marginLeft: '5px', marginRight: '5px' }}
          target="_blank"
          to="https://dotaudiences.com/advertiser-terms-of-service/"
        >
          Terms of Service
        </Link>
        and
        <Link
          style={{ color: '#673ab7', marginLeft: '5px' }}
          target="_blank"
          to="https://dotaudiences.com/privacy-policy/"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Box>
  )
}
