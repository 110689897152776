import Cookies from 'js-cookie'
import { lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useRoutes } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import Loadable from '../layout/ui-components/Loadable'
import { logoutUser } from '../store/actions'
import SearchResults from '../views/search'

const AdminDashboard = Loadable(lazy(() => import('../views/admin/dashboard')))
const AddCampaigns = Loadable(lazy(() => import('../views/campaigns/add-campaigns')))
const Accounts = Loadable(lazy(() => import('../views/admin/accounts')))
const UserAccount = Loadable(lazy(() => import('../views/admin/user-account')))
const DetailCampaign = Loadable(lazy(() => import('../views/campaigns/detail-campaign')))
const Requests = Loadable(lazy(() => import('../views/admin/requests')))
const Transactions = Loadable(lazy(() => import('../views/admin/transactions')))

const Settings = Loadable(lazy(() => import('../views/settings')))
const PageNotFound = Loadable(lazy(() => import('../views/extra/NotFound')))
const AddUser = Loadable(lazy(() => import('../views/admin/add-user')))
const Analytics = Loadable(lazy(() => import('../views/view-analytics')))

export default function AdminRoutes() {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Cookies.get('dot-ad-token')) {
      navigate('/')
      dispatch(logoutUser())
    }
  }, [])

  const list = {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '', element: <AdminDashboard /> },
      { path: 'dashboard', element: <AdminDashboard /> },
      { path: 'accounts', element: <Accounts /> },
      { path: 'user-account/:fuid', element: <UserAccount /> },
      { path: 'detail-campaign/:id', element: <DetailCampaign /> },
      { path: 'view-analytics/:id', element: <Analytics /> },
      { path: 'edit-campaign/:id', element: <AddCampaigns /> },
      { path: 'detail-campaign', element: <DetailCampaign /> },
      { path: 'requests', element: <Requests /> },
      { path: 'transactions', element: <Transactions /> },
      { path: 'settings', element: <Settings /> },
      { path: 'add-user', element: <AddUser /> },
      { path: '*', element: <PageNotFound /> },
      { path: '/searchResults', element: <SearchResults /> }
    ]
  }
  return useRoutes([list])
}
