import { Box, Grid, Typography , useTheme, useMediaQuery} from '@mui/material'
import GreenButton from '../../../ui-components/GreenButton'
import TrustpilotRating from '../Elements/TrustpilotRating'
const ShapeLeft = process.env.PUBLIC_URL + '/images/headershape.webp'
const ShapeRight = process.env.PUBLIC_URL + '/images/shapes.webp'
const HeaderImage = process.env.PUBLIC_URL + '/images/Main.png'
const MobileHeaderImage = process.env.PUBLIC_URL + '/images/Main mobile.webp'

export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      component={'section'}
      sx={{
        paddingTop: { xs: '80px', md: '122px' },
        paddingBottom: '30px',
        width: '100%',
        margin: '0 auto',
        paddingLeft: '100px',
        '@media (max-width: 1280px)': {
          margin: '0 auto',
          paddingLeft: '60px',
          width: '100%',
        },
        '@media (max-width: 960px)': {
          flexDirection: 'column',
        },
        '@media only screen and (max-width: 859px)': {
            padding: "0 15px",
        }
      }}
      id="home"
      className="flexSpaceCenter"
    >
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div id='google-one-tap-container' style={{ position: 'fixed', left: '74%', top: '70px', zIndex: 999 }}>
        </div>
      </Box>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingTop: { xs: '80px', md: '90px', },
            width: '45%',
            height: '100%',
            order: '0',
            '@media (max-width: 960px)': {
              width: '100%',
              order: '1',             
               margin: '50px 0',
              textAlign: 'center'
            },
            '@media (max-width: 560px)': {
              margin: '0'
            }
          }}
          className="flexCenter"
        >
          <Box>
            <Typography
              variant="h1"
              className="extraBold font60 header-main-text"
              sx={{
                color: '#000000',
                fontSize: { xs: '42px', md: '70px' },
                display: 'flex',
                marginRight: { xs: '0', lg: '20px',xl:'130px' },
                position: 'relative',
                '@media (max-width: 1280px)': {
                marginRight: '0',
                }
              }}
            >
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <img
                  style={{ position: 'absolute', left: '-44px', top: '35%' }}
                  alt="not displayShapeLeftass"
                  src={ShapeLeft}
                  aria-label="For ShapeLeftass"
                  width="44px"
                  height="47px"
                />
              </Box>
              Grow Your Web3 Business
              <Box
                component="img"
                src={ShapeRight}
                alt="image not displayshaperight"
                sx={{
                  display: { xs: 'none', md: 'block' },
                  width: '46px',
                  height: '46px',
                  position: 'absolute',
                  right: '0px',
                  top: '80px',
                  '@media (max-width: 1280px)': {
                    right: '-20px',
                    top: '80px',
                  }
                }}
                aria-label={'For shaperight'}
              />
            </Typography>
            <Box
              className="header-sub-text"
              sx={{
                maxWidth: '470px',
                color: '#12141D',
                fontSize: { xs: '16px', md: '18px' },
                padding: '15px 0 32px 0',
                lineHeight: '1.5rem',
                '@media (max-width: 960px)': {
                  textAlign: 'center',
                  maxWidth: '100%'
                }
              }}
            >
              Converting audiences in Crypto, Gaming, Finance, Tech
            </Box>
            <GreenButton title="Try Now" />

            <Box
              className="header-rating"
              sx={{ marginTop: '20px', display: 'flex', justifyContent: { md: 'flex-start', xs: 'center' } }}
            >
              <TrustpilotRating color="#000" ratingColor="#334155" />
            </Box>
          </Box>
        </Grid>
        <Grid
          className="header-image"
          item
          xs={12}
          md={6}
          sx={{
            width: '55%',
            height: '100%',
            paddingBottom:isMobile ? "20px" : "0px",
            '@media (max-width: 1280px)': {
              width: '55%',
              height: '520px',
            },
            '@media (max-width: 960px)': {
              width: '100%',
              order: '1',
              marginTop: '30px',
              height:'auto'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'relative',
              zIndex: 9,
              borderRadius: '15.84px',
              height: '100%',
              boxShadow: isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
              '@media (max-width: 960px)': {
                width: '100%',
                justifyContent: 'center',
                marginLeft:"0px",
                height:"auto",
              },
              '@media (max-width:767px)': {
                borderWidth: '3px',
                marginLeft:"0px",
                height:"auto",


              },
              '.img': {
                '@media (max-width: 560px)': {
                  width: '100%',
                  height:"auto",
                  
                }
              }
            }}
          >
            <img
              className="radius8"
              src={isMobile ? MobileHeaderImage : HeaderImage} // Conditional rendering based on viewport width
              width="100%"
              height="100%"
              aria-label={'For office'}
              alt="not displayoffice"
              style={{ zIndex: 9 }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}