import { VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomChip from '../../layout/ui-components/CustomChip'
import UserDetail from '../../layout/ui-components/DetailsRow'
import EmptyRow from '../../layout/ui-components/EmptyRow'
import CurrencyFormat from '../../util/constants'
import { UserHeadCell } from './CreativeTableHead'

import SearchTableHead from './SearchTableHead'

const UserTable = () => {
  const navigate = useNavigate()
  const searchData = useSelector((state: any) => state.commonReducer.searchData)

  const navigateToUserDetailHandler = (fuid: string) => {
    navigate('/user-account/' + fuid)
  }

  return (
    <Box>
      <h2 style={{ marginTop: '30px' }}>Accounts</h2>
      <Box sx={UserTableStyles.tableContainer}>
        <Paper sx={{ width: '100%', elevation: 3 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
              <SearchTableHead headCells={UserHeadCell} />
              <TableBody>
                {searchData?.users?.length > 0 ? (
                  searchData?.users?.map((row: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <UserDetail profile_image={row?.profile_image} name={row?.full_name} email={row?.email} />
                        </TableCell>
                        <TableCell>{row.user_type}</TableCell>
                        <TableCell>{moment(row.updated_at).format('MMMM DD, YYYY')}</TableCell>
                        <TableCell>{CurrencyFormat(row.spent_amount)}</TableCell>
                        <TableCell>
                          <CustomChip name={CurrencyFormat(row.available_balance)} type={'status'} variant={'filled'} />
                        </TableCell>

                        <TableCell sx={{ display: 'flex' }} align="center">
                          <Button
                            id="view"
                            onClick={() => {
                              navigateToUserDetailHandler(row.fuid)
                            }}
                          >
                            <VisibilityOutlined />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <EmptyRow totalColumn={UserHeadCell.length + 1} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default UserTable

const UserTableStyles = {
  tableContainer: {
    margin: '10px 0px',
    border: '1px solid #EAECF0',
    borderRadius: '8px',
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 24px'
  }
}
