import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import onboardingImageFive from '../../../assets/onboardingImages/onboardingImageFive.webp';
import onboardingImageFour from '../../../assets/onboardingImages/onboardingImageFour.webp';
import onboardingImageThree from '../../../assets/onboardingImages/onboardingImageThree.webp';
import onboardingImageTwo from '../../../assets/onboardingImages/onboardingImageTwo.webp';
import onboardingImageOne from '../../../assets/onboardingImages/onboardingImageOne.webp';

const Step2 = ({ currentImageStep}: any) => {
  const images = [onboardingImageOne, onboardingImageTwo, onboardingImageThree, onboardingImageFour, onboardingImageFive];
  
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.headingBox}>
        <Typography sx={Style.step}>step 2</Typography>
        <Typography sx={Style.title}>Get to know DOT</Typography>
        <Typography sx={Style.description}>Learn the basics in under 1 minute</Typography>
      </Box>
      <Box sx={Style.carouselBox}>
        <img
          src={images[currentImageStep]}
          alt={`Step${currentImageStep + 1}`}
          style={{
            width: '100%',
            height:'100%',
            maxHeight: '100%',
            objectPosition: 'center',
            borderRadius: "12px"
          }}
        />
        <Box sx={Style.indicatorsContainer}>
          {images.map((image, index) => (
            <Box key={index} sx={{ ...Style.indicatorCircle, backgroundColor: index === currentImageStep ? 'black' : 'grey' }}></Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
const Style = {
  mainBox: {
    height: "100%",
    display: "flex",
    padding: "32px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
  },
  headingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch"
  },
  step: {
    color: "var(--text-icons-light-base-second, #858C95)",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.12px",
    textTransform: "uppercase"
  },
  title: {
    color: "var(--text-icons-light-base-main, #323539)",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px"
  },
  description: {
    color: "var(--text-icons-light-base-second, #858C95)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.16px"
  },
  carouselBox: {
    position: "relative",
    // height: { xs: "60%", md: "100%" },
    maxHeight:{xs:"200px",sm:"250px",md:"400px"},
    width: '100%'
  },
  backArrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    left: 5,
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center"
  },
  nextArrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    right: 5,
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center"
  },
  indicatorsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: 'absolute',
    bottom: { xs: '5%', md: '6%' },
    width: "100px",
    height: "25px",
    transform: 'translateX(-50%)',
    left: '50%',
    borderRadius: "7px",
    backgroundColor: "#FFFFFF"
  },
  indicatorCircle: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'grey'
  }
}
export default Step2
