import { gql } from '@apollo/client'

const mutationAddCampaign = gql`
  mutation addCampaign(
    $balance: Float
    $utm_url: String!
    $creative: [JSON!]!
    $cpm_bid: Float
    $daily_budget: Float
    $time_frame: String!
    $number_of_impression: String!
    $device_targeting: [JSON!]!
    $interest_categories: [JSON!]!
    $objectives: [JSON!]!
    $countries: [JSON!]!
    $placement: String!
    $name: String!
  ) {
    addCampaign(
      balance: $balance
      utm_url: $utm_url
      creative: $creative
      cpm_bid: $cpm_bid
      daily_budget: $daily_budget
      time_frame: $time_frame
      number_of_impression: $number_of_impression
      device_targeting: $device_targeting
      interest_categories: $interest_categories
      objectives: $objectives
      countries: $countries
      placement: $placement
      name: $name
    ) {
      _id
      name
      user_id
    }
  }
`

const queryCampaignList = gql`
  query campaignList(
    $endDate: String
    $startDate: String
    $search: String
    $order: String
    $columnName: String
    $page: Float!
    $limit: Float!
    $user_id: String
    $objective: [String!]
    $status: [String!]
    $channel: [String!]
    $budget: Boolean
  ) {
    campaignList(
      endDate: $endDate
      startDate: $startDate
      search: $search
      order: $order
      columnName: $columnName
      page: $page
      limit: $limit
      user_id: $user_id
      objective: $objective
      status: $status
      channel: $channel
      budget: $budget
    ) {
      campaigns
      page
      limit
      total_records
    }
  }
`

const queryCopyCampaign = gql`
  query copyCampaign($_id: String, $name: String) {
    copyCampaign(_id: $_id, name: $name) {
      _id
      name
    }
  }
`

const queryObjectiveList = gql`
  query objectiveList {
    objectiveList {
      objectives
    }
  }
`

const queryInterestingCategoryList = gql`
  query interestingCategoryList {
    interestingCategoryList {
      interesting_categories
      total_records
    }
  }
`

const queryCountriesList = gql`
  query countriesList {
    countriesList {
      countries
      subregion
    }
  }
`

const queryCampaignDetail = gql`
  query campaignDetail($campaign_id: String!) {
    campaignDetail(campaign_id: $campaign_id) {
      _id
      name
      user_id
      placement
      countries
      objectives
      interest_categories
      device_targeting
      number_of_impression
      time_frame
      daily_budget
      cpm_bid
      bidding
      creative
      utm_url
      is_active
      rejected_reason
      status
      impressions
      ctr
      channel
      commission
      balance
      spent
      created_at
      updated_at
      total_budget
      request_type
      has_approved_once
    }
  }
`

const syncCampaignSpentDetail = gql`
  query syncCampaignSpentDetail($campaign_id: String!, $startDate: Float!, $endDate: Float!) {
    syncCampaignSpentDetail(campaign_id: $campaign_id,startDate: $startDate, endDate: $endDate) {
       _id
      name
      user_id
      placement
      countries
      objectives
      interest_categories
      device_targeting
      number_of_impression
      time_frame
      daily_budget
      cpm_bid
      bidding
      creative
      utm_url
      is_active
      rejected_reason
      status
      impressions
      ctr
      channel
      commission
      balance
      spent
      created_at
      updated_at
      total_budget
      request_type
      has_approved_once
    }
  }
`

const queryDeleteCampaign = gql`
  query deleteCampaign($_id: String) {
    deleteCampaign(_id: $_id) {
      _id
      name
    }
  }
`

const mutationFetchCampaignStatus = gql`
  mutation fetchBmCreativeStatus($campaignId: String!) {
    fetchBmCreativeStatus(id: $campaignId) {
      status
    }
  }
`

const mutationUpdateStatusCampaign = gql`
  mutation updateStatusCampaign($channel: String!, $status: String!, $_id: String!) {
    updateStatusCampaign(channel: $channel, status: $status, _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationApproveCampaignToManual = gql`
  mutation approveCampaignToManual( $_id: String!) {
    approveCampaignToManual(_id: $_id) {
      _id
    }
  }
`

const mutationPauseUserCampaignStatus = gql`
  mutation pauseCampaign($_id: String!) {
    pauseCampaign( _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationResumeUserCampaignStatus = gql`
  mutation resumeCampaign($_id: String!) {
    resumeCampaign( _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationPauseCampaignAdmin = gql`
  mutation pauseCampaignAdmin($_id: String!) {
    pauseCampaignAdmin( _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationResumeCampaignAdmin = gql`
  mutation resumeCampaignAdmin($_id: String!) {
    resumeCampaignAdmin( _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationCreateCampaignInGAM = gql`
  mutation createCampaignInGAM($status: String, $_id: String!) {
    createCampaignInGAM(status: $status, _id: $_id) {
      _id
      name
      user_id
      status
    }
  }
`

const mutationUpdateCommissionCampaign = gql`
  mutation updateCommissionCampaign($commission: Float!, $_id: String!) {
    updateCommissionCampaign(commission: $commission, _id: $_id) {
      commission
      _id
    }
  }
`

const mutationEditCampaign = gql`
  mutation updateCampaign(
    $_id: String!
    $utm_url: String!
    $creative: [JSON!]!
    $cpm_bid: Float
    $daily_budget: Float
    $balance: Float
    $time_frame: String!
    $number_of_impression: String!
    $device_targeting: [JSON!]!
    $interest_categories: [JSON!]!
    $objectives: [JSON!]!
    $countries: [JSON!]!
    $placement: String!
    $name: String!
  ) {
    updateCampaign(
      _id: $_id
      utm_url: $utm_url
      creative: $creative
      cpm_bid: $cpm_bid
      daily_budget: $daily_budget
      balance: $balance
      time_frame: $time_frame
      number_of_impression: $number_of_impression
      device_targeting: $device_targeting
      interest_categories: $interest_categories
      objectives: $objectives
      countries: $countries
      placement: $placement
      name: $name
    ) {
      _id
      name
      user_id
    }
  }
`

const mutationDraftCampaign = gql`
  mutation draftCampaign(
    $_id: String
    $balance: Float
    $utm_url: String
    $creative: [JSON!]
    $cpm_bid: Float
    $daily_budget: Float
    $time_frame: String
    $number_of_impression: String
    $device_targeting: [JSON!]
    $interest_categories: [JSON!]
    $objectives: [JSON!]
    $countries: [JSON!]
    $placement: String
    $name: String
  ) {
    draftCampaign(
      _id: $_id
      balance: $balance
      utm_url: $utm_url
      creative: $creative
      cpm_bid: $cpm_bid
      daily_budget: $daily_budget
      time_frame: $time_frame
      number_of_impression: $number_of_impression
      device_targeting: $device_targeting
      interest_categories: $interest_categories
      objectives: $objectives
      countries: $countries
      placement: $placement
      name: $name
    ) {
      _id
      spent
      balance
      commission
      rejected_reason
      is_active
      utm_url
      creative
      cpm_bid
      daily_budget
      time_frame
      number_of_impression
      device_targeting
      interest_categories
      objectives
      countries
      placement
      name
    }
  }
`


export {
  queryCopyCampaign,
  queryObjectiveList,
  queryInterestingCategoryList,
  mutationAddCampaign,
  queryCountriesList,
  queryCampaignList,
  queryCampaignDetail,
  syncCampaignSpentDetail,
  mutationUpdateStatusCampaign,
  mutationUpdateCommissionCampaign,
  mutationEditCampaign,
  queryDeleteCampaign,
  mutationCreateCampaignInGAM,
  mutationPauseUserCampaignStatus,
  mutationResumeUserCampaignStatus,
  mutationDraftCampaign,
  mutationApproveCampaignToManual,
  mutationFetchCampaignStatus,
  mutationPauseCampaignAdmin,
  mutationResumeCampaignAdmin
}
