import { Box } from '@mui/material'
import Marquee from 'react-fast-marquee'
const EasyCryptoLogo = process.env.PUBLIC_URL + '/images/EasyCryptoLogo.webp'
const EnjinLogo = process.env.PUBLIC_URL + '/images/EnjinLogo.webp'
const HuobiLogo = process.env.PUBLIC_URL + '/images/Huobi-logo.webp'
const IskraLogo = process.env.PUBLIC_URL + '/images/IskraLogo.webp'
const TreezorLogo = process.env.PUBLIC_URL + '/images/treezor.webp'
const ZenGoLogo = process.env.PUBLIC_URL + '/images/ZenGoLogo.webp'

export default function HomePageSlider() {
  const items = [
    { imgPath: EasyCryptoLogo },
    { imgPath: EnjinLogo },
    { imgPath: HuobiLogo },
    { imgPath: IskraLogo },
    { imgPath: ZenGoLogo },
    { imgPath: TreezorLogo }
  ]

  return (
    <Box
      id="HomePageSlider"
      component="section"
      sx={{
        padding: { xs: '20px 0px', md: '40px 0px' },
        filter: 'grayscale(1)',
        background: '#fff'
      }}
    >
      <Box className="home-slider-box container">
        <Marquee gradient={false}>
          {items.map((item: any, i) => (
            <img
              width="200px"
              height="50px"
              key={i}
              aria-label={'For ReachImage' + i}
              src={item.imgPath}
              alt={'ReachImage' + i}
              style={{ marginLeft: '80px' }}
            />
          ))}
        </Marquee>
      </Box>
    </Box>
  )
}
