import { Box, Typography } from '@mui/material'

export default function CampaignName({ name, url }: any) {
  return (
    <Box>
      <Typography sx={requestTableStyle.textMain}>{name}</Typography>
      <Typography sx={requestTableStyle.textSub}>
        {url && (
          <a style={{ color: '#6941C6' }} target="_blank"  rel="noopener noreferrer" href={url}>
            {url}
          </a>
        )}
      </Typography>
    </Box>
  )
}

const requestTableStyle = {
  textMain: {
    width: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#101828',
    cursor: 'pointer'
  },
  textSub: {
    width: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#475467'
  }
}
