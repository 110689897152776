import DoneIcon from '@mui/icons-material/Done'
import { Divider, Grid } from '@mui/material'
import { useEffect, useState } from 'react'

const UtmUrlSection = ({ campaignDetails }: any) => {
  const getTitle = (name: string) => {
    return (
      <Grid item sx={{ fontSize: '16px', display: 'flex', fontWeight: 'bold' }} md={4}>
        <DoneIcon color="success" sx={{ mr: 2 }} />
        {name}
      </Grid>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        {getTitle('UTM URL')}
        <Grid item sx={{ mt: { xs: 2, md: 0 }, mb: { xs: 4, md: 0 }, width: '100%' }}>
          <>
            <a target="blank" href={campaignDetails?.previous_campaign_values?.utm_url}>
              {campaignDetails?.previous_campaign_values?.utm_url}
            </a>
          </>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default UtmUrlSection
