import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import { USER_DASHBOARD, USER_DASHBOARD_AVERAGE } from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail } from '../customization'

// get Document List
export const getUserDashboardCount = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryuserDashboardCounts,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: USER_DASHBOARD,
          data: response.data.userDashboardCounts
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}

export const getUserDashboardAverageCount = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryuserDashboardAverageCounts,
        fetchPolicy: 'no-cache'
      })
      .then((respose: any) => {
        dispatch({
          type: USER_DASHBOARD_AVERAGE,
          data: respose?.data?.userDashboardAverageCounts
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message))
      })
  }
}
