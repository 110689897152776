import { Box, Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import AuthModal from '../../../../views/modals/AuthDialog'

const AddAccountBg = process.env.PUBLIC_URL + '/images/addAccountBg.webp'
const mobileAddAccountBg = process.env.PUBLIC_URL + '/images/mobileAddAccountBg.webp'

export default function PRArticles() {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }
  return (
    <Box component={'section'} id="PRArticles" className="container ">
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            src={AddAccountBg}
            aria-label={'For AddAccountBg'}
            alt="not displayAddAccountBg"
            width="100%"
            height="100%"
          />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            src={mobileAddAccountBg}
            aria-label={'For mobileAddAccountBg'}
            alt="not displaymobileAddAccountBg"
            width="100%"
            height="100%"
          />
        </Box>
        <Grid
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            padding: { xs: '20px', md: '0 100px' },
            flexDirection: { xs: 'column', md: 'row' }
          }}
        >
          <Typography variant="h2" sx={{ fontSize: { xs: '24px', md: '48px' }, mb: { xs: '20px', md: '0' } }}>
            Get started
          </Typography>
          <Button
            id="btncreateaccount"
            onClick={() => {
              handleClickOpen()
              setStep(2)
            }}
            variant="contained"
            sx={{ fontSize: '16px', fontWeight: '600', p: { xs: '10px 20px', md: '10px 20px' } }}
          >
            Create Account
          </Button>
        </Grid>
      </Box>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
