import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import Box from '@mui/material/Box'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { LoginAuthProvider } from '../../types/auth'
import ForgotPasswordDialog from '../modals/ForgotPasswordDialog'


interface propsType {
  step: any
  handleLoginOrSignUp: (provider: LoginAuthProvider, args?: { email: string; password: string }) => void
  userEmail?: string
}

const LoginForm = (prop: propsType) => {
  // const formikRef = useRef(null);
  const { step, handleLoginOrSignUp, userEmail } = prop
  const [open, setOpen] = useState(false)
  const { isLoading } = useSelector((state: any) => state.customization)
  
  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required').trim(' '),
    password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Password Required')
  })
  const [isPassword, setIsPassword] = React.useState(true)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: userEmail || '',
          password: ''
        }}
        validationSchema={SignInSchema}
        onSubmit={(values: { email: any; password: any }) => {
          values = { ...values, email: values.email.trim() }
          handleLoginOrSignUp(LoginAuthProvider.Email, values)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }: any) =>  {
          
          return (
          <Form style={{ width: '100%', padding: '0px 20px', boxSizing: 'border-box' }}>
            <TextField
              fullWidth
              id={'email'}
              placeholder="Email address"
              type={'text'}
              name={'email'}
              value={values?.email}
              onChange={handleChange}
              error={touched?.email && Boolean(errors?.email)}
              helperText={touched?.email && errors.email}
            />
            <Box sx={{ position: 'relative', marginTop: 2 }}>
              <OutlinedInput
                fullWidth
                id={'password'}
                placeholder="Password"
                type={isPassword ? 'password' : 'text'}
                name={'password'}
                value={values?.password}
                onChange={handleChange}
                error={touched?.password && Boolean(errors?.password)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsPassword(!isPassword)}
                      aria-label="toggle password visibility"
                      onMouseDown={() => setIsPassword(!isPassword)}
                      edge="end"
                    >
                      {isPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors?.password && (
                <FormHelperText error id="password">
                  {errors?.password}
                </FormHelperText>
              )}

              <Box sx={{ position: 'absolute', right: '15px', top: '30%', color: '#898989' }}></Box>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                id="ForgotPassword"
                onClick={() => handleClickOpen()}
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontWeight: '600',
                  justifyContent: 'center',
                  width: '100%',
                  textAlign: 'center',
                  mt: 2
                }}
              >
                Forgot Password?
              </Typography>
            </Stack>
            <Button
              id="btnLogin"
              type="submit"
              disabled={isLoading}
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                p: 0,
                width: '100% !important',
                height: '40px',
                color: '#fff !important',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'white',
                    marginRight: '20px'
                  }}
                />
              )}
              {step === 1 ? 'Login' : 'Signup'}
            </Button>
          </Form>
          )
        }}
      </Formik>
      {open && <ForgotPasswordDialog open={open} handleClose={handleCloseModal} />}
    </Box>
  )
}

export default LoginForm
