export const downloadFile = async (fileUrl: any, fileName: any) => {
  try {
    const response = await fetch(fileUrl, {
      headers: {
        Accept: 'application/octet-stream'
      }
    })
    if (!response.ok) {
      console.error(`Failed to fetch URL. Response status: ${response.status}`)
      return
    }
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()
  } catch (error: any) {
  }
}
