// login action

import * as DotGQL from '../../services/graphQL'
import { NOTIFICATIONS } from '../../util/notification'
import { GETTING_USER_FAILURE, GETTING_USER_SUCCESS } from '../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from './customization'
import { getUserList } from './user'

export const getUser = (client: any, req?: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryUserDetails,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: GETTING_USER_SUCCESS,
          payload: response?.data?.userDetails
        })
      })
      .catch((error: any) => {
        dispatch({
          type: GETTING_USER_FAILURE,
          payload: error
        })
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const getUserAccountsPage = (client: any, req?: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryUserDetailsPreviousVersion,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: GETTING_USER_SUCCESS,
          payload: response?.data?.userDetails
        })
      })
      .catch((error: any) => {
        dispatch({
          type: GETTING_USER_FAILURE,
          payload: error
        })
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const editUser = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryUpdate,
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.userDetailsUpdateSuccess))
        // dispatch(getUser(client, req))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}

export const deleteUser = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryDeleteUser,
        variables: req
      })
      .then((response: any) => {
        const listRequest = {
          page: 1,
          limit: 10,
          isExport: false,
          columnName: '_id',
          order: 'desc'
        }
        dispatch(getUserList(client, listRequest))
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.userDeletedSuccess))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}
