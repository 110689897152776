// action - state management
import * as actionTypes from '../actionTypes'

export const initialState = {
  sizeList: [],
  creativeData: {}
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const CreativeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SIZE_LIST:
      return {
        ...state,
        sizeList: action.data
      }
    case actionTypes.LIST_CREATIVE_SUCCESS:
      return {
        ...state,
        creativeData: action?.data
      }
    default:
      return state
  }
}

export default CreativeReducer
