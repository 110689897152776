import { Link } from 'react-router-dom'

const LinkComponent = ({ to, icon = null, text }: any) => {
  return (
    <Link key="1" color="inherit" to={to} style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
      {icon && icon}
      {text && text}
    </Link>
  )
}

export default LinkComponent
