import * as DotGQL from '../../../services/graphQL'
import { DefaultParamForFetchingCampaigns } from '../../../util/constants'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { getRecentCampaign } from '../admin/dashboard'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from '../customization'

export const updateStatusCampaign = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationUpdateStatusCampaign,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_UPDATE,
          payload: value?.data?.updateStatusCampaign?._id
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        callBack()
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignFailed, error.message))
      })
  }
}

export const approveCampaignToManual = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationApproveCampaignToManual,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_UPDATE,
          payload: value?.data?.approveCampaignToManual?._id
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        callBack()
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignFailed, error.message))
      })
  }
}

export const updateCommissionCampaign = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationUpdateCommissionCampaign,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_UPDATE,
          payload: value?.data?.updateCommissionCampaign
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.updateCommissionCampaign))
        dispatch(getRecentCampaign(client, DefaultParamForFetchingCampaigns) as any)
        dispatch(loadingStop())
        callBack()
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignFailed, error.message))
      })
  }
}

export const createUpdateCampaignInGam = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationCreateCampaignInGAM,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_GAM_STATUS,
          payload: value
          // value?.data?.updateStatusCampaign?._id
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        callBack()
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignFailed, error.message))
      })
  }
}

export const syncCampaignSpentDetail = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.syncCampaignSpentDetail,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_DETAIL,
          payload: value?.data?.syncCampaignSpentDetail
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignSpentFetched))
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
      })
  }
}

export const syncCampaignStatus = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.mutationFetchCampaignStatus,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.ADD_STATUS,
          payload: value?.data?.fetchCampaignStatus
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusFetched))
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignStatusFailed))
      })
  }
}

export const pauseCampaignAdmin = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.mutationPauseCampaignAdmin,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.ADD_STATUS,
          payload: value?.data?.mutationPauseCampaignAdmin
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignPaused))
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignStatusFailed))
      })
  }
}

export const resumeCampaignAdmin = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.mutationResumeCampaignAdmin,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.ADD_STATUS,
          payload: value?.data?.mutationResumeCampaignAdmin
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignResumed))
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.updateCampaignStatusFailed))
      })
  }
}

export const clearCampaignDetail = () => {
  return {
    type: actionTypes.CLEAR_CAMPAIGN_DETAIL
  }
}
