import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { alpha, styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSearchResult } from '../../store/actions'
import { getResetSearch } from '../../store/actions/common'
import { useDebounce } from './useDebounce'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '100%'
  }
}))

const SearchIconWrapper1 = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%'
      }
    }
  }
}))

export default function SearchAppBar() {
  const location = useLocation()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const dispatch: any = useDispatch()
  const client = useSelector((state: any) => state.apolloClient.client)
  const resetSearch = useSelector((state: any) => state.commonReducer.resetSearch)
  const navigate = useNavigate()
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        dispatch(getSearchResult(client, { search: debouncedSearchTerm }))
        navigate('/searchResults')
      } else {
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )
  useEffect(() => {
    if (resetSearch) {
      setSearchTerm('')
    }
  }, [resetSearch])

  useEffect(() => {
    if (!location.pathname.includes('searchResults')) {
      let req = {
        resetSearch: true
      }
      dispatch(getResetSearch(req))
    }
  }, [location])

  return (
    <Search>
      <SearchIconWrapper1>
        <SearchIcon />
      </SearchIconWrapper1>
      <StyledInputBase
        id="globalSearch"
        sx={SearchStyles}
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  )
}
const SearchStyles = {
  input: {
    width: '100%',
    display: 'block',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    height: '25px',
    padding: '2px 0px',
    '&::placeholder': {
      color: '#667085',
      fontWeight: '400',
      fontSize: '16px'
    }
  }
}
