import PropTypes from 'prop-types'
// material-ui
import { Box, useMediaQuery, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// project imports
import { useDispatch, useSelector } from 'react-redux'
import { drawerWidth } from '../../../store/constant'
import { UserType } from '../../../util/constants'
import { adminMenuItems, menuItems } from '../../menu-items'
import SearchAppBar from '../../ui-components/SearchBar'
import MenuCard from './MenuCard'
import MenuList from './MenuList'
import { SET_MENU } from '../../../store/actions'
import sparklingIcon from '../../../assets/sparklingIcon.svg'
import { useNavigate } from 'react-router-dom'
import { new_campaign_button_clicked } from '../../../services/mixpanel'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const user: any = useSelector((state: any) => state.authReducer)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const leftDrawerOpened = useSelector((state: any) => state.customization.opened)

  const handleDrawerToggle = () => {
    if (leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }
  }
  const navigate = useNavigate();
  const handleNewCampaignClick = () => {
    new_campaign_button_clicked({})
    navigate('/add-campaigns')
  }
  return (
    <Box
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : 'auto',
        // height: '275px',
        height: "350px",
        overflow: 'auto',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
    >
      <SearchAppBar />

      {
        user?.userType === UserType.CUSTOMER &&
        <Box sx={Style.newCampaignButton} onClick={handleNewCampaignClick}>
          <img src={sparklingIcon} height="20px" width="20px" alt="sparklingIcon" />
          <Typography color='#FFFFFF' fontSize='15px' fontWeight={500} lineHeight='20px'>New Campaign</Typography>
        </Box>
      }


      <div onClick={handleDrawerToggle}>
        <MenuList
          menuItem={
            user?.userType === UserType.ADMIN || user?.userType === UserType.SUPER_ADMIN ? adminMenuItems : menuItems
          }
        />

        <MenuCard />
      </div>
    </Box>
  )
}

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
}
const Style = {
  newCampaignButton: {
    width: "100%",
    height: "55px",
    backgroundColor: "#5A27DD",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    mt: "8px",
    display: "flex",
    justifyContent: "center",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // smooth transition for color and shadow
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // subtle shadow
    '&:hover': {
      backgroundColor: '#5530a3', // Very slightly darker shade of purple
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Enhanced shadow on hover for depth
    },
  }
}
export default Sidebar
