import React from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux'
import { roundBalance } from '../../../util/numericalConvertors';
import US from '../../../assets/US.svg';
import lock from '../../../assets/lock.svg';
import tickIcon from '../../../assets/tickIcon.svg';
import Step3b from './step3b';
const Step3 = ({ selectedCard, setSelectedCard, showStep3b, topUpOptions, setSelectedValue, selectedValue }: any) => {
  const userDetails = useSelector((state: any) => state.settingReducer.userDetails);

  const handleCardClick = (optionId: any) => {
    setSelectedCard(optionId);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {
        showStep3b ? <Step3b setSelectedValue={setSelectedValue} selectedValue={selectedValue} />
          :
          <Box sx={Style.mainBox}>
            <Box sx={Style.headingBox}>
              <Typography sx={Style.step}>step 3</Typography>
              <Typography sx={Style.title}>Get to know DOT</Typography>
              <Typography sx={Style.description}>Learn the basics in under 1 minute</Typography>
            </Box>
            <Box sx={Style.balanceBox}>
              <Typography fontWeight={600} fontSize="16px" lineHeight="24px" color="#475467">
                Your balance
              </Typography>
              <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <img src={US} style={{ height: "30px", width: "30px", borderRadius: "50%" }} alt='USLogo' />
                <Typography fontWeight={600} fontSize="36px" letterSpacing='-2%' color="#101828">
                  ${roundBalance(userDetails.available_balance)}
                </Typography>
              </div>
            </Box>
            <Box sx={Style.topUpOptionBox}>
              <Typography fontWeight={400} fontSize="16px" lineHeight="24px" color="#858C95" letterSpacing="-1%">Choose Recommended top up option below</Typography>
              <Grid container sx={{ display: "flex", justifyContent: "space-between", gap: { xs: "8px", md: "8px" }, overflowY: "auto" }}>
                {
                  topUpOptions.map((option: any) => (
                    <Grid item xs={12} md={5.9} lg={3.8} key={option.id} onClick={() => handleCardClick(option.id)} sx={selectedCard === option.id ? Style.selectedOptionCard : Style.optionCard}>
                      <Box sx={{ flex: 1, display: "flex", flexDirection: { xs: "row", md: "column" }, justifyContent: "space-between", alignItems: { xs: "center", md: "normal" } }}>
                        <Typography fontWeight={500} fontSize="24px" lineHeight="29.05px" color="#2D146F">${option.topUp}</Typography>
                        <Typography fontWeight={400} fontSize="14px" lineHeight="24px" color="#000000">{option.description}</Typography>
                      </Box>
                      <Box sx={{ width: "16px", height: "16px", borderRadius: "8px", border: "1px solid #EAECF0" }}>
                        {selectedCard === option.id ?
                          <img src={tickIcon} alt='tickIcon' />
                          : null
                        }
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', alignItems: "center", gap: "8px", width: "100%", justifyContent: "center" }}>
              <img src={lock} height='20px' width='20px' alt='lockIcon' />
              <Typography fontWeight={600} fontSize="14px" lineHeight="20px" color="#344054" >
                Your payment info is secure
              </Typography>
            </Box>
          </Box>
      }
    </>
  )
}
const Style = {
  mainBox: {
    height: "100%",
    display: "flex",
    padding: "32px",
    paddingBottom: { xs: '0px' },
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
    overflowY: "auto",
  },
  headingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch"
  },
  step: {
    color: "var(--text-icons-light-base-second, #858C95)",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.12px",
    textTransform: "uppercase"
  },
  title: {
    color: "var(--text-icons-light-base-main, #323539)",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px"
  },
  description: {
    color: "var(--text-icons-light-base-second, #858C95)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.16px"
  },
  balanceBox: {
    background: '#F8F9FB',
    borderRadius: "10px",
    width: "100%",
    padding: "16px"
  },
  topUpOptionBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "10px",
    gap: { xs: "20px", md: "8px" },
    width: "100%"
  },
  optionCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: { xs: "center", md: "normal" },
    padding: { xs: "11px 16px 11px 16px", md: "16px" },
    borderRadius: "12px",
    gap: "4px",
    background: "#FFFFFF",
    border: "1px solid #EAECF0",
    cursor: "pointer"
  },
  selectedOptionCard: {
    display: "flex",
    padding: { xs: "11px 16px 11px 16px", md: "16px" },
    justifyContent: "space-between",
    alignItems: { xs: "center", md: "normal" },
    borderRadius: "12px",
    gap: "4px",
    background: "#FFFFFF",
    border: "1px solid #5828C4",
    cursor: "pointer"
  }
}
export default Step3
