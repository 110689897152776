// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

interface actionType {
  type: string
  payload: any
}

export const initialState = {
  campaignDetail: null,
  loading: true,
  error: null,
  campaignAnalyticsData: null
}

// ==============================|| Campaign Detail REDUCER ||============================== //

const campaignDetailReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_DETAIL:
      return updateObject(state, {
        campaignDetail: action?.payload,
        loading: false,
        error: null
      })

    case actionTypes.CLEAR_CAMPAIGN_DETAIL:
      return updateObject(state, {
        campaignDetail: null,
        loading: true,
        error: null
      })

    case actionTypes.CAMPAIGN_DETAIL_FAILURE:
      return updateObject(state, {
        campaignDetail: null,
        loading: false,
        error: action?.payload?.error
      })
    case actionTypes.GET_ANALYTICS_CAMPAIGN_SUCCESS:
      return updateObject(state, {
        campaignAnalyticsData: action?.payload,
        loading: false,
        error: null
      })

    default:
      return state
  }
}

export default campaignDetailReducer
