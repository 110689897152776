import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CampaignName from '../../layout/ui-components/CampaignName'
import CustomChip, { StatusCustomChip } from '../../layout/ui-components/CustomChip'
import EmptyRow from '../../layout/ui-components/EmptyRow'
import { CreativeTableHeadCell } from './CreativeTableHead'
import SearchTableHead from './SearchTableHead'

const CreativeList = () => {
  const navigate = useNavigate()
  const searchData = useSelector((state: any) => state.commonReducer.searchData)

  const buttonClickHandler = (id: any) => {
    navigate('/creative-gallery')
  }

  return (
    <Box>
      <h2 style={{ marginTop: '30px' }}>Creatives</h2>
      <Box
        sx={{
          border: '1px solid #EAECF0',
          borderRadius: '8px',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
          marginTop: 2
        }}
      >
        <Paper sx={{ width: '100%', elevation: 3 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <SearchTableHead headCells={CreativeTableHeadCell} />
              <TableBody>
                {searchData?.creatives?.length > 0 ? (
                  searchData?.creatives?.map((row: any, index: any) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        sx={{
                          '.MuiTableCell-root ': {
                            p: 1
                          }
                        }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                              buttonClickHandler(row?._id)
                            }}
                          >
                            <CampaignName name={row?.name} url={row?.url} />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <StatusCustomChip name={row?.size} />
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <CustomChip name={row?.creative_type} type={'objectives'} variant={'filled'} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <EmptyRow totalColumn={CreativeTableHeadCell.length + 1} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default CreativeList
