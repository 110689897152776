import { Chip } from '@mui/material'

interface propType {
  name: string
  type?: 'status' | 'objectives' | 'interest' | 'impression' | 'bidding' | 'pending approval' | undefined
  variant?: 'outlined' | 'filled' | undefined
}

// to add additional chip styles modify this object

const chipStyle = {
  common: {
    m: '2px',
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    height: '20px'
  },
  'pending approval': {
    background: '#FFFAEB',
    color: '#B54708'
  },
  status: {
    background: '#ECFDF3',
    color: '#027A48'
  },
  objectives: {
    background: '#F9F5FF',
    color: '#6941C6'
  },
  interest: {
    background: '#FFFAEB',
    color: '#B54708'
  },
  impression: {
    background: '#FDF2FA',
    color: '#C11574'
  },
  bidding: {
    background: '#F2F4F7',
    color: '#344054'
  }
}

const statusChipStyle = {
  Active: {
    background: '#ECFDF3',
    color: '#027A48'
  },
  "Pending Approval": {
    background: '#FFFAEB',
    color: '#B54708'
  },
  Inactive: {
    background: '#FDF2FA',
    color: '#C11574'
  },
  Paused: {
    background: '#F2F4F7',
    color: '#344054'
  },
  Completed: {
    background: '#F2F4F7',
    color: '#344054'
  },
  Approved: {
    background: '#ECFDF3',
    color: '#027A48'
  },
  Rejected: {
    background: '#FDF2FA',
    color: '#C11574'
  }
}

export default function CustomChip({ name, type, variant }: propType) {
  return (
    <Chip
      label={name}
      sx={
        type
          ? {
            ...chipStyle.common,
            ...(type && chipStyle[type])
          }
          : { m: 1, borderRadius: '8px' } //this section to be converted to better conditions
      }
      variant={variant == 'filled' ? 'filled' : 'outlined'}
    />
  )
}

export function StatusCustomChip({
  name
}: {
  name: 'Approved' | 'Active' | 'Rejected' | 'Paused' | 'Pending Approval' | 'Completed' | 'Inactive'
}) {
  return (
    <Chip
      label={name}
      sx={{
        ...chipStyle.common,
        ...(name && statusChipStyle[name])
      }}
      variant="filled"
    />
  )
}
