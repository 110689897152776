import React, { useState } from 'react'
import { Box, Typography, Radio, RadioGroup, IconButton, Card, FormControlLabel, useMediaQuery, useTheme } from '@mui/material'
// import { useSelector, useDispatch } from 'react-redux'
import { PaymentType } from '../../../util/constants'
import creditCard from '../../../assets/creditCard.svg'
import cryptocurrency from '../../../assets/cryptocurrency.svg'
import bitcoin from '../../../assets/bitcoin.svg'
import eth from '../../../assets/eth.svg'
import mastercard from '../../../assets/mastercard.svg'
import visa from '../../../assets/visa.svg'
import lock from '../../../assets/lock.svg'

const Step3b = ({ setSelectedValue, selectedValue }: any) => {
    const [isChecked, setIsChecked] = React.useState(false);

    const handleRadioChange = (event: any) => {
        setSelectedValue(event.target.value)
    }
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    // const isXs = useMediaQuery('(max-height:750px)');
    return (
        <Box sx={Style.mainBox}>
            <Box sx={Style.headingBox}>
                <Typography sx={Style.step}>step 3</Typography>
                <Typography sx={Style.title}>Choose Payment Method</Typography>
                <Typography sx={Style.description}>Card or Crypto</Typography>
            </Box>
            <Box sx={{ ...Style.radioGroupContainer }}>
                <RadioGroup
                    aria-labelledby="payment type radio options"
                    defaultValue="recurring"
                    name="radio-buttons-group"
                    value={selectedValue} // Set the value prop to the selectedValue
                    onChange={handleRadioChange} // Handle radio button change
                    sx={Style.radioButtonGroup}
                >
                    <PaymentTypeComponent
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        paymentType={PaymentType.ONE_TIME}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                    {/* {showRecurringOption && ( */}
                    <PaymentTypeComponent
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        paymentType={PaymentType.RECURRING}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                    {/* )} */}
                    <PaymentTypeComponent
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        paymentType={PaymentType.CRYPTO}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                </RadioGroup>
            </Box>
            <Box sx={{ display: 'flex', alignItems: "center", gap: "8px" }}>
                <img src={lock} height='20px' width='20px' alt='lockIcon' />
                <Typography fontWeight={600} fontSize="14px" lineHeight="20px" color="#344054" >
                    Your payment info is secure
                </Typography>
            </Box>
        </Box>
    )
}

const PaymentTypeComponent = (props: any) => {
    const { isChecked, paymentType, selectedValue, setSelectedValue } = props

    const handleCardClick = (
        paymentType: PaymentType.CRYPTO | PaymentType.ONE_TIME | PaymentType.RECURRING | undefined
    ) => {
        setSelectedValue(paymentType)
    }

    return (
        <Card
            sx={selectedValue === paymentType ? Style.radioButtonSelectedCard : Style.radioButtonCard}
            onClick={() => handleCardClick(paymentType)}
        >
            <Box sx={selectedValue === paymentType ? Style.radioButtonSelectedItem : Style.radioButtonItem}>
                <IconButton sx={Style.iconStyle} color="secondary" aria-label="credit card icon">
                    {returnPaymentTypeIcon(paymentType)}
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={Style.paymentDetailsBox}>
                        <Typography sx={Style.paymentTypeTextStyle}>{returnPaymentTypeText(paymentType)}</Typography>
                        {paymentType === PaymentType.RECURRING && (
                            <Box sx={Style.chip}>
                                <Typography sx={Style.chipTextStyle}>10% extra credit free</Typography>
                            </Box>
                        )}
                    </Box>
                    {returnSubText(paymentType)}
                    {returnPaymentTypeHelperIcon(paymentType)}
                </Box>
                <FormControlLabel value={paymentType} control={<Radio />} label="" sx={{ ml: 'auto' }} />
            </Box>
        </Card>
    )
}

const returnPaymentTypeText = (paymentType: string | any): string => {
    switch (paymentType) {
        case PaymentType.ONE_TIME:
            return 'Credit or Debit card'
        case PaymentType.RECURRING:
            return 'Credit or Debit card'
        case PaymentType.CRYPTO:
            return 'Crypto'

        default:
            return 'none'
    }
}

const returnPaymentTypeIcon = (paymentType: string | any): any => {
    switch (paymentType) {
        case PaymentType.ONE_TIME:
            return <img src={creditCard} alt='creditCard' />
        case PaymentType.RECURRING:
            return <img src={creditCard} alt='creditCard' />
        case PaymentType.CRYPTO:
            return <img src={cryptocurrency} alt='cryptocurrency' />

        default:
            return 'none'
    }
}

const returnPaymentTypeHelperIcon = (paymentType: string | any): any => {
    switch (paymentType) {
        case PaymentType.ONE_TIME:
            return (
                <Box sx={Style.paymentTypeHelperIconBoxStyle}>
                    <img src={visa} alt='visa' />
                    <img src={mastercard} alt='masterCard' />
                </Box>
            )
        case PaymentType.RECURRING:
            return (
                <Box sx={Style.outsidePaymentTypeHelperIconBoxStyle}>
                    <Box sx={Style.paymentTypeHelperIconBoxStyle}>
                        <img src={visa} alt='visa' />
                        <img src={mastercard} alt='masterCard' />
                    </Box>
                    <Box sx={Style.popularBoxContainer}>
                        <Box sx={Style.mostPopularTextBox}>
                            <Typography sx={Style.mostPopularChipTextStyle}>Most Popular</Typography>
                        </Box>
                    </Box>
                </Box>
            )
        case PaymentType.CRYPTO:
            return (
                <Box sx={Style.paymentTypeHelperIconBoxStyle}>
                    <img src={bitcoin} alt='bitcoin' />
                    <img src={eth} alt='ethereum' />
                </Box>
            )

        default:
            return 'none'
    }
}

const returnSubText = (paymentType: string | any): any => {
    switch (paymentType) {
        case PaymentType.ONE_TIME:
            return <Typography sx={Style.subtextStyle}>(One Time)</Typography>
        case PaymentType.RECURRING:
            return (
                <Typography sx={Style.subtextStyle}>
                    (Monthly) <span style={Style.subTextCancelAnyTimeStyle}>Cancel any time</span>
                </Typography>
            )
        case PaymentType.CRYPTO:
            return null

        default:
            return 'none'
    }
}
const Style = {
    mainBox: {
        height: "100%",
        display: "flex",
        padding: "32px",
        paddingBottom: '0px',
        flexDirection: "column",
        gap: "24px",
        alignSelf: "stretch",
    },
    headingBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch"
    },
    step: {
        color: "var(--text-icons-light-base-second, #858C95)",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0.12px",
        textTransform: "uppercase"
    },
    title: {
        color: "var(--text-icons-light-base-main, #323539)",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "-0.2px"
    },
    description: {
        color: "var(--text-icons-light-base-second, #858C95)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.16px"
    },
    radioButtonCard: {},
    radioGroupContainer: {
        width: '100%',
        overflowY: 'auto'
    },
    radioButtonGroup: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '10px', md: '20px', lg: '20px' }
    },
    radioButtonSelectedCard: {
        boxShadow: '0px 0px 21px 0px rgba(0, 0, 0, 0.35)'
    },
    radioButtonItem: {
        display: 'flex',
        // height: '104px',
        // width: '450px',
        width: '100%',
        padding: '16px 0px 16px 16px',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
        borderRadius: '12px',
        border: '1px solid var(--gray-200, #EAECF0)',
        background: 'var(--base-white, #FFF)'
    },
    radioButtonSelectedItem: {
        display: 'flex',
        // height: '104px',
        // width: '450px',
        padding: '16px 0px 16px 16px',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
        borderRadius: '12px',
        border: '2px solid var(--dot-color-blue-b-300, #5A27DD)',
        background: 'var(--primary-50, #F9F5FF)',
        boxShadow: '0px 0px 21px 0px rgba(0, 0, 0, 0.35)'
    },
    secureInfo: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        height: '20px'
    },
    secureText: {
        color: 'var(--gray-600, #475467)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px'
    },
    dialogActions: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',
        alignSelf: 'stretch'
    },
    saveButton: {
        display: 'flex',
        padding: '10px 18px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
        borderRadius: '8px',
        border: '1px solid var(--dot-color-blue-b-300, #5A27DD)',
        background: 'var(--dot-color-blue-b-300, #5A27DD)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
    },
    cancelButton: {
        display: 'flex',
        padding: '10px 18px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
        borderRadius: '8px',
        border: '1px solid var(--gray-300, #D0D5DD)',
        background: 'var(--base-white, #FFF)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
    },
    iconStyle: {
        display: 'flex',
        width: '32px',
        height: '32px',
        padding: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '28px',
        border: '4px solid var(--primary-50, #F9F5FF)',
        background: 'var(--primary-100, #F4EBFF)',
        mixBlendMode: 'multiply'
    },
    paymentTypeTextStyle: {
        color: 'var(--gray-700, #344054)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
    },
    subtextStyle: {
        color: '#000',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px'
    },
    subTextCancelAnyTimeStyle: {
        color: '#000',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px'
    },
    outsidePaymentTypeHelperIconBoxStyle: {
        display: 'flex'
    },
    paymentTypeHelperIconBoxStyle: { display: 'flex', gap: 1, alignItems: 'center' },
    paymentDetailsBox: {
        display: 'flex',
        // flexDirection: { xs: 'column', md: 'row', lg: 'row' },
        alignItems: 'flex-end',
        flex: 1,
        gap: 3
    },
    mostPopularTextBox: {
        display: 'flex',
        innerWidth: '65px',
        padding: '2px 10px',
        alignItems: 'center',
        borderRadius: '16px',
        background: 'rgba(127, 86, 217, 0.10)',
        mixBlendMode: 'multiply'
    },
    mostPopularChipTextStyle: {
        color: '#6941C6',
        textAlign: 'center',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px'
    },
    popularBoxContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        width: '100%'
    },
    chip: {
        background: '#FFFAEB',
        borderRadius: '4px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        padding: '2px 6px',
        margin: '0',
        innerWidth: '20px',
        innerHeight: '150px',
        color: 'warning'
    },
    chipTextStyle: {
        fontSize: { xs: '10px', md: '14px', lg: '14px' },
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#B54708'
    }
}
export default Step3b
