import { Box } from '@mui/material'
import FeatureImage from './FeatureImage'
import RelevantAudiences from './RelevantAudiences'

export default function Features() {
  return (
    <Box component={'section'} id="Features">
      <FeatureImage />
      <RelevantAudiences />
    </Box>
  )
}
