declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const sendNewRegistrationEventToGTM = (data: any) => {
    window.dataLayer.push({
        event: 'userRegistered',
        eventValue: data?.userEmail
    });
}

export const sendNewPaymentEventToGTM = (data: any) => {
    window.dataLayer.push({
        event: 'userPayment',
        eventValue: data?.userEmail
    })
}