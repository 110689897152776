import * as DotGQL from '../../services/graphQL'
import { DefaultParamForFetchingCreatives } from '../../util/constants'
import { NOTIFICATIONS } from '../../util/notification'
import {
  ADD_CREATIVE_SUCCESS,
  CREATIVE_DETAIL_SUCCESS,
  CREATIVE_LIST_BY_CAMPAIGN_SUCCESS,
  LIST_CREATIVE_SUCCESS,
  SIZE_LIST
} from '../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from './customization'

export const sizeList = [
  {
    id: 1,
    type: 'primary',
    name: '300x250',
    width: '300px',
    height: '250px'
  },
  {
    id: 2,
    type: 'primary',
    name: '728x90',
    width: '728px',
    height: '90px'
  },
  {
    id: 3,
    type: 'primary',
    name: '320x100',
    width: '320px',
    height: '100px'
  },
  {
    id: 4,
    type: 'primary',
    name: '160x600',
    width: '160px',
    height: '600px'
  },
  {
    id: 6,
    type: 'general',
    name: '468x60',
    width: '468px',
    height: '60px'
  },
  {
    id: 7,
    type: 'general',
    name: '125x125',
    width: '125px',
    height: '125px'
  },
  {
    id: 8,
    type: 'general',
    name: '250x250',
    width: '250px',
    height: '250px'
  },
  {
    id: 9,
    type: 'general',
    name: '300x100',
    width: '300px',
    height: '100px'
  },
  {
    id: 12,
    type: 'general',
    name: '120x240',
    width: '120px',
    height: '240px'
  },
  {
    id: 13,
    type: 'general',
    name: '120x600',
    width: '120px',
    height: '600px'
  },
  {
    id: 14,
    type: 'general',
    name: '180x150',
    width: '180px',
    height: '150px'
  },
  {
    id: 15,
    type: 'general',
    name: '300x600',
    width: '300px',
    height: '600px'
  },
  {
    id: 16,
    type: 'general',
    name: '320x50',
    width: '320px',
    height: '50px'
  },
  {
    id: 19,
    type: 'general',
    name: '970x90',
    width: '970px',
    height: '90px'
  },
  {
    id: 24,
    type: 'general',
    name: '200x200',
    width: '200px',
    height: '200px'
  },
]

// get Document List
export const getSizeList = () => {
  return {
    type: SIZE_LIST,
    data: sizeList
  }
}

export const addCreative = (client: any, req: any, callback: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationAddCreative,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.creativeAddSuccess))
        dispatch({
          type: ADD_CREATIVE_SUCCESS,
          data: response?.data?.creativeList
        })
        callback()
        dispatch(getCreativeList(client, DefaultParamForFetchingCreatives))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.creativeAddFail, error.message))
      })
  }
}

export const getCreativeList = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCreativeList,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: LIST_CREATIVE_SUCCESS,
          data: response?.data?.creativeList
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.creativeFetchFail, error.message))
      })
  }
}

export const deleteCreative = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryDeleteCreative,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.creativeDeleteSuccess))
        dispatch(getCreativeList(client, DefaultParamForFetchingCreatives))
      })
      .catch((error: any) => {
        if (typeof error.message == "string") {
          dispatch(notificationFail(error, error.message))
        } else {
          dispatch(notificationFail(NOTIFICATIONS.ERROR.deleteCreativeFail))
        }
        dispatch(loadingStop())
      })
  }
}

export const editCreative = (client: any, req: any, callback: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationEditCreative,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.editCreativeSuccess))
        dispatch(getCreativeList(client, DefaultParamForFetchingCreatives))
        callback()
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.editCreativeFail, error.message))
      })
  }
}

export const getCreativeListByCampaign = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCreativeList,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: CREATIVE_LIST_BY_CAMPAIGN_SUCCESS,
          data: response?.data?.creativeList
        })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.creativeFetchFail, error.message))
      })
  }
}