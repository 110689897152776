import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: 'AIzaSyB-579chGrBrpLktGFeVep5Ud2OReYhq14',
  authDomain: 'dot-advertisment-dev.firebaseapp.com',
  projectId: 'dot-advertisment-dev',
  storageBucket: 'dot-advertisment-dev.appspot.com',
  messagingSenderId: '197177864748',
  appId: '1:197177864748:web:d02c85869bbe1d7fc71789',
  measurementId: 'G-W2QJMP9WQR'
}

const app = initializeApp(firebaseConfig)
const appSecondary = initializeApp(firebaseConfig, 'adminCreatesUser')

export const auth = getAuth(app)
export const storage = getStorage(app)

export const authSecondary = getAuth(appSecondary)
export default app
