import mixpanel from 'mixpanel-browser';
import { config } from './config';
import { Event, bannerUploaded, campaignBudgetDetailsAdded, campaignBudgetSubmitted, campaignCreationInitiated, campaignPreviewed, campaignPublished, feedbackSubmitted, getBannerClicked, homePageButtonClicked, loggedIn, newCampaignButtonClicked, onboardingCompleted, pageView, signUpButtonClicked, signUpCompleted, signUpInitiated, targettingDetailsAdded, topUpCompleted, topUpInitiated, uploadBannerClicked, userData, webappButtonClicked } from './types';


const sendMixPanelIdentifyEvent = (email: string) => {
    mixpanel.identify(email);
}
const sendMixPanelSetEvent = (data: userData) => {
    mixpanel.people.set(data)
}

const sendMixPanelTrackEvent = (event: string, data: any) => {
    try {
        data.platform = config.platform;
        data['Page Title'] = document.title;
        if (config.sendMixPanelEvents) {
            mixpanel.track(event, data);
        }
    } catch (error: any) {
    }
}

export const identifyUser = (email: string) => {
    sendMixPanelIdentifyEvent(email)
}

export const setUser = (data: userData) => {
    sendMixPanelSetEvent(data)
}

export const page_viewed = (data: pageView) => {
    sendMixPanelTrackEvent(Event.PageViewed, data)
}

export const signup_button_clicked = (data: signUpButtonClicked) => {
    sendMixPanelTrackEvent(Event.SignupButtonClicked, data)
}

export const signup_initiated = (data: signUpInitiated) => {
    sendMixPanelTrackEvent(Event.SignupInitiated, data)
}

export const signup_completed = (data: signUpCompleted) => {
    sendMixPanelTrackEvent(Event.SignupCompleted, data)
}

export const logged_in = (data: loggedIn) => {
    sendMixPanelTrackEvent(Event.LoggedIn, data)
}

export const new_campaign_button_clicked = (data: newCampaignButtonClicked) => {
    sendMixPanelTrackEvent(Event.NewCampaignButtonClicked, data)
}

export const campaign_creation_initiated = (data: campaignCreationInitiated) => {
    sendMixPanelTrackEvent(Event.CampaignCreationInitiated, data)
}

export const targeting_details_added = (data: targettingDetailsAdded) => {
    sendMixPanelTrackEvent(Event.TargetingDetailsAdded, data)
}

export const campaign_budget_details_added = (data: campaignBudgetDetailsAdded) => {
    sendMixPanelTrackEvent(Event.CampaignBudgetDetailsAdded, data)
}

export const campaign_budget_submitted = (data: campaignBudgetSubmitted) => {
    sendMixPanelTrackEvent(Event.CampaignBudgetSubmitted, data)
}

export const upload_banner_clicked = (data: uploadBannerClicked) => {
    sendMixPanelTrackEvent(Event.UploadBannerClicked, data)
}

export const banner_uploaded = (data: bannerUploaded) => {
    sendMixPanelTrackEvent(Event.BannerUploaded, data)
}

export const get_banner_clicked = (data: getBannerClicked) => {
    sendMixPanelTrackEvent(Event.GetBannerClicked, data)
}

export const campaign_previewed = (data: campaignPreviewed) => {
    sendMixPanelTrackEvent(Event.CampaignPreviewed, data)
}

export const campaign_published = (data: campaignPublished) => {
    sendMixPanelTrackEvent(Event.CampaignPublished, data)
}

export const webapp_button_clicked = (data: webappButtonClicked) => {
    sendMixPanelTrackEvent(Event.WebappButtonClicked, data)
}

export const topup_initiated = (data: topUpInitiated) => {
    sendMixPanelTrackEvent(Event.TopUpInitiated, data)
}

export const topup_completed = (data: topUpCompleted) => {
    sendMixPanelTrackEvent(Event.TopUpCompleted, data)
}

export const homepage_button_clicked = (data: homePageButtonClicked) => {
    sendMixPanelTrackEvent(Event.HomepageButtonClicked, data)
}

export const feedback_submitted = (data: feedbackSubmitted) => {
    sendMixPanelTrackEvent(Event.FeedbackSubmitted, data)
}

export const onboarding_compeleted = (data: onboardingCompleted) => {
    sendMixPanelTrackEvent(Event.OnboardingCompeleted, data)
}