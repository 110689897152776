const redirect_url_prod = 'https://ads.dotaudiences.com/?emailVerified=true&email=';
const redirect_url_dev = 'https://dot-advertiser-dashboard-uhztu.ondigitalocean.app/?emailVerified=true&email=';
const redirect_url_local = 'http://localhost:3000/?emailVerified=true&email=';

export const servicesConfig = {
    firebase: {
        redirect_url: process.env.REACT_APP_NODE_ENV === 'production' ? redirect_url_prod
            : process.env.REACT_APP_NODE_ENV === 'development' ? redirect_url_dev
                : redirect_url_local
    }
};
