import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

// material-ui
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// assets
import { MENU_OPEN, SET_MENU } from '../../../../../store/actions'
import { webapp_button_clicked } from '../../../../../services/mixpanel'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any) => {
  const theme: any = useTheme()
  const dispatch: any = useDispatch()
  const customization = useSelector((state: any) => state.customization)
  const leftDrawerOpened = useSelector((state: any) => state.customization.opened)

  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps: any = {
    component: forwardRef((props, ref: any) => (
      <Link id={item?.id} ref={ref} {...props} to={item.url} target={itemTarget} />
    ))
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = (id: any) => {
    dispatch({ type: MENU_OPEN, id })
    if (matchesSM) {
      dispatch({ type: SET_MENU, opened: leftDrawerOpened })
    }
  }

  // active menu item on page load
  useEffect(() => {
    const button_name = item.title.toLowerCase()
    webapp_button_clicked({button_name})
    if (item?.url && document.location.pathname.includes(item?.url)) {
      dispatch({ type: MENU_OPEN, id: item.id })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '8px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      selected={customization.isOpen.findIndex((id: any) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ marginRight: '10px', my: 'auto', minWidth: !item?.icon ? 18 : 36, fontSize: '1.5rem' }}>
        {item?.icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={customization.isOpen.findIndex((id: any) => id === item.id) > -1 ? 'body1' : 'body1'}
            color="inherit"
          >
            {item.title}
            {item?.linkIcon && (
              <img
                style={{ marginLeft: '3.5px', marginBottom: '4px' }}
                src={item?.linkIcon}
                alt="link"
                width="9px"
                height="9px"
              />
            )}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
}

export default NavItem
