import Dialog from '@mui/material/Dialog'
import Login from '../auth/Login'
import LoginWithEmail from '../auth/LoginWithEmail'
import Register from '../auth/Register'

export default function AuthModal(props: any) {
  const { open, handleClose, step, setStepValue, userEmail } = props

  const renderUI = () => {
    switch (step) {
      case 1:
        return <Login step={step} handleClose={handleClose} setStepValue={setStepValue} userEmail={userEmail} />

      case 2:
        return <Register step={step} handleClose={handleClose} setStepValue={setStepValue} />

      case 3:
        return <LoginWithEmail step={step} handleClose={handleClose} setStepValue={setStepValue} />

      default:
        break
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      {renderUI()}
    </Dialog>
  )
}
