import { Box, Button, Divider } from '@mui/material'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { approveCampaignToManual, createUpdateCampaignInGam, updateStatusCampaign, syncCampaignSpentDetail, syncCampaignStatus, pauseCampaignAdmin, resumeCampaignAdmin } from '../../../store/actions/campaign/adminActions'
import { getCampaignDetail } from '../../../store/actions/campaign/list'
import {
  CampaignAdminActions,
  CampaignChannel,
  campaignCustomerActions,
  CampaignStatus,
  UserType
} from '../../../util/constants'
import CopyToClipBoard from '../../modals/CopyToClipBoard'
import DatePickerDialog from '../../modals/DatePickerDialog'
import { initialDate } from '../../../util/constants'
import { dateRangeToDays } from '../../../util/dateRangeToDays'
interface FooterActionsProps {
  campaign_id: any
}
const FooterActions = ({ campaign_id }: FooterActionsProps) => {
  const dispatch: any = useDispatch()
  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [date1, setDate1] = useState(initialDate);
  const [dateConfirmed, setDateConfirmed] = useState(false);
  const client = useSelector((state: any) => state.apolloClient.client)
  const campaignDetail = useSelector((state: any) => state.campaignDetailReducer.campaignDetail)
  const platFormUser = useSelector((state: any) => state.authReducer)

  const getDetail = () => {
    if (campaign_id) {
      let req = {
        campaign_id: campaign_id
      }
      dispatch(getCampaignDetail(client, req))
    }
  }
  const getDetailAndCopy = () => {
    if (campaign_id) {
      setOpen(true)
      let req = {
        campaign_id: campaign_id
      }
      dispatch(getCampaignDetail(client, req))
    }
  }
  useEffect(() => {
    if (dateConfirmed) {
      const start_date = date1?.startDate as Date | undefined;
      const end_date = date1?.endDate as Date | undefined;
      if (start_date && end_date) {
        const {startDate,endDate} = dateRangeToDays(start_date, end_date);
        let requests = {
          campaign_id: campaignDetail?._id,
          startDate,
          endDate
        }
        dispatch(syncCampaignSpentDetail(client, requests) as any)
      }
      setDateConfirmed(false)
    }
  }, [dateConfirmed])
  const handleClose = () => {
    setOpenDatePicker(false);
  }

  const actionButtonsHandler = async (action: string) => {
    switch (action) {
      case CampaignAdminActions.APPROVE_GAM:
        let req = {
          _id: campaignDetail?._id,
          status: CampaignStatus.APPROVED
        }
        dispatch(createUpdateCampaignInGam(client, req, getDetail))
        break
      case CampaignAdminActions.APPROVE_MANUAL:
        let params = {
          _id: campaignDetail?._id,
          status: CampaignStatus.APPROVED,
          channel: CampaignChannel.MANUAL
        }
        dispatch(approveCampaignToManual(client, params, getDetailAndCopy))
        break
      case CampaignAdminActions.SYNC_STATUS:
        let param = {
          campaignId: campaignDetail?._id,
        }
        dispatch(syncCampaignStatus(client, param))
        break
      case CampaignAdminActions.SYNC_ANALYTICS:
        setOpenDatePicker(true)
        break
      case CampaignAdminActions.PAUSE_CAMPAIGN:
        let pauseRequest = {
          _id: campaignDetail?._id,
        }
        dispatch(pauseCampaignAdmin(client, pauseRequest))
        break
      case CampaignAdminActions.RESUME_CAMPAIGN:
        let resumeRequest = {
          _id: campaignDetail?._id,
        }
        dispatch(resumeCampaignAdmin(client, resumeRequest))
        break
      case CampaignAdminActions.REJECT:
        let request = {
          _id: campaignDetail?._id,
          status: CampaignStatus.REJECTED,
          channel: campaignDetail?.channel || 'none'
        }
        dispatch(updateStatusCampaign(client, request, getDetail))
        break
    }
  }
  const handleDialogueClose = () => {
    setOpen(false)
  }
  
  return (
    <Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt={'20px'} gap={2}>
        {campaignDetail?.status === CampaignStatus.ACTIVE && (
          <Button
            sx={buttonStyle}
            onClick={() => actionButtonsHandler(CampaignAdminActions.PAUSE_CAMPAIGN)}
          >
            {campaignCustomerActions.pauseCampaign}
          </Button>)}
        {campaignDetail?.status === CampaignStatus.PAUSED && (
          <Button
            sx={buttonStyle}
            onClick={() => actionButtonsHandler(CampaignAdminActions.RESUME_CAMPAIGN)}
          >
            {campaignCustomerActions.resumeCampaign}
          </Button>)}
        <Button
          sx={buttonStyle}
          disabled={[CampaignStatus.PENDING_APPROVAL].includes(campaignDetail?.status)}
          onClick={() => actionButtonsHandler(CampaignAdminActions.SYNC_STATUS)}
        >
          {campaignCustomerActions.syncStatus}
        </Button>
        <Button
          sx={buttonStyle}
          disabled={[CampaignStatus.PENDING_APPROVAL].includes(campaignDetail?.status)}
          onClick={() => actionButtonsHandler(CampaignAdminActions.SYNC_ANALYTICS)}
        >
          {campaignCustomerActions.syncAnalytics}
        </Button>
        <Button
          id="btnApproveToGAM"
          sx={buttonStyle}
          disabled={![CampaignStatus.PENDING_APPROVAL].includes(campaignDetail?.status)}
          onClick={() => actionButtonsHandler(CampaignAdminActions.APPROVE_GAM)}
        >
          {campaignCustomerActions.ApproveToGAM}
        </Button>
        {platFormUser.userType === UserType.SUPER_ADMIN && (
          <Button
            id="btnApproveToManual"
            disabled={![CampaignStatus.PENDING_APPROVAL].includes(campaignDetail?.status)}
            sx={buttonStyle}
            onClick={() => actionButtonsHandler(CampaignAdminActions.APPROVE_MANUAL)}
          >
            {campaignCustomerActions.ApproveToManual}
          </Button>
        )}
        <Button
          id="btnReject"
          disabled={![CampaignStatus.PENDING_APPROVAL].includes(campaignDetail?.status)}
          sx={buttonStyle}
          onClick={() => actionButtonsHandler(CampaignAdminActions.REJECT)}
        >
          {campaignCustomerActions.Reject}
        </Button>
      </Box>
      {open && (
        <CopyToClipBoard open={open} handleClose={handleDialogueClose} campaignDetail={campaignDetail?.campaigns} />
      )}
      {
        openDatePicker && <DatePickerDialog open={openDatePicker} handleClose={handleClose} date={date1} setDate={setDate1} setDateConfirmed={setDateConfirmed} />
      }
    </Box>
  )
}

const buttonStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 16px',
  gap: '8px',
  width: '84px',
  height: '40px',
  background: '#FFFFFF',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '8px',
  // font style
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#344054'
}

export default FooterActions
