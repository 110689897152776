import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { RiHome3Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import LinkComponent from '../../../layout/ui-components/LinkComponent'
import { LinkWithIcon } from '../../../layout/ui-components/LinkWithIconAdmin'
import MainCard from '../../../layout/ui-components/MainCard'
import { getCampaignDetail } from '../../../store/actions/campaign/list'
import { NAVIGATION, UserType } from '../../../util/constants'
import CampaignDetailsInfo from './campaign-info'
import FooterActions from './FooterActions'
import PreviousCampaignValuesModal from '../../modals/PreviousCampaignValuesDialog'

const DetailCampaign = (props: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth: any = useSelector((state: any) => state.authReducer)

  const [showModal, setShowModal] = useState(false)
  const client = useSelector((state: any) => state.apolloClient.client)
  const platFormUser = useSelector((state: any) => state.authReducer)
  const campaignDetails = useSelector((state: any) => state.campaignDetailReducer.campaignDetail)
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      let req = {
        campaign_id: id
      }

      dispatch(getCampaignDetail(client, req) as any)
    } else {
      navigate(NAVIGATION.HOME)
    }
  }, [id])

  const handleClose = () => {
    setShowModal(false)
  }

  const breadcrumbs = [
    <LinkComponent key="1" icon={<RiHome3Line />} to="/" />,
    <LinkComponent
      key="2"
      text={'Campaigns'}
      to={auth?.userType === UserType.ADMIN || auth?.userType === UserType.SUPER_ADMIN ? '/' : '/campaigns'}
    />,
    <Typography key="3" color="text.primary">
      Details
    </Typography>
  ]

  return (
    <MainCard
      title="Campaign Details and Analytics"
      className="MainCard"
      breadcrumbs={breadcrumbs}
      actions={<LinkWithIcon name={campaignDetails?.utm_url} URL={campaignDetails?.utm_url} campaignDetails={campaignDetails}/>}
      subHeader={'Analytics for Campaigns'}
      content={false}
    >
      {campaignDetails?.previous_campaign_values &&
        (platFormUser?.userType === UserType.SUPER_ADMIN || platFormUser?.userType === UserType.ADMIN) && (
          <Button onClick={() => setShowModal(true)}>
            <Typography>View Previous Campaign Values</Typography>
          </Button>
        )}
      {campaignDetails && (
        <>
          <CampaignDetailsInfo campaignDetails={campaignDetails} />

          {platFormUser?.userType === UserType.SUPER_ADMIN && <FooterActions campaign_id={id} />}
          {platFormUser?.userType === UserType.ADMIN && <FooterActions campaign_id={id} />}
        </>
      )}

      {showModal && (
        <PreviousCampaignValuesModal open={showModal} handleClose={handleClose} campaignDetails={campaignDetails} />
      )}
    </MainCard>
  )
}

export default DetailCampaign
