// action - state management
import * as actionTypes from '../../actionTypes'
import { updateObject } from '../../shared/utility'

interface actionType {
  type: string
  payload: any
}

export const initialState = {
  loading: true,
  error: null,
  recentCampaignList: [],
  recentDeposit: []
}

// ==============================|| DASHBOARD REDUCER ||============================== //

const dashboardReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.GETTING_RECENT_CAMPAIGN_SUCCESS:
      return updateObject(state, {
        recentCampaignList: action.payload
      })

    case actionTypes.GETTING_RECENT_DEPOSIT_SUCCESS:
      return updateObject(state, {
        loading: false,
        recentDeposit: action.payload
      })

    default:
      return state
  }
}

export default dashboardReducer
