import { Box, TableCell, TableRow } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { HiDocumentSearch } from 'react-icons/hi'

const EmptyRow = ({ totalColumn }: any) => {
  const theme: any = useTheme()

  return (
    <TableRow>
      <TableCell colSpan={totalColumn}>
        <Box
          sx={{
            m: 3,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <HiDocumentSearch size={150} color={theme.palette?.primary.main} />
            <h3>No results found</h3>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )
}
export default EmptyRow
