// Sections
import { Box } from '@mui/material'
import Footer from './components/Footer'
import TopNavbar from './components/Nav/TopNavbar'
import AboutUs from './components/Sections/AboutUs'
import Features from './components/Sections/Features'
import Header from './components/Sections/Header'
import PRArticles from './components/Sections/PRArticles'
import Testimonials from './components/Sections/Testimonials'
import { useEffect } from 'react'
import { page_viewed } from '../../services/mixpanel'
import { getUrl, getUtmValues } from '../../util/helperFunctions'

export default function Landing() {
  useEffect(() => {
    // adding this for Google Analytics
    // document.title = 'DOT Ads - Bitcoin and Crypto Ad Network'
    const url = getUrl()
    const { utm_source, utm_medium, utm_campaign } = getUtmValues(url)
    const data = {
      url,
      page_name: 'landing',
      utm_source,
      utm_medium,
      utm_campaign
    }
    page_viewed(data)
    return () => {
      document.title = 'DOT Ads Dashboard'
    }
  }, [])

  return (
    <Box>
      <TopNavbar />
      <Header />
      <Features />
      <Testimonials />
      <AboutUs />
      <PRArticles />
      <Footer />
    </Box>
  )
}
