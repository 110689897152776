import { Box, Grid, Typography } from '@mui/material'
const ShapeLeft = process.env.PUBLIC_URL + '/images/headershape.webp'
const AboutUsVector2 = process.env.PUBLIC_URL + '/images/aboutUsVector.webp'

export default function AboutUs() {
  return (
    <Box component={'section'} id="AboutUs">
      <Box
        sx={{
          padding: { xs: '40px 0px', md: '40px 0px' }
        }}
      >
        <Grid container className="container fade-bottom reveal" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
          <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              className="fade-bottom reveal"
              sx={{ position: 'relative', display: 'table', margin: { xs: '0 auto 30px', md: '0 0px 30px' } }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: { xs: '-20px', md: '-42px' },
                  top: '-27%',
                  img: {
                    width: { xs: '23px', md: '44px' }
                  }
                }}
              >
                <img aria-label="For ShapeLeft" src={ShapeLeft} alt="not displayShapeLeft" />
              </Box>
              <Typography variant="h2" sx={{ fontSize: { xs: '32px', md: '48px' } }}>
                About Us
              </Typography>
            </Box>
            <Box
              sx={{
                padding: { xs: 0, md: '125px' },
                display: { xs: 'none', md: 'block' },
                width: { xs: '23px', md: '44px' }
              }}
              className="fade-bottom reveal"
            >
              <img
                width="260"
                height="69"
                aria-label="For AboutUsVector2"
                src={AboutUsVector2}
                alt=" not displayAboutUsVector"
              />
            </Box>
          </Grid>
          <Grid className="fade-bottom reveal" item xs={12} md={1} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <img
              src={process.env.PUBLIC_URL + '/images/comaVector.png'}
              height="32px"
              width="36px"
              aria-label="For ComaVectorzsd"
              alt=" not displayComaVector"
            />
          </Grid>
          <Grid className="fade-bottom reveal" item xs={12} md={7} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography
              variant="h5"
              sx={{ fontSize: { xs: '16px', md: '24px' }, lineHeight: { xs: '24px', md: '36px' } }}
            >
              "We understand the challenges both Web3 and emerging tech companies face when searching for relevant
              audiences. Add too the hostility of traditional platforms like Google and Facebook Ads, and suddently
              growth seems like an uphill struggle.
            </Typography>
            <br />
            <Typography
              variant="h5"
              sx={{ fontSize: { xs: '16px', md: '24px' }, lineHeight: { xs: '24px', md: '36px' } }}
            >
              DOT has been operating out of the UK since 2018, and our goal is simple; to give back power to the
              founders, marketeers, and growth hackers seeking relevant audiences, and cost effective conversions. We
              look forward to working with you. From all of us at Team DOT"
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
