import { addDays } from 'date-fns'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential
} from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import Cookies from 'js-cookie'
import { client } from '../ApolloProvider'
import * as DotGQL from '../services/graphQL'
import { loadingStop, logoutUser, notificationFail, notificationSuccess } from '../store/actions'
import { LoginAuthProvider } from '../types/auth'
import { FireBaseError, SignUpDefaults, UserStatus, UserType } from '../util/constants'
import { ILogInArgs, ISignUpArgs } from '../util/types'
import { loadingStart } from './../store/actions'
import { NOTIFICATIONS } from './../util/notification'
import { auth, authSecondary, firebaseConfig } from './firebase'
import { FirebaseErrorHandling } from './firebase/FirebaseError'
import { setEncryptedCookie } from './encryption.ts'
import { createRedirectUrl } from '../util/helperFunctions'
import { servicesConfig } from './config'
// import { sendNewRegistrationEventToGTM } from './googleTagManager'

export const getCurrentUser = () => {
  return auth.currentUser
}

export const signUpToApi = async (
  firebaseResult: UserCredential,
  args?: ISignUpArgs
): Promise<{
  success: boolean
  user?: any
  error?: Error
}> => {
  if (!firebaseResult.user) {
    return {
      success: false,
      error: new Error('No user found')
    }
  }

  const bearerToken = await firebaseResult?.user?.getIdToken()

  try {
    const response = await client.mutate({
      mutation: DotGQL.mutationAddUser,
      fetchPolicy: 'no-cache',
      variables: {
        full_name: firebaseResult?.user?.displayName || SignUpDefaults.DEFAULT_USER_FULL_NAME,
        parent_id: SignUpDefaults.DEFAULT_USER_PARENT_ID,
        email: firebaseResult.user.email,
        user_type: UserType.CUSTOMER,
        profile_image: firebaseResult?.user?.photoURL || SignUpDefaults.DEFAULT_USER_PROFILE_IMAGE,
        deposit_amount: SignUpDefaults.DEFAULT_USER_DEPOSIT_AMOUNT,
        spent_amount: SignUpDefaults.DEFAULT_USER_SPENT_AMOUNT,
        available_balance: SignUpDefaults.DEFAULT_USER_AVAILABLE_BALANCE,
        status: SignUpDefaults.DEFAULT_USER_STATUS,
        commission: SignUpDefaults.DEFAULT_USER_COMMISSION,
        stripe_customer_id: SignUpDefaults.DEFAULT_USER_STRIPE_CUSTOMER_ID,
        fuid: firebaseResult.user.uid,
        phone: SignUpDefaults.DEFAULT_USER_PHONE_NUMBER,
        dial_code: SignUpDefaults.DEFAULT_USER_DIAL_CODE,
        preferred_messenger: SignUpDefaults.DEFAULT_USER_PREFERRED_MESSENGER,
        messenger: args?.messenger || SignUpDefaults.DEFAULT_USER_PREFERRED_MESSENGER
      },
      context: {
        headers: {
          authorization: `Bearer ${bearerToken}`
        }
      }
    })
    // sending trigger to GTM using Data Layer
    // if (response.data?.addUser && response.data?.addUser?.is_new_user === true) {
    //   let data = {
    //     userEmail: response?.data?.addUser?.email,
    //   }
    //   sendNewRegistrationEventToGTM(data)
    // }
    if (response.data?.addUser && response.data?.addUser?.status === UserStatus.ACTIVE) {
      //strong userData to Cookies
      Cookies.set('dot-ad-token', bearerToken, {
        secure: false,
        expires: addDays(new Date(), 7)
      })
      setEncryptedCookie('ad-settings', response.data?.addUser?.user_type)
      return {
        success: true,
        user: { ...firebaseResult.user, ...response.data }
      }
    } else {
      return {
        success: false,
        error: new Error(NOTIFICATIONS.ERROR.userDisabled)
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: new Error(FireBaseError.PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN)
    }
  }
}

export const loginWithFirebase = async (
  provider: LoginAuthProvider,
  args?: ILogInArgs
): Promise<{
  success: boolean
  user?: any
  error?: Error
}> => {
  if (provider === LoginAuthProvider.Email) {
    if (args) return await loginWithEmailFirebase(args)
  }
  if (provider === LoginAuthProvider.Google) {
    return await loginWithGoogle()
  }
  if (provider === LoginAuthProvider.Facebook) {
    return await loginWithFacebook()
  }
  return {
    success: false,
    error: new Error(FireBaseError.PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN)
  }
}

export const loginWithFirebaseForOneTapLogin = async (
  credentialResponse?: any
): Promise<{
  success: boolean
  user?: any
  error?: Error
}> => {
  try {
    const credential = GoogleAuthProvider.credential(credentialResponse.credential);
    const firebaseResponse = await signInWithCredential(auth, credential);
    return await signUpToApi(firebaseResponse);
  } catch (error) {
    return {
      success: false,
      error: new Error(FireBaseError.PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN)
    }
  }
}

export const loginWithEmailFirebase = async (args: { email: string; password: string }) => {
  try {
    const firebaseResponse = await signInWithEmailAndPassword(auth, args.email, args.password) //sign upto firebase api
    if (firebaseResponse.user?.emailVerified) {
      return await signUpToApi(firebaseResponse, args) //sign up to our local api
    } else {
      await auth.signOut()
      return {
        success: false,
        error: new Error(FireBaseError.EMAIL_NOT_VERIFIED)
      }
    }
  } catch (error: any) {
    if (error?.code === FireBaseError.USER_NOT_FOUND) {
      //handling email not found
      return {
        success: false,
        error: new Error(FireBaseError.NO_USER_FOUND)
      }
    } else if (error?.code === FireBaseError.INVALID_PASSWORD) {
      //handling wrong password
      return {
        success: false,
        error: new Error(FireBaseError.WRONG_PASSWORD)
      }
    } else {
      //Every other edge case
      return {
        success: false,
        error: new Error(FireBaseError.PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN)
      }
    }
  }
}

export const registerWithEmailFirebase = async (
  args: ISignUpArgs
): Promise<{
  success: boolean
  error?: Error
}> => {
  try {
    const firebaseResponse = await createUserWithEmailAndPassword(auth, args.email, args.password)
    try {
      const redirectUrl = createRedirectUrl(args.email, servicesConfig.firebase.redirect_url)
      await sendEmailVerification(firebaseResponse?.user, {
        url: redirectUrl, // Specify your login page URL here
        handleCodeInApp: false, // Set to false to redirect in the same browser tab})
      })
      return {
        success: true
      }
    } catch (error: any) {
      await auth.signOut()
      return {
        success: false,
        error: new Error(FireBaseError.PROBLEM_WITH_SIGNUP_PLEASE_TRY_AGAIN)
      }
    }
  } catch (error: any) {
    if (error?.code === FireBaseError.EMAIL_ALREADY_IN_USE) {
      return {
        success: false,
        error: new Error(FireBaseError.EMAIL_EXISTS)
      }
    }
    //handling every other useCase. [can be more precised]
    return {
      success: false,
      error: new Error(FireBaseError.PROBLEM_WITH_SIGNUP_PLEASE_TRY_AGAIN)
    }
  }
}

export const loginWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    const firebaseResponse = await signInWithPopup(auth, provider)
    return await signUpToApi(firebaseResponse)
  } catch (error: any) {
    return {
      success: false,
      error: new Error('There was a problem with your google login. Please try again')
    }
  }
}

export const loginWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider()
    const firebaseResponse = await signInWithPopup(auth, provider)
    return await signUpToApi(firebaseResponse)
  } catch (error: any) {
    return {
      success: false,
      error: new Error('There was a problem with your facebook login. Please try again')
    }
  }
}

export const resetPassword = async (
  email: string
): Promise<{
  success: boolean
  response?: any
  error?: Error
}> => {
  try {
    const firebaseResponse = await sendPasswordResetEmail(auth, email)
    return {
      success: true,
      response: firebaseResponse
    }
  } catch (error: any) {
    if (error?.code === FireBaseError.USER_NOT_FOUND) {
      return {
        success: false,
        error: new Error(FireBaseError.NO_USER_FOUND)
      }
    }
    return {
      success: false,
      error: new Error('There was a problem with your password reset. Please try again!')
    }
  }
}

export const logout = async (): Promise<{ success: boolean; error?: Error }> => {
  try {
    await auth.signOut()
    Cookies.remove('dot-ad-token')
    Cookies.remove('ad-settings')

    return {
      success: true
    }
  } catch (error: any) {
    return {
      success: false,
      error: new Error('There was a problem with your logout. Please try again')
    }
  }
}

export const addNewUserWithEmailFirebase = async (
  args: ISignUpArgs
): Promise<{
  success: boolean
  user?: any
  error?: Error
}> => {
  // const secondaryApp = initializeApp(firebaseConfig);
  // const secondaryAuth = getAuth(secondaryApp);

  try {
    const firebaseResponse = await createUserWithEmailAndPassword(authSecondary, args.email, args.password)
    if (firebaseResponse.user?.email) {
      await sendPasswordResetEmail(authSecondary, firebaseResponse?.user?.email)
    }

    return await addNewUserToApi(firebaseResponse, args)
  } catch (error: any) {
    if (error?.code === FireBaseError.EMAIL_ALREADY_IN_USE) {
      return {
        success: false,
        error: new Error(FireBaseError.EMAIL_EXISTS)
      }
    }
    //handling every other useCase. [can be more precised]
    return {
      success: false,
      error: new Error(FireBaseError.PROBLEM_WITH_SIGNUP_PLEASE_TRY_AGAIN)
    }
  }
}

export const addNewUserToApi = async (
  firebaseResult: UserCredential,
  args?: ISignUpArgs
): Promise<{
  success: boolean
  user?: any
  error?: Error
}> => {
  if (!firebaseResult.user) {
    return {
      success: false,
      error: new Error('No user found')
    }
  }
  const bearerToken = await firebaseResult?.user?.getIdToken()

  try {
    const response = await client.mutate({
      mutation: DotGQL.mutationAddUser,
      fetchPolicy: 'no-cache',
      variables: {
        full_name: args?.fullName || SignUpDefaults.DEFAULT_USER_FULL_NAME,
        parent_id: args?.parentId || SignUpDefaults.DEFAULT_USER_PARENT_ID,
        email: firebaseResult.user.email,
        user_type: args?.userType || UserType.CUSTOMER,
        profile_image: SignUpDefaults.DEFAULT_USER_PROFILE_IMAGE,
        deposit_amount: SignUpDefaults.DEFAULT_USER_DEPOSIT_AMOUNT,
        spent_amount: SignUpDefaults.DEFAULT_USER_SPENT_AMOUNT,
        available_balance: SignUpDefaults.DEFAULT_USER_AVAILABLE_BALANCE,
        status: SignUpDefaults.DEFAULT_USER_STATUS,
        commission: SignUpDefaults.DEFAULT_USER_COMMISSION,
        stripe_customer_id: SignUpDefaults.DEFAULT_USER_STRIPE_CUSTOMER_ID,
        fuid: firebaseResult.user.uid,
        phone: args?.phone || SignUpDefaults.DEFAULT_USER_PHONE_NUMBER,
        dial_code: args?.dialCode || SignUpDefaults.DEFAULT_USER_DIAL_CODE,
        preferred_messenger: args?.preferred_messenger || SignUpDefaults.DEFAULT_USER_PREFERRED_MESSENGER,
        messenger: args?.messenger || SignUpDefaults.DEFAULT_USER_PREFERRED_MESSENGER
      },
      context: {
        headers: {
          authorization: `Bearer ${bearerToken}`
        }
      }
    })

    return {
      success: true,
      user: { ...firebaseResult.user, ...response.data }
    }
  } catch (error: any) {
    return {
      success: false,
      error: new Error(FireBaseError.PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN)
    }
  }
}

const reauthenticate = (currentPassword: any) => {
  var user = firebase.auth()?.currentUser
  var cred = firebase.auth.EmailAuthProvider.credential(user?.email!, currentPassword)
  return user?.reauthenticateWithCredential(cred)
}

export const changePassword = (currentPassword: string, newPassword: string) => {
  return (dispatch: any) => {
    dispatch(loadingStart())

    firebase.initializeApp(firebaseConfig)
    reauthenticate(currentPassword)
      ?.then(() => {
        var user = firebase.auth().currentUser
        user
          ?.updatePassword(newPassword)
          .then((data) => {
            dispatch(loadingStop())
            dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.passwordChangeSuccess))
            setTimeout(() => {
              dispatch(logoutUser())
            }, 1000)
          })
          .catch((error: any) => {
            dispatch(loadingStop())
            dispatch(notificationFail(NOTIFICATIONS.ERROR.passwordChangeFail))
          })
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(FirebaseErrorHandling(error?.code)))
      })
  }
}

export const getNewTokenFromFirebase = async () => {
  const token = await getIdTokenFromFirebase()
  if (token) {
    Cookies.remove('dot-ad-token')
    Cookies.set('dot-ad-token', token)
  }
  return token
}
const getIdTokenFromFirebase = async () => {
  const user = auth.currentUser
  if (!user) {
    return null
  }
  return await user.getIdToken()
}
