import DoneIcon from '@mui/icons-material/Done'
import { Box, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const BiddingSection = ({ balance, daily_budget, cpm_bid }: any) => {
  const theme: any = useTheme()

  const getTitle = (name: string) => {
    return (
      <Grid item sx={{ fontSize: '16px', display: 'flex', fontWeight: 'bold' }} md={4}>
        <DoneIcon color="success" sx={{ mr: 2 }} />
        {name}
      </Grid>
    )
  }

  const labelCss = {
    marginBottom: 1,
    color: theme.palette?.grey[500]
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ mt: 2, mb: 3, flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        {getTitle('Bidding')}
        <Grid
          item
          md={8}
          sx={{ mt: { xs: '20px', md: '0' }, maxWidth: '100%  !important', flexBasis: '100%  !important' }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ display: { xs: 'block', md: 'flex' } }}
          >
            <Grid item xs={6} md={4}>
              <Box sx={labelCss}>Remaining Budget</Box>
              <Box sx={biddingCss?.labelCss}>${parseFloat(balance)?.toFixed(2)}</Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box sx={labelCss}>Daily Budget</Box>
              <Box sx={biddingCss?.labelCss}>${parseFloat(daily_budget)?.toFixed(2)}</Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box sx={labelCss}>CPM Bid</Box>
              <Box sx={biddingCss?.labelCss}>${parseFloat(cpm_bid)?.toFixed(2)}</Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default BiddingSection

const biddingCss = {
  labelCss: {
    fontSize: '16px',
    color: '#19181A',
    fontWeight: '600'
  }
}
