import { Box, Link } from '@mui/material'
import { RiFileDownloadFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { downloadFile } from '../../../../util/fileDownloader'

const Creative = () => {
  const creativeList = useSelector((state: any) => state.CreativeDetailReducer.creativeList)

  return (
    <Box>
      {creativeList?.creatives?.map((item: any, i: number) => {
        return (
          <Box display={'flex'} sx={{ alignItems: 'center' }} gap={3} key={i}>
            <Link
              target={'_blank'}
              href={item?.creative_type === 'image' ? item?.url : item?.preview_url}
              color="inherit"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <img src={approvedStatus} width={'14px'} /> */}
                <span style={{ marginLeft: '10px' }}>{item?.name || ''}</span>
              </div>
            </Link>

            <Box
              onClick={() => {
                downloadFile(item?.url, item?.name)
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main'
                }
              }}
            >
              <RiFileDownloadFill size={20} />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default Creative
