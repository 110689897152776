import { sendNewPaymentEventToGTM } from '../../services/googleTagManager'
import * as DotGQL from '../../services/graphQL'
import { NOTIFICATIONS } from '../../util/notification'
import { GETTING_TRANSACTION_SUCCESS } from '../actionTypes'
import { loadingStart, loadingStop, notificationFail } from './customization'
import { settingUser } from './setting'

export const getAddPayment = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationAddPayment,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        // document.location.href = response.data.addPaymentLink
        window.open(response.data.addPaymentLink, '_blank');
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}


export const getAddRecurringPaymentLink = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationAddRecurringPayment,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        // document.location.href = response.data.addRecurringPaymentLink
        window.open(response.data.addRecurringPaymentLink, '_blank');
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}

export const getAddCryptoPaymentLink = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryAddCryptoPaymentLink,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        // document.location.href = response.data.addRecurringPaymentLink
        window.open(response.data.addCryptoPaymentLink, '_blank');
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}
export const getManageSubscriptionLink = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationManageSubscriptionLink,
        fetchPolicy: 'no-cache',
        // variables: { ...req }
      })
      .then((response: any) => {
        document.location.href = response.data.manageSubscriptionLink
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}


export const getUserTransaction = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryTransactionList,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: GETTING_TRANSACTION_SUCCESS,
          data: response?.data?.transactionList
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
      })
  }
}
export const addTransaction = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationAddTransaction,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        let req1 = {
          page: 1,
          limit: 5,
          columnName: '_id',
          order: 'desc',
          isExport: false,
          userId: req?.user_id
        }
        let data = {
          userEmail: response?.data?.addTransaction?.email
        }
        sendNewPaymentEventToGTM(data)
        dispatch(getUserTransaction(client, req1))
        dispatch(settingUser(client))
        window.history.replaceState('', '', '/balance')
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addTransactionFail, error.message))
      })
  }
}
