// login action

import {
  addNewUserWithEmailFirebase,
  loginWithFirebase,
  loginWithFirebaseForOneTapLogin,
  logout,
  registerWithEmailFirebase,
  resetPassword
} from '../../services/auth'
import { sendNewRegistrationEventToGTM } from '../../services/googleTagManager'
import { identifyUser, logged_in, setUser, signup_completed } from '../../services/mixpanel'
// import { sendEmailVerifiedWebhook } from '../../services/zapier'
// import { sendNewRegistrationEventToGTM } from '../../services/googleTagManager'
import { LoginAuthProvider } from '../../types/auth'
import { NOTIFICATIONS } from '../../util/notification'
import {
  ADD_NEW_USER_FAILURE,
  ADD_NEW_USER_SUCCESS,
  GET_AUTH_TOKEN_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD_SENT_FAILURE,
  RESET_PASSWORD_SENT_SUCCESS,
  SETTING_USER_SUCCESS,
  SIGN_UP_USER_FAILURE,
  USER_ALREADY_LOGGED_IN_SUCCESS
} from '../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from './customization'
import { getUserList } from './user'

export const login = (provider: LoginAuthProvider, req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    loginWithFirebase(provider, req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.addUser
        })
        identifyUser(user?.email)
        setUser({ $email: user?.email })
        logged_in({ $email: user?.email, user_role: user?.user_type })
        if (user.addUser?.is_new_user) {
          signup_completed({
            $email: user?.email,
            signup_mode: provider,
            balance: user.addUser?.available_balance,
            user_role: user.addUser?.user_type,
            platform_variant: user.addUser?.onboarding_flow_variant
          })
        }

        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.loginSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const oneTaplogin = (req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    loginWithFirebaseForOneTapLogin(req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.addUser
        })
        identifyUser(user?.email)
        setUser({ "$email": user?.email })
        logged_in({ "$email": user?.email, user_role: user?.user_type })

        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.loginSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const signup = (provider: LoginAuthProvider, req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    loginWithFirebase(provider, req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.addUser
        })

        let data = {
          userEmail: user?.email
        }
        sendNewRegistrationEventToGTM(data)

        identifyUser(user?.email)
        setUser({ $email: user?.email })
        signup_completed({
          $email: user?.email,
          signup_mode: provider,
          balance: user.addUser?.available_balance,
          user_role: user.addUser?.user_type,
          platform_variant: user.addUser?.onboarding_flow_variant
        })
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.signupSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const signUpWithEmail = (req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    registerWithEmailFirebase(req).then(async ({ success, error }) => {
      if (success) {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.emailVerificationMailSent))
        dispatch(loadingStop())
        let data = {
          userEmail: req.email
        }
        sendNewRegistrationEventToGTM(data)
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: SIGN_UP_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const addNewUser = (client: any, req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    addNewUserWithEmailFirebase(req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: ADD_NEW_USER_SUCCESS,
          payload: user
        })

        const listRequest = {
          page: 1,
          limit: 10,
          isExport: false,
          columnName: '_id',
          order: 'desc'
        }
        dispatch(getUserList(client, listRequest))
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.addNewUserSuccess))
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addNewUserFail, error?.message))
        dispatch({
          type: ADD_NEW_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const forgotPassword = (req: any, callBack: any) => {
  return (dispatch: any) => {
    resetPassword(req).then(({ success, response, error }) => {
      if (success) {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.passwordSentSuccess))
        dispatch(loadingStop())
        callBack()
        dispatch({
          type: RESET_PASSWORD_SENT_SUCCESS,
          payload: response
        })
      } else {
        dispatch(notificationFail(NOTIFICATIONS.ERROR.passwordSentFail, error?.message))
        dispatch(loadingStop())
        dispatch({
          type: RESET_PASSWORD_SENT_FAILURE,
          payload: error
        })
        callBack()
      }
    })
  }
}

export const logoutUser = () => {
  return (dispatch: any) => {
    logout().then(({ success, error }) => {
      success
        ? dispatch({
          type: LOGOUT_USER_SUCCESS
        })
        : dispatch({
          type: LOGOUT_USER_FAILURE,
          payload: error
        })
    })
  }
}

export const getInitialState = (req: any) => {
  return (dispatch: any) => {
    dispatch({
      type: USER_ALREADY_LOGGED_IN_SUCCESS,
      payload: req
    })
  }
}

export const getAuthToken = () => {
  return (dispatch: any) => {
    getAuthToken()
    dispatch({
      type: GET_AUTH_TOKEN_SUCCESS
    })
  }
}

export const setAuthToken = () => {
  return (dispatch: any) => {
    setAuthToken()
    dispatch({
      type: GET_AUTH_TOKEN_SUCCESS
    })
  }
}
