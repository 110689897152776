import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import AuthModal from '../../views/modals/AuthDialog'

export default function GreenButton(props: any) {
  const theme: any = useTheme()
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  return (
    <Box>
      <Button
        id="btn_createAdd"
        title="Create Ads"
        className="animate greenButton"
        variant="contained"
        onClick={(props) => {
          handleClickOpen()
          setStep(2)
        }}
        sx={{
          padding: '10px 24px',
          fontSize: '18px',
          background: theme.palette.secondary.green,
          '&:hover': { background: '#00e39cc2' }
        }}
      >
        {props.title}
        <ArrowCircleRightIcon sx={{ ml: '10px' }} />
      </Button>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
