// action - state management
import * as actionTypes from '../../actionTypes'
import { updateObject } from '../../shared/utility'

export const initialState = {
  campaignChartCounts: {},
  isUpdated: null,
  isUpdateGam: null
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const campaignCreatedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_CREATED:
      return updateObject(state, {
        loading: false,
        campaignChartCounts: action.data
      })
    case actionTypes.CAMPAIGN_UPDATE:
      return updateObject(state, {
        loading: false,
        isUpdated: action.payload
      })
    case actionTypes.CAMPAIGN_GAM_STATUS:
      return updateObject(state, {
        loading: false,
        isUpdateGam: action.payload
      })
    default:
      return state
  }
}

export default campaignCreatedReducer
