import { Paper, useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { useEffect } from 'react'
import { HiMenuAlt3 } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { SET_MENU } from '../../store/actions'
import { drawerWidth } from '../../store/constant'
import LogoSection from './LogoSection'
import Sidebar from './Sidebar'
export interface Props {
  theme: any
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }: Props) => ({
  ...theme.typography.mainContent,
  ...{
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 3,
    padding: 3,

    flexGrow: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  }
}))

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props: any) => {
  const theme: any = useTheme()
  const dispatch = useDispatch()
  const leftDrawerOpened = useSelector((state: any) => state.customization.opened)

  const matchUpXs = useMediaQuery(theme.breakpoints.up('xs'))
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'))
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    if (matchUpSm === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    } else if (matchUpMd === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: leftDrawerOpened })
    } else if (matchUpLg === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: leftDrawerOpened })
    }
  }, [matchUpXs, matchUpSm, matchUpMd, matchUpLg])

  const handleDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  return (
    <Box sx={{ display: 'flex', margin: '0' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ background: '#fff', width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}
      >
        <Toolbar sx={{ display: { sm: 'none' }, background: '#fff', p: '10px', justifyContent: 'space-between' }}>
          <LogoSection />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              borderRadius: '0px',
              color: theme.palette.secondary.dark,
              width: '45px',
              height: '45px',
              mr: 0,
              display: { sm: 'none' }
            }}
          >
            <HiMenuAlt3 />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        elevation={2}
        sx={{
          position: 'fixed',
          height: '100%',
          display: { xs: 'none', sm: 'block' },
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          borderRadius: '1px !important'
        }}
      >
        <Box sx={{ textAlign: 'center', p: 3 }}>
          <LogoSection />
        </Box>
        <Sidebar />
      </Paper>

      <Main theme={theme}>
        <Outlet />
      </Main>

      {leftDrawerOpened && (
        <Drawer
          open={leftDrawerOpened}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <LogoSection />
          </Box>
          <div>
            <Sidebar />
          </div>
        </Drawer>
      )}
    </Box>
  )
}

export default MainLayout
