import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Box } from '@mui/system'

export default function CustomizedBreadcrumbs({ breadcrumbs }: any) {
  return (
    <Box role="presentation" sx={{ mt: { xs: '28%', sm: '10px' } }}>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{
          display: { xs: 'none', md: 'block' },
          padding: { xs: 0, sm: '10px 0' },
          fontSize: '14px'
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  )
}
