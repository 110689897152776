import Cookies from 'js-cookie'
import { UserType } from '../../util/constants'
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'
import { getEncryptedCookie } from '../../services/encryption.ts'

interface actionType {
  type: string
  payload: any
}

// setting initial state after checking local storage
const getInitialState = () => {
  const userToken = Cookies.get('dot-ad-token')
  const userType = getEncryptedCookie('ad-settings')

  if (userToken && userType) {
    return {
      idToken: userToken,
      isLoggedIn: true,
      loading: false,
      userType: userType,
      is_new_user: false,
      onboarding_flow_variant: '',
      error: null
    }
  } else {
    Cookies.remove('dot-ad-token')
    Cookies.remove('ad-settings')
    return {
      idToken: null,
      isLoggedIn: false,
      loading: false,
      userType: UserType.CUSTOMER,
      is_new_user: false,
      onboarding_flow_variant: '',
      error: null
    }
  }
}

const initialState = getInitialState()

const reducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER_SUCCESS:
      return updateObject(state, {
        user: action.payload?.addUser?._id,
        idToken: action.payload?.addUser?._token,
        userType: action.payload?.addUser?.user_type,
        is_new_user: action.payload?.addUser?.is_new_user,
        onboarding_flow_variant: action.payload?.addUser?.onboarding_flow_variant,
        isLoggedIn: true,
        loading: false,
        error: null
      })

    case actionTypes.LOGIN_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        isLoggedIn: false,
        error: action.payload
      })

    case actionTypes.SIGN_UP_USER_SUCCESS:
      return updateObject(state, {
        user: null,
        isLoggedIn: false,
        loading: false,
        error: null
      })

    case actionTypes.SETTING_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        isLoggedIn: false,
        error: action.payload
      })

    case actionTypes.LOGOUT_USER_SUCCESS:
      return updateObject(state, {
        idToken: null,
        isLoggedIn: false,
        loading: false,
        userType: null,
        error: null,
        user: null
      })

    case actionTypes.RESET_PASSWORD_SENT_FAILURE:
      return updateObject(state, {
        loading: false,
        error: action.payload
      })

    default:
      return state
  }
}

export default reducer
