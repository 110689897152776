import DoneIcon from '@mui/icons-material/Done'
import { Box, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { StatusCustomChip } from '../../../../layout/ui-components/CustomChip'
import CustomChip from '../../../../layout/ui-components/CustomChip'

interface INameSection {
  name: any
  status: any
  request_type?: any
}

const NameSection = ({ name, status, request_type }: INameSection) => {
  const theme: any = useTheme()

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        <Grid item sx={NameRowStyle.itemName} md={4}>
          <DoneIcon color="success" sx={{ mr: 2 }} />
          Campaign Name
        </Grid>
        <Grid item sx={NameRowStyle.item}>
          <Box sx={{ fontWeight: '700', fontSize: '20px', color: theme.palette?.secondary?.green }}>{name}</Box>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        <Grid item sx={NameRowStyle.itemName} md={4} xs={true}>
          <DoneIcon color="success" sx={{ mr: 2 }} />
          Campaign Status
        </Grid>
        <Grid item sx={NameRowStyle.item}>
          <StatusCustomChip name={status} />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
      {request_type && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
        >
          <Grid item sx={NameRowStyle.itemName} md={4} xs={true}>
            <DoneIcon color="success" sx={{ mr: 2 }} />
            Request Type
          </Grid>
          <Grid item sx={NameRowStyle.item}>
            <CustomChip name={request_type} type={'status'} variant={'filled'} />
          </Grid>
        </Grid>
      )}
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default NameSection

const NameRowStyle = {
  item: {
    pt: { xs: '30px', md: '0' },
    pb: { xs: '30px', md: '0' },
    width: '100%',
    '.MuiChip-root': { fontSize: '16px' }
  },
  itemName: { fontSize: '16px', display: 'flex', fontWeight: 'bold' }
}
