import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail } from '../customization'

export const getAdminDashboardCount = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryAdminDashboardCounts,
        fetchPolicy: 'no-cache'
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.ADMIN_DASHBOARD,
          data: response?.data.adminDashboardCounts
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}

export const getRecentDeposit = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryTransactionList,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.GETTING_RECENT_DEPOSIT_SUCCESS,
          payload: response?.data?.transactionList?.transactions
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.interestingFail, error.message))
      })
  }
}

export const getRecentCampaign = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCampaignList,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.GETTING_RECENT_CAMPAIGN_SUCCESS,
          payload: value?.data?.campaignList
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message))
      })
  }
}
