import { Paper } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCreativeListByCampaign } from '../../../../store/actions/Creative'
import AdvanceSection from './AdvanceSection'
import BasicSection from './BasicSection'
import BiddingSection from './BiddingSection'
import NameSection from './NameSection'
import UtmUrlSection from './UtmUrlSection'

const PreviousCampaignDetailsInfo = ({ campaignDetails }: any) => {
  const dispatch = useDispatch()
  const client = useSelector((state: any) => state.apolloClient.client)

  useEffect(() => {
    let req = {
      page: 1,
      limit: 100,
      campaignId: campaignDetails?._id
    }
    dispatch(getCreativeListByCampaign(client, req) as any)
  }, [campaignDetails])

  return (
    <Paper elevation={2} sx={{ m: 2, p: 2 }}>
      <NameSection
        name={campaignDetails?.previous_campaign_values?.name}
        status={campaignDetails?.previous_campaign_values?.status}
        request_type={campaignDetails?.previous_campaign_values?.request_type}
      />
      <BasicSection
        countries={campaignDetails?.previous_campaign_values?.countries}
        objectives={campaignDetails?.previous_campaign_values?.objectives}
        interest_categories={campaignDetails?.previous_campaign_values?.interest_categories}
      />
      <AdvanceSection
        device_targeting={campaignDetails?.previous_campaign_values?.device_targeting}
        number_of_impression={campaignDetails?.previous_campaign_values?.number_of_impression}
        time_frame={campaignDetails?.previous_campaign_values?.time_frame}
      />
      <BiddingSection
        balance={campaignDetails?.previous_campaign_values?.balance || 0}
        daily_budget={campaignDetails?.previous_campaign_values?.daily_budget || 0}
        cpm_bid={campaignDetails?.previous_campaign_values?.cpm_bid || 0}
      />
      <UtmUrlSection campaignDetails={campaignDetails} />
    </Paper>
  )
}

export default PreviousCampaignDetailsInfo
