// action - customization reducer
export const SET_MENU = '@customization/SET_MENU'
export const MENU_TOGGLE = '@customization/MENU_TOGGLE'
export const MENU_OPEN = '@customization/MENU_OPEN'

export { deleteUser, getUser } from './account'
export { getCampaignCreated } from './admin/campaignCreated'
export { getAdminDashboardCount, getRecentCampaign, getRecentDeposit } from './admin/dashboard'
export { addNewUser, forgotPassword, getAuthToken, login, logoutUser, setAuthToken, signUpWithEmail } from './auth'
export {
  AddNewCampaign,
  getCopyCampaign,
  SelectRegion,
  updateBalance,
  updateCampaign,
  updateCpmBid,
  updateCreativeGallery,
  updateDailyBudget,
  updateDestinationUrl,
  updateDevice,
  updateFinalDestinationUrl,
  updateGeo,
  updateImpression,
  updateInterest,
  updateMedium,
  updateName,
  updateObjective,
  updatePlacement,
  updateSource,
  updateTimeFrame
} from './campaign/add'
export { getCampaignAnalyticsData } from './campaign/analytics'
export { EditCampaign, ResetAddCampaignData, setAllNewCampData, DraftCampaign } from './campaign/edit'
export {
  getCampaignList,
  ResetCampaignStatusFilter,
  updateCampaignStatusFilter,
  updateStatusCampaign
} from './campaign/list'
export { getCountryList, getInterestList, getObjectiveList, getSearchResult } from './common'
export { addCreative, deleteCreative, editCreative, getCreativeList, getSizeList } from './Creative'
export { loadingStart, loadingStop, notificationFail, notificationSuccess, notificationWarning } from './customization'
export { editUser, settingUser } from './setting'
export { addTransaction, getAddPayment, getUserTransaction } from './strip'
export { getRole, getUserList, UserStatusChanges } from './user'
export { getUserDashboardAverageCount, getUserDashboardCount } from './user/dashboard'
