import * as icons from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { Box, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'

const BasicSection = ({
  countries,
  objectives,
  interest_categories,
  isCountriesChanged,
  isObjectivesChanged,
  isInterestCategoriesChanged
}: any) => {
  const theme: any = useTheme()
  const objectiveList = useSelector((state: any) => state.campaignReducer.objectiveList) || []

  const getTitle = (name: string) => {
    return (
      <Grid item sx={{ fontSize: '16px', display: 'flex', fontWeight: 'bold' }} md={4}>
        <DoneIcon color="success" sx={{ mr: 2 }} />
        <Box sx={isCountriesChanged || isObjectivesChanged || isInterestCategoriesChanged ? Style.chip : Style.noChip}>
          {name}
        </Box>
      </Grid>
    )
  }

  const labelCss = {
    marginBottom: 1,
    color: theme.palette?.grey[500]
  }

  const renderObjectives = (id: any) => {
    const finalResult = objectiveList?.find((elementId: any) => elementId.objective_title === id)
    const Icon = icons[finalResult?.objective_icon as keyof typeof icons]
    return (
      <Box key={id} sx={{ mr: 2, display: 'flex', alignItems: 'center', color: theme.palette?.primary.main }}>
        <Icon style={{ marginRight: '10px' }} />
        {id}
      </Box>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ flexWrap: { xs: 'wrap', md: 'initial' } }}
      >
        {getTitle('Basic Targeting')}
        <Grid item sx={{ pt: { xs: '30px', md: '0' }, width: '100%' }}>
          <Box>
            <Box>
              <Box
                sx={
                  isCountriesChanged
                    ? { ...labelCss, color: '#C10202' }
                    : { ...labelCss, color: theme.palette?.grey[500] }
                }
              >
                Location
              </Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {countries.map((country: string, index: number) => (
                  <Box
                    key={index}
                    sx={{ mr: 2, display: 'flex', alignItems: 'center', marginBottom: { xs: '10px', md: '0' } }}
                  >
                    <FlagCircleIcon sx={{ mr: 1 }} /> {country}
                  </Box>
                ))}
              </Grid>
            </Box>
            <Box sx={{ mt: 3, mb: 3 }}>
              <Box
                sx={
                  isObjectivesChanged
                    ? { ...labelCss, color: '#C10202' }
                    : { ...labelCss, color: theme.palette?.grey[500] }
                }
              >
                Objective
              </Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {objectives.map((objective: string, index: number) => renderObjectives(objective))}
              </Grid>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Box
                sx={
                  isInterestCategoriesChanged
                    ? { ...labelCss, color: '#C10202' }
                    : { ...labelCss, color: theme.palette?.grey[500] }
                }
              >
                Interest Category
              </Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {interest_categories.map((interest: string, index: number) => (
                  <Box
                    key={index}
                    sx={{ mr: 2, display: 'flex', alignItems: 'center', color: theme.palette?.primary.main }}
                  >
                    <GroupAddIcon sx={{ mr: 1 }} color="primary" />
                    {interest}
                  </Box>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 1 }} />
    </>
  )
}

export default BasicSection

const Style = {
  chip: {
    // display: 'inline-block',
    borderRadius: '999px',
    padding: '0.5em',

    backgroundColor: '#EDA6A6'
  },
  noChip: {}
}
