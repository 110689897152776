export const redirectPage = (redirectLink: string) => {
    window.location.href = redirectLink
}
export const redirectToNewPage = (redirectLink: string) => {
    window.open(redirectLink, '_blank');
}

export const platformUrl = (onboardingFlowVariant: 'a' | 'b'): string => {
    // Define a type for the base URLs
    type BaseUrls = {
        [key: string]: string;
    };

    // Base URLs depending on the environment
    const baseUrl: BaseUrls = {
        development: 'https://dot-advertiser-dashboard-uhztu.ondigitalocean.app/',
        production: 'https://ads.dotaudiences.com/',
        local: 'http://localhost:3000/'
    };

    // Determine the environment, falling back to 'local' if REACT_APP_NODE_ENV is undefined
    const env: string = process.env.REACT_APP_NODE_ENV || 'local';

    // Construct the full URL based on the onboarding flow variant
    const url: string = baseUrl[env] + (onboardingFlowVariant === 'a' ? '' : 'add-campaigns');

    return url;
}
