import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Logo = process.env.PUBLIC_URL + '/images/logoNew.svg'

const LogoSection = () => {
  const navigate = useNavigate()
  return (
    <Box onClick={() => navigate('/')} sx={{ maxHeight: '44px', paddingLeft: '1rem' }}>
      <img src={Logo} aria-label="For Logo" alt="not displayFor logoNew" width="100%" height="40px" />
    </Box>
  )
}

export default LogoSection
