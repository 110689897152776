import { Box, Button, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import AuthModal from '../../../../../views/modals/AuthDialog'
const BreakfreeImage = process.env.PUBLIC_URL + '/images/BreakfreeImage.webp'
const FlexibleImage = process.env.PUBLIC_URL + '/images/FlexibleImage.webp'
const ReachImage = process.env.PUBLIC_URL + '/images/ReachImage.png'

export default function RelevantAudiences() {
  const theme: any = useTheme()

  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  const reveal = () => {
    var reveals = document.querySelectorAll('.reveal')

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight
      var elementTop = reveals[i].getBoundingClientRect().top
      var elementVisible = 75
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active')
      } else {
        reveals[i].classList.remove('active')
      }
    }
  }

  window.addEventListener('scroll', reveal)

  return (
    <Box sx={{ background: '#fff' }}>
      <Box
        sx={{
          padding: { xs: '40px 0px', md: '40px 0px' }
        }}
        className="container flexSpaceCenter"
      >
        <Grid container sx={{ alignItems: 'center', textAlign: { xs: 'center', md: 'left' } }}>
          <Grid className="reveal fade-bottom" item xs={12} md={6} sx={{ p: { xs: '16px 27px', md: '16px 27px' } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '32px', md: '48px' },
                maxWidth: '440px',
                width: '100%',
                m: { xs: '0 auto', md: '0' }
              }}
            >
              Relevant audiences at your fingertips
            </Typography>
          </Grid>
          <Grid className="reveal fade-bottom" item xs={12} md={6} sx={{ p: { xs: '16px 24px', md: '16px 27px' } }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: '16px', md: '18px' },
                maxWidth: '591px',
                width: '100%',
                m: { xs: '0 auto', md: '0' }
              }}
            >
              We understand the growth ambitions of Web3 companies. Whether you focus on gaming, NFT's, blockchain
              solutions, financial services, crypto, or something else, we have interested audiences ready and waiting
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', md: '40px 0px' },
          textAlign: { xs: 'center', md: 'left' },
          order: { xs: '1', md: 'unset' }
        }}
        className="container flexSpaceCenter reveal fade-bottom"
      >
        <Grid container sx={{ alignItems: 'center', order: { xs: '1', md: 'unset' } }}>
          <Grid
            className="reveal fade-bottom"
            item
            xs={12}
            md={6}
            sx={{ p: { xs: '16px 0px 16px 12px', md: '16px 27px' }, order: { xs: '1', md: 'unset' } }}
          >
            <img
              src={ReachImage}
              aria-label="For ReachImage"
              width="100%"
              alt="not displayReachImage"
              height="100%"
            />
          </Grid>
          <Grid
            className="reveal fade-bottom"
            item
            xs={12}
            md={6}
            sx={{ p: '10px 27px', maxWidth: '496.92px', margin: '0 auto' }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '20px', md: '28px' },
                color: theme.palette.secondary.green,
                mb: { xs: '18px', md: '30px' },
                textTransform: 'uppercase'
              }}
            >
              Reach
            </Typography>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '20px', md: '32px' }, pb: 2, lineHeight: { xs: '28px', md: '37px' } }}
            >
              Reach 175+ Million interested readers across Web3, Crypto, Finance, Gaming, & Tech
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                color: '#5A5B61',
                mb: { xs: '18px', md: '30px' },
                lineHeight: '24px'
              }}
            >
              Powerful contextual and geo targeting at scale. Turn clicks into customers
            </Typography>
            <Box className="animate-button">
              <Button
                id="btnCampaign"
                variant="contained"
                size="large"
                className="animation-button"
                onClick={(props) => {
                  handleClickOpen()
                  setStep(2)
                }}
              >
                Try Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', md: '40px 0px' },
          textAlign: { xs: 'center', md: 'left' }
        }}
        className="container flexSpaceCenter reveal fade-bottom"
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid
            className="reveal fade-bottom"
            item
            xs={12}
            md={6}
            sx={{ p: '16px 27px', maxWidth: '496.92px', margin: '0 auto' }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '20px', md: '28px' },
                color: theme.palette.secondary.green,
                mb: { xs: '18px', md: '30px' },
                textTransform: 'uppercase'
              }}
            >
              Break free
            </Typography>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '20px', md: '32px' }, pb: 2, lineHeight: { xs: '28px', md: '37px' } }}
            >
              Bypass Google & Facebook Ad Restrictions
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                color: '#5A5B61',
                mb: { xs: '18px', md: '30px' },
                lineHeight: '24px'
              }}
            >
              Break free from limitation and take back control over your growth and advertising strategy. DOT offers a
              new perspective.
            </Typography>
            <Box className="animate-button">
              <Button
                id="btnRestrictions"
                variant="contained"
                size="large"
                className="animation-button"
                onClick={(props) => {
                  handleClickOpen()
                  setStep(2)
                }}
              >
                Try Now
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ p: '16px 27px' }}>
            <img
              aria-label="For BreakfreeImage"
              alt=" not displayBreakfreeImage"
              className="reveal fade-bottom"
              src={BreakfreeImage}
              width="100%"
              height={'auto'}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', md: '40px 0px' },
          textAlign: { xs: 'center', md: 'left' }
        }}
        className="container flexSpaceCenter reveal fade-bottom"
      >
        <Grid container sx={{ alignItems: 'center', order: { xs: '1', md: 'unset' } }}>
          <Grid
            className="reveal fade-bottom"
            item
            xs={12}
            md={6}
            sx={{ p: '16px 27px', order: { xs: '1', md: 'unset' } }}
          >
            <img
              aria-label="For FlexibleImage"
              alt="not displayFlexibleImage"
              src={FlexibleImage}
              width="100%"
              height="100%"
            />
          </Grid>
          <Grid
            className="reveal fade-bottom"
            item
            xs={12}
            md={6}
            sx={{ p: '16px 27px', maxWidth: '496.92px', margin: '0 auto' }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '20px', md: '28px' },
                color: theme.palette.secondary.green,
                mb: { xs: '18px', md: '30px' },
                textTransform: 'uppercase'
              }}
            >
              Flexible
            </Typography>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '20px', md: '32px' }, pb: 2, lineHeight: { xs: '28px', md: '37px' } }}
            >
              Flexible Spend & Realtime Analytics.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                color: '#5A5B61',
                mb: { xs: '18px', md: '30px' },
                lineHeight: '24px'
              }}
            >
              Start and stop campaigns at any time, and master performance with complete visibility across key metrics
              and KPI's.
            </Typography>
            <Box className="animate-button">
              <Button
                id="btnAccount"
                variant="contained"
                size="large"
                className="animation-button"
                onClick={(props) => {
                  handleClickOpen()
                  setStep(2)
                }}
              >
                Try Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
