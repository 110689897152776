export const steps = [
    {
        title: 'Connect Telegram',
        description: 'Free Credit',
        completed: false
    },
    {
        title: 'Learn the basics',
        description: 'Ready in no time',
        completed: false
    },
    {
        title: 'All Done!',
        description: 'Onwards to growth!',
        completed: false
    }
]