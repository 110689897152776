import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import blueTelegramLogo from '../../../assets/blueTelegramLogo.webp'
import { useSelector } from 'react-redux'
import { redirectToNewPage } from '../../../util/redirect'
import { SOCIALS } from '../../../util/constants'
const Step1 = () => {
    const userDetails = useSelector((state: any) => state.settingReducer.userDetails)
    const sendPulseUrl = `${SOCIALS.TELEGRAM}|email=${userDetails?.email}`
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${sendPulseUrl}&amp;size=50x50`

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={Style.mainBox}>
            <Box sx={Style.headingBox}>
                <Typography sx={Style.step}>step 1</Typography>
                <Typography sx={Style.title}>Get Personalised Support (Optional)</Typography>
                <Typography sx={Style.description}>+ Receive Free Credit</Typography>
            </Box>

            <Typography
                sx={Style.telegramHeading}
                onClick={() => {
                    redirectToNewPage(sendPulseUrl)
                }}
            >
                <img src={blueTelegramLogo} alt="telegramLogo" />
                Connect telegram
            </Typography>
            {!isXs && <>
                <Box sx={Style.telegramDescriptionBox}>
                    <Box sx={Style.line}></Box>
                    <Typography sx={Style.telegramDescription}>Or, scan QR code below from your phone</Typography>
                    <Box sx={Style.line}></Box>
                </Box>
                <Box sx={Style.qrBox}>
                    <img src={qrCodeUrl} alt="QRCode" height="100%" width="100%" />
                </Box>
            </>}
        </Box>
    )
}
const Style = {
    mainBox: {
        height: "100%",
        display: "flex",
        padding: "32px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
    },
    headingBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch"
    },
    step: {
        color: "var(--text-icons-light-base-second, #858C95)",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0.12px",
        textTransform: "uppercase"
    },
    title: {
        color: "var(--text-icons-light-base-main, #323539)",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "-0.2px"
    },
    description: {
        color: "var(--text-icons-light-base-second, #858C95)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.16px"
    },
    TelegramBox: {
        display: 'flex',
        padding: '36px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        alignSelf: 'stretch',
        borderRadius: '18px',
        border: '1.8px solid var(--stroke-light-base, #E5E5E7)',
        background: 'var(--background-light-base-second, #F8F9FB)',
        boxShadow: '0px 0.9px 1.8px 0px rgba(16, 24, 40, 0.04)'
    },
    telegramHeading: {
        display: 'flex',
        padding: 'var(--main-system-12-px, 12px) var(--main-system-16-px, 16px)',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 'var(--main-system-8-px, 8px)',
        alignSelf: 'stretch',
        borderRadius: 'var(--roundness-round-inside, 6px)',
        border: '1px solid var(--stroke-light-base, #E5E5E7)',
        background: 'var(--background-light-base-main, #FFF)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
        color: 'var(--text-icons-light-base-main, #323539)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '22px',
        cursor: 'pointer'
    },
    telegramDescriptionBox: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: { xs: 0, lg: '12px' },
        alignSelf: 'stretch',
        textAlign: 'center'
    },
    line: {
        background: 'var(--neutral-600, #EAEBF0)',
        height: '1px',
        width: '15%',
        display: { xs: 'none', lg: 'flex' }
    },
    telegramDescription: {
        color: 'var(--gray-700, var(--gray-700, #323539))',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.16px'
    },
    qrBox: {
        margin: "auto",
        height: "200px",
        width: "200px",
        '@media (max-height: 700px)': {
            height: '125px',
            width: '125px',
        },
    }
}
export default Step1
