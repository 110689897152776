import { gql } from '@apollo/client'

const queryLoginCustom = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      _id
      full_name
      parent_id
      email
      user_type
      profile_image
      spent_amount
      available_balance
      status
      stripe_customer_id
      fuid
      commission
      _token
      created_at
      updated_at
    }
  }
`

const mutationAddUser = gql`
  mutation addUser(
    $fuid: String!
    $stripe_customer_id: String!
    $status: String!
    $commission: Float!
    $available_balance: Float!
    $preferred_messenger: String!
    $phone: String!
    $dial_code: String!
    $spent_amount: Float!
    $deposit_amount: Float!
    $profile_image: String!
    $user_type: String!
    $email: String!
    $parent_id: String!
    $full_name: String!
    $messenger: String
  ) {
    addUser(
      fuid: $fuid
      stripe_customer_id: $stripe_customer_id
      status: $status
      commission: $commission
      available_balance: $available_balance
      preferred_messenger: $preferred_messenger
      phone: $phone
      dial_code: $dial_code
      spent_amount: $spent_amount
      deposit_amount: $deposit_amount
      profile_image: $profile_image
      user_type: $user_type
      email: $email
      parent_id: $parent_id
      full_name: $full_name
      messenger: $messenger
    ) {
      _id
      full_name
      parent_id
      email
      user_type
      profile_image
      preferred_messenger
      messenger
      spent_amount
      available_balance
      status
      stripe_customer_id
      fuid
      commission
      is_new_user
      onboarding_flow_variant
      _token
      created_at
      updated_at
    }
  }
`

export { queryLoginCustom, mutationAddUser }
