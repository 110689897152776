import { gql } from '@apollo/client'

const queryAdminDashboardCounts = gql`
  query adminDashboardCounts {
    adminDashboardCounts {
      balance
      balance_percentage
      total_campaign
      total_campaign_percentage
      credit_added
      credit_added_percentage
      total_accounts
      total_accounts_percentage
      total_campaign_balance,
      percentage_campaign_balance_growth
    }
  }
`
const queryCampaignCreatedCounts = gql`
  query campaignCreated {
    campaignCreated {
      name
      data
    }
  }
`
export { queryAdminDashboardCounts, queryCampaignCreatedCounts }
