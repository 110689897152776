import NorthEastIcon from '@mui/icons-material/NorthEast'
import PublicIcon from '@mui/icons-material/Public'
import { Link } from '@mui/material'
import { useSelector } from 'react-redux'
import { UserType } from '../../util/constants'

export const LinkWithIcon = ({ name, URL, campaignDetails }: any) => {
  const LinkCss = {
    // color: '#6941C6',
    mr: 1,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  }
  const platFormUser = useSelector((state: any) => state.authReducer)

  const textColor = () => {
    if (platFormUser?.userType === UserType.CUSTOMER) {
      return 'inherit'
    }

    if (campaignDetails?.previous_campaign_values === null || campaignDetails?.previous_campaign_values === undefined) {
      return 'inherit'
    }

    if (
      JSON.stringify(campaignDetails?.utm_url) === JSON.stringify(campaignDetails?.previous_campaign_values?.utm_url)
    ) {
      return 'inherit'
    }
    return '#C10202'
  }

  return (
    <Link underline="hover" color={textColor()} href={URL} sx={LinkCss} target="_blank">
      <PublicIcon sx={{ m: 1 }} />
      {name} <NorthEastIcon sx={{ m: 1 }} />
    </Link>
  )
}
