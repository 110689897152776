import { VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CampaignName from '../../layout/ui-components/CampaignName'
import CustomChip, { StatusCustomChip } from '../../layout/ui-components/CustomChip'
import EmptyRow from '../../layout/ui-components/EmptyRow'
import { getResetSearch } from '../../store/actions/common'
import { CampaignHeadCell } from './CreativeTableHead'
import SearchTableHead from './SearchTableHead'

const CampaignsList = () => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const searchData = useSelector((state: any) => state.commonReducer.searchData)

  const buttonClickHandler = (id: any) => {
    let req = {
      resetSearch: true
    }
    dispatch(getResetSearch(req))
    navigate('/detail-campaign/' + id)
  }

  return (
    <Box>
      <h2 style={{ marginTop: '20px' }}>Campaigns</h2>
      <Box
        sx={{
          border: '1px solid #EAECF0',
          borderRadius: '8px',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
          marginTop: 2
        }}
      >
        <Paper sx={{ width: '100%', elevation: 3 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <SearchTableHead headCells={CampaignHeadCell} />
              <TableBody>
                {searchData?.campaigns?.length > 0 ? (
                  searchData?.campaigns?.map((row: any, index: any) => {
                    return (
                      <TableRow
                        data-id="row"
                        hover
                        key={index}
                        sx={{
                          '.MuiTableCell-root ': {
                            p: 1
                          }
                        }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          <Box
                            onClick={(e) => {
                              e.stopPropagation()
                              buttonClickHandler(row?._id)
                            }}
                          >
                            <CampaignName name={row?.name} url={row?.utm_url} />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <StatusCustomChip name={row?.status} />
                        </TableCell>
                        <TableCell align="left">
                          {row?.objectives?.map((locate: any, i: any) => (
                            <Box key={i}>
                              <CustomChip name={locate} type={'objectives'} variant={'filled'} />
                            </Box>
                          ))}
                        </TableCell>
                        <TableCell align="center">${row?.daily_budget?.toFixed(2) || 0}</TableCell>
                        <TableCell align="center">${row?.budget_spend?.toFixed(2) || 0}</TableCell>
                        <TableCell align="center">${row?.balance?.toFixed(2) || 0}</TableCell>
                        <TableCell align="center">${row?.total_cpc?.toFixed(2) || 0}</TableCell>
                        <TableCell align="center">{row?.total_ctr?.toFixed(2) || 0}%</TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              id="view"
                              sx={{ minWidth: 'auto' }}
                              variant="text"
                              title="View Campaign"
                              onClick={(e) => {
                                e.stopPropagation()
                                buttonClickHandler(row?._id)
                              }}
                            >
                              <VisibilityOutlined color="primary" />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <EmptyRow totalColumn={CampaignHeadCell.length + 1} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default CampaignsList
