import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import { GETTING_USERS_FAILURE, GETTING_USERS_SUCCESS, SET_ROLES } from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from '../customization'

export const getUserList = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryUserList,
        fetchPolicy: 'cache-first',
        variables: req
      })
      .then((response: any) => {
        dispatch(loadingStop())
        dispatch({
          type: GETTING_USERS_SUCCESS,
          data: response?.data?.userList
        })
      })
      .catch((error: any) => {
        dispatch({
          type: GETTING_USERS_FAILURE
        })
        dispatch(loadingStop())
        dispatch(notificationFail(error, error.message))
      })
  }
}
export const UserStatusChanges = (client: any, req: any, listRequest: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.queryUpdate,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch(getUserList(client, listRequest))
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.userStatusChange))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.userStatusChangeFail, error.message))
      })
  }
}

export const getRole = (req: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_ROLES,
      data: req
    })
  }
}
