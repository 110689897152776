import { gql } from '@apollo/client'

const queryUserDetails = gql`
  query userDetails($user_id: String) {
    userDetails(user_id: $user_id) {
      _id
      full_name
      parent_id
      email
      user_type
      profile_image
      spent_amount
      available_balance
      status
      stripe_customer_id
      fuid
      commission
      _token
      created_at
      updated_at
      phone
      messenger
      preferred_messenger
      is_subscription_active
      payment_method
      subscription_amount
      is_new_user
      onboarding_flow_variant
    }
  }
`
const queryUserDetailsPreviousVersion = gql`
  query userDetails($user_id: String) {
    userDetails(user_id: $user_id) {
      _id
      full_name
      parent_id
      email
      user_type
      profile_image
      spent_amount
      available_balance
      status
      stripe_customer_id
      fuid
      commission
      _token
      created_at
      updated_at
      phone
      messenger
      preferred_messenger
      is_subscription_active
      payment_method
      subscription_amount
    }
  }
`

const queryUpdate = gql`
  mutation editProfile(
    $_id: String!
    $stripe_customer_id: String
    $status: String
    $available_balance: Float
    $commission: Float
    $spent_amount: Float
    $deposit_amount: Float
    $preferred_messenger: String
    $phone: String
    $profile_image: String
    $user_type: String
    $full_name: String
    $messenger: String
    $is_new_user: Boolean
  ) {
    editProfile(
      _id: $_id
      stripe_customer_id: $stripe_customer_id
      status: $status
      available_balance: $available_balance
      commission: $commission
      spent_amount: $spent_amount
      deposit_amount: $deposit_amount
      preferred_messenger: $preferred_messenger
      phone: $phone
      profile_image: $profile_image
      user_type: $user_type
      full_name: $full_name
      messenger: $messenger
      is_new_user: $is_new_user
    ) {
      _id
      full_name
      parent_id
      email
      user_type
      messenger
      preferred_messenger
      phone
      profile_image
      deposit_amount
      spent_amount
      available_balance
      status
      stripe_customer_id
      is_new_user
      fuid
      _token
      created_at
      updated_at
    }
  }
`

const mutationAddTransaction = gql`
  mutation addTransaction($user_id: String!, $amount: Float, $type: String!, $checkout_session_id: String) {
    addTransaction(user_id: $user_id, amount: $amount, type: $type, checkout_session_id: $checkout_session_id) {
      _id
      full_name
      parent_id
      email
    }
  }
`

export { queryUserDetails, queryUpdate, mutationAddTransaction, queryUserDetailsPreviousVersion }
