import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logoutUser } from '../store/actions'
import OnboardingFlowVariant1 from '../layout/ui-components/onboardingFlow-1'
import OnboardingFlowVariant2 from '../layout/ui-components/onboardingFlow-2'

export const OnBoardingRoutes = ({ variant }: { variant: string }) => {
    const dispatch: any = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!Cookies.get('dot-ad-token')) {
            navigate('/')
            dispatch(logoutUser())
        }
    }, [])

    const renderOnboardingFlow = (variant: string) => {
        switch (variant) {
            case 'a':
                return <OnboardingFlowVariant1 />;
            case 'b':
                return <OnboardingFlowVariant2 />;
            default:
                // Default to variant a if not specified
                return <OnboardingFlowVariant1 />;
        }
    };

    return renderOnboardingFlow(variant)
}
