// action - state management
import * as actionTypes from '../../actionTypes'
import { updateObject } from '../../shared/utility'

export const initialState = {
  dashboardCount: {},
  dashboardAverageCount: {}
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const dashboarCountdReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.USER_DASHBOARD:
      return updateObject(state, {
        loading: false,
        dashboardCount: action.data
      })
    case actionTypes.USER_DASHBOARD_AVERAGE:
      return updateObject(state, {
        loading: false,
        dashboardAverageCount: action.data
      })
    default:
      return state
  }
}

export default dashboarCountdReducer
