import { gql } from '@apollo/client'

const queryUserList = gql`
  query userList(
    $page: Float!
    $limit: Float!
    $columnName: String
    $order: String
    $search: String
    $isExport: Boolean
    $user_type: [String!]
    $balance: Boolean
  ) {
    userList(
      page: $page
      limit: $limit
      columnName: $columnName
      order: $order
      search: $search
      isExport: $isExport
      user_type: $user_type
      balance: $balance
    ) {
      users
      page
      limit
      total_records
    }
  }
`

const queryDeleteUser = gql`
  query userDelete($_id: String!) {
    userDelete(_id: $_id)
  }
`

const queryuserDashboardCounts = gql`
  query userDashboardCounts($user_id: String) {
    userDashboardCounts(user_id: $user_id) {
      _id
      spent_amount
      spent_amount_percentage
      available_balance
      available_balance_percentage
      total_campaign
      total_campaign_percentage
      live_campaign
      live_campaign_percentage
      pending_campaign
      pending_campaign_percentage
      paused_campaign
      paused_campaign_percentage
      active_campaign
      active_campaign_percentage
      total_clicks
      total_clicks_percentage
    }
  }
`

const queryuserDashboardAverageCounts = gql`
  query userDashboardAverageCounts {
    userDashboardAverageCounts {
      total_clicks
      total_clicks_percentage
      average_cpc
      average_cpc_percentage
      average_ctr
      average_ctr_percentage
      click_time
    }
  }
`

export { queryUserList, queryDeleteUser, queryuserDashboardCounts, queryuserDashboardAverageCounts }
