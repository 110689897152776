import { Box } from '@mui/material'
import CopyRight from './Copyright'
import TopFooter from './TopFooter'

export default function Footer() {
  return (
    <Box className="container">
      <TopFooter />
      <CopyRight />
    </Box>
  )
}
