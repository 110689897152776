import Dialog from '@mui/material/Dialog'
import PreviousCampaignDetailsInfo from '../campaigns/detail-campaign/previous-campaign-info'

export default function PreviousCampaignValuesModal(props: any) {
  const { open, handleClose, campaignDetails } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // maxWidth="xs"
    >
      <PreviousCampaignDetailsInfo campaignDetails={campaignDetails} />
    </Dialog>
  )
}
