export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE'
}

export enum NAVIGATION {
  HOME = '/',
  LOGIN = '/login',
  DETAIL_CAMPAIGN = '/detail-campaign',
  ACCOUNTS = '/accounts',
  USER_ACCOUNT = '/user_account',
  REQUESTS = '/requests',
  TRANSACTIONS = '/transactions'
}

export enum AdminsWhiteList {
  DOT_AUDIENCES = "@dotaudiences.com",
}

export const DOT_TUTORIAL_VIDEO_URL = 'https://youtu.be/p0oSkICEeOQ'

export enum MONTHLY_SUBSCRIPTION {
  AMOUNT = 1000
}

export enum UserType {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  CUSTOMER = 'customer'
}

export const getBucketName = (userId: string, type: string) => {
  return `/${userId}/${type}/`
}
export enum BucketType {
  CREATIVE = 'creative',
  PROFILE = 'profile'
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum UserCampaignAction {
  PAUSE = 'Pause',
  EDIT = 'Edit',
  RESUME = 'Resume',
  DELETE = 'Delete'
}

export enum PaymentType {
  RECURRING = 'recurring',
  ONE_TIME = 'one_time',
  CRYPTO = 'crypto'
}

export enum CampaignUserRequestType {
  PAUSE = 'Pause',
  RESUME = 'Resume',
  UPDATE = 'Update',
  ACTIVE = 'Active',
  COPY = 'Copy'
}

export enum CampaignAdminActions {
  VIEW = 'view',
  APPROVE_GAM = 'approveGAM',
  APPROVE_MANUAL = 'approveManual',
  REJECT = 'reject',
  MARK_AS_DONE = 'Markasdone',
  SYNC_STATUS = 'syncStatus',
  SYNC_ANALYTICS = 'syncAnalytics',
  PAUSE_CAMPAIGN = 'pauseCampaign',
  RESUME_CAMPAIGN = 'resumeCampaign'
}

export enum CampaignStatus {
  ACTIVE = 'Active',
  DRAFT = 'Draft',
  PENDING_APPROVAL = 'Pending Approval',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  PAUSED = 'Paused',
  DAILY_LIMIT = 'Daily Limit'
}

export enum CampaignChannel {
  GAM = 'gam',
  MANUAL = 'manual'
}

export enum SignUpDefaults {
  DEFAULT_USER_TYPE = 'customer',
  DEFAULT_USER_STATUS = 'active',
  DEFAULT_USER_STRIPE_CUSTOMER_ID = '',
  DEFAULT_USER_FUID = '',
  DEFAULT_USER_DEPOSIT_AMOUNT = 0,
  DEFAULT_USER_SPENT_AMOUNT = 0,
  DEFAULT_USER_AVAILABLE_BALANCE = 0,
  DEFAULT_USER_PROFILE_IMAGE = 'Default',
  DEFAULT_USER_PARENT_ID = '0',
  DEFAULT_USER_FULL_NAME = '',
  DEFAULT_USER_PHONE_NUMBER = '',
  DEFAULT_USER_DIAL_CODE = '+0',
  DEFAULT_USER_PREFERRED_MESSENGER = '',
  // DEFAULT_USER_COMMISSION = 30 // updated value
  DEFAULT_USER_COMMISSION = 50
}
export enum FireBaseError {
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  USER_NOT_FOUND = 'auth/user-not-found',
  TOO_MANY_ATTEMPT = 'auth/too-many-requests',
  INVALID_PASSWORD = 'auth/wrong-password',
  EMAIL_EXISTS = 'This Email is already in use, try a different Email',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  OPERATION_NOT_ALLOWED = 'OPERATION_NOT_ALLOWED',
  NO_USER_FOUND = 'This Email is not registered',
  WRONG_PASSWORD = 'Entered Password is wrong, Please try again',
  PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN = 'There was a problem with your login. Please try again',
  PROBLEM_WITH_SIGNUP_PLEASE_TRY_AGAIN = 'There was a problem with your signup. Please try again',
  FIREBASE_TOKEN_EXPIRED = 'Firebase token expired/invalid...!!',
  EMAIL_NOT_VERIFIED = 'Email is not verified, Please verify your email',
  PROBLEM_WITH_SENDING_EMAIL_VERIFICATION = 'There was a problem with sending email verification. Please try again'
}

export const DEFAULT_CREATIVE_TITLE_CHARACTER_LIMIT = 15

export const paymentMethodDefault = 'card'
export const cardLimitDefault = '10'
export const IMAGE_MAX_SIZE = 150000

export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_CURRENCY_SYMBOL = '$'
export const DEFAULT_CURRENCY_FORMAT = 'en-US'
export const DEFAULT_CURRENCY_DECIMAL = 2
export const DEFAULT_CURRENCY_THOUSANDS = ','
export const DEFAULT_CURRENCY_DECIMALS = '.'
export const DEFAULT_CURRENCY_SYMBOL_AFTER = false
export const DEFAULT_CURRENCY_SYMBOL_SPACE = false

export const DEFAULT_CURRENCY_FORMAT_CONFIG = {
  symbol: DEFAULT_CURRENCY_SYMBOL,
  format: DEFAULT_CURRENCY_FORMAT,
  decimal: DEFAULT_CURRENCY_DECIMAL,
  thousand: DEFAULT_CURRENCY_THOUSANDS,
  precision: DEFAULT_CURRENCY_DECIMALS,
  formatWithSymbol: DEFAULT_CURRENCY_SYMBOL_AFTER,
  spaceBetweenAmountAndSymbol: DEFAULT_CURRENCY_SYMBOL_SPACE
}

export const DEFAULT_CAMPAIGN_LIST_LIMIT = 5

export default function CurrencyFormat(amount: any) {
  let values = '0'
  try {
    let isInteger = Number.isInteger(amount)
    if (isInteger) {
      values = amount
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      values = Number.parseFloat(amount.toString())
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  } catch (error) { }
  return '$' + values
}
export const REG_TRANSFER_AMOUNT = /^\d{1,}(\.\d{0,2})?$/

export const DefaultParamForFetchingCreatives = {
  page: 1,
  limit: 9
}

export const DefaultParamForFetchingCampaigns = {
  page: 1,
  limit: 5
}

export const DefaultParamForFetchingCampaignsCustomer = {
  page: 1,
  limit: 10
}

export const Device = [
  {
    icon: 'FaDesktop',
    DeviceName: 'Desktop',
    isBi: true,
    id: 1,
    data: [
      { OSName: 'Windows', OSicon: 'FaWindows' },
      { OSName: 'Linux', OSicon: 'FaLinux' },
      { OSName: 'Mac OS', OSicon: 'FaLaptop' }
    ]
  },
  {
    icon: 'FaTabletAlt',
    DeviceName: 'Mobile & Tablet',
    isBi: false,
    id: 2,
    data: [
      { OSName: 'Android', OSicon: 'FaAndroid' },
      { OSName: 'iOS', OSicon: 'FaApple' },
      { OSName: 'Other', OSicon: 'FaApple' }
    ]
  }
]

export const stringAvatar = (name: string) => {
  return name?.substring(0, 1)
}
export const checkURL = (url: any) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

export enum campaignCustomerActions {
  Details = 'View Details',
  MarkAsDone = 'Mark as done',
  ApproveToGAM = 'Approve to GAM',
  ApproveToManual = 'Approve to Manual',
  Reject = 'Reject',
  syncStatus = 'Sync Status',
  syncAnalytics = 'Sync Analytics',
  pauseCampaign = 'Pause Campaign',
  resumeCampaign = 'Resume Campaign',
}

export enum MIN_AMOUNTS {
  cpmBid = 1.4,
  Daily = 20,
  // total = 100 //updated value
  total = 99
}

export enum BALANCE_PAGE_CONSTANTS {
  // MINIMUM_TOP_UP_AMOUNT = 250, updated minimum balance
  MINIMUM_TOP_UP_AMOUNT = 99,
  MAXIMUM_TOP_UP_AMOUNT = 999999
}

export const initialDate = {
  startDate: null || undefined,
  endDate: new Date(''),
  key: 'selection'
}
export const isValidUrl = (string: any) => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
export const getTotalctr = (clicks: any, impressions: any) => {
  let data = (clicks / impressions) * 100 || 0
  return data?.toFixed(2) || 0
}

export const SOCIALS = {
  LINKEDIN: `https://www.linkedin.com/company/dot-audiences/`,
  TELEGRAM: `https://tg.pulse.is/DotAds_Bot?start=649c5f7d8f96f095050b2f5a`,
  TWITTER: `https://twitter.com/dotaudiences`
}

export const DOT_REACH_OUT_MAIL = 'hello@dotaudiences.com'

export const TERMS_OF_SERVICE = `https://dotaudiences.com/advertiser-terms-of-service`

export const OFFERS_LINK = 'https://marketplace.dotaudiences.com/newswire/?utm_source=ad_platform'