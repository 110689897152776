import CloseIcon from '@mui/icons-material/Close'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { login } from '../../store/actions'
import { LoginAuthProvider } from '../../types/auth'
// import FacebookSocialButton from './authbuttons/FacebookSocialButton'
import GoogleLoginButton from './authbuttons/GoogleLoginButton'
import LoginForm from './LoginForm'

export default function Login(props: any) {
  const { handleClose, step, setStepValue, userEmail } = props
  const dispatch = useDispatch()

  const handleLoginOrSignUp = async (provider: LoginAuthProvider, args?: { email: string; password: string }) => {
    dispatch(login(provider, args) as any)
  }

  const theme = useTheme()

  return (
    <Box>
      <Box
        className="modal-header"
        sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '20px' }}
      >
        <Box>
          <Typography variant="h3" sx={{ fontSize: '24px' }}>
            Log in
          </Typography>
          <Box
            sx={{
              fontWeight: '600',
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              marginTop: '10px',
              color: '#5A5A5A',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px'
            }}
          >
            Don't have an account?
            <Box onClick={() => setStepValue(2)} style={{ textDecoration: 'none', padding: '0 5px', color: '#673ab7' }}>
              Sign Up
            </Box>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* Login Email */}
      <LoginForm step={step} handleLoginOrSignUp={handleLoginOrSignUp} userEmail={userEmail} />

      <Grid item xs={12} sx={{ position: 'relative', height: '50px', padding: '0 20px' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Divider sx={{ flexGrow: 1, marginTop: '25px', borderColor: '#DCDCDC' }} orientation="horizontal" />
          <Button
            variant="outlined"
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              margin: '0px !important',
              padding: '0px !important',
              zIndex: '99',
              background: ' #ffff',
              cursor: 'unset',
              py: 0.5,
              px: 7,
              borderColor: `${theme.palette.grey[100]} !important`,
              color: `${theme.palette.grey[900]}!important`,
              fontWeight: 500
            }}
            disableRipple
            disabled
            id="btnor"
          >
            or
          </Button>
        </Box>
      </Grid>
      <Box sx={{ p: '20px' }}>
        {/* <FacebookSocialButton
          handleClose={handleClose}
          handleLoginOrSignUp={handleLoginOrSignUp}
          setStepValue={setStepValue}
        /> */}
        <GoogleLoginButton
          handleClose={handleClose}
          handleLoginOrSignUp={handleLoginOrSignUp}
          setStepValue={setStepValue}
        />
      </Box>
    </Box>
  )
}
