import adminPages from './adminPages'
import pages from './pages'
import utilities from './utilities'

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
  items: [pages]
}

export const subMenu = {
  items: [utilities]
}

export const adminMenuItems = {
  items: [adminPages]
}
