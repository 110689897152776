import { gql } from '@apollo/client'

const getCampaignAnalytics = gql`
  query campaignAnalytics($to_date: String!, $from_date: String!, $campaign_id: String!) {
    campaignAnalytics(to_date: $to_date, from_date: $from_date, campaign_id: $campaign_id) {
      data
    }
  }
`

export { getCampaignAnalytics }
