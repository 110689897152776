import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useLocation } from 'react-router-dom'

// material-ui
import { Box, CardContent, Divider, Grid } from '@mui/material'
import AppLoader from './AppLoader'
import CustomizedBreadcrumbs from './CustomizedBreadcrumbs'

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    { children, subHeader = '', content = true, actions = null, breadcrumbs = null, sx = {}, title, ...others }: any,
    ref: any
  ) => {
    const location = useLocation()
    const pathname = location.pathname
    return (
      <Box ref={ref} {...others} sx={{ padding: { xs: '0', md: '20px' } }}>
        {breadcrumbs && <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />}
        <Grid
          className={pathname === '/' || pathname === '/dashboard' ? 'dashboard-heading' : 'top-header'}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingBottom: { xs: '20px', md: '10px' },
            // marginTop: { xs: '100px', md: 0 },
            display: { xs: 'block', md: 'flex' }
          }}
        >
          <Grid item>
            <Box
              sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '32px', letterSpacing: ' 0em', textAlign: 'left' }}
            >
              {title ? title : ''}
            </Box>
            {subHeader ? (
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0em',
                  textAlign: 'left'
                }}
              >
                {subHeader}
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item sx={{ pt: { xs: 2, sm: 0 } }}>
            {actions && actions}
          </Grid>
        </Grid>

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        <AppLoader />
        {content && <CardContent sx={{ p: 0 }}>{children}</CardContent>}
        {!content && children}
      </Box>
    )
  }
)

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
}

export default MainCard
