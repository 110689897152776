// Note: email, phone, name, last_name, first_name are Mixpanel predefined properties and should be send with $.

export type userData = {
    "$email": string
    user_type?: string
    first_name?: string
    last_name?: string
    company_name?: string
    phone?: number
}

export type pageView = {
    url: string
    page_name: string
    utm_source: string
    utm_medium: string
    utm_campaign: string
    platform?: string
}

export type signUpButtonClicked = {
    url: string
    page_name: string
    platform?: string
}

export type signUpInitiated = {
    signup_mode: string
    platform?: string
}

export type signUpCompleted = {
    "$email": string
    signup_mode: string
    balance: number
    user_role: string
    platform?: string
    platform_variant?: string
}

export type loggedIn = {
    "$email": string
    user_role: string
    platform?: string
}

export type newCampaignButtonClicked = {
    platform?: string
}

export type campaignCreationInitiated = {
    campaign_id: string
    campaign_action: string
    platform?: string
}

export type targettingDetailsAdded = {
    campaign_name: string
    campaign_id: string
    placement: string
    region: string
    country: string[]
    num_region: number
    num_countries: number
    is_brand_awareness: boolean
    is_sales: boolean
    is_app_promotion: boolean
    is_email_leads: boolean
    is_website_traffic: boolean
    is_social_following: boolean
    interests: string
    device_type: string
    mobile_tablet: string
    frequency_cap: string
    frequency_timeframe: string
    platform?: string
}

export type campaignBudgetDetailsAdded = {
    campaign_name: string
    campaign_id: string
    total_budget: number
    daily_budget: number
    cpm_bid: number
    campaign_balance: number
    platform?: string
}

export type campaignBudgetSubmitted = {
    budget_submitted: number
    campaign_name: string
    campaign_id: string
    campaign_balance: number
    platform?: string
}

export type uploadBannerClicked = {
    platform?: string
}

export type bannerUploaded = {
    banner_name: string
    platform?: string
}

export type getBannerClicked = {
    platform?: string
}

export type campaignPreviewed = {
    campaign_name: string
    campaign_id: string
    url: string
    campaign_source: string
    campaign_medium: string
    campaign_campaign: string
    term: string
    platform?: string
}

export type campaignPublished = {
    campaign_name: string
    campaign_id: string
    campaign_budget: string
    daily_budget: string
    cpm_bid: string
    frequency: string
    frequency_timeframe: string
    platform?: string
    platform_variant?: string
}

export type webappButtonClicked = {
    button_name: string
    platform?: string
}

export type topUpInitiated = {
    amount: number
    payment_mode: string
    payment_plan: string
    platform?: string
}

export type topUpCompleted = {
    amount: number
    payment_mode: string
    payment_plan: string
    platform?: string
}

export type homePageButtonClicked = {
    button_name: string
    platform?: string
}

export type feedbackSubmitted = {
    subject: string
    feedback: string
}

export type onboardingCompleted = {
    platform_variant: string
    platform?: string
}

export enum Event {
    PageViewed = "page_viewed",
    SignupButtonClicked = "signup_button_clicked",
    SignupInitiated = "signup_initiated",
    SignupCompleted = "signup_completed",
    LoggedIn = "logged_in",
    NewCampaignButtonClicked = "new_campaign_button_clicked",
    CampaignCreationInitiated = "campaign_creation_initiated",
    TargetingDetailsAdded = "targeting_details_added",
    CampaignBudgetDetailsAdded = "campaign_budget_details_added",
    CampaignBudgetSubmitted = "campaign_budget_submitted",
    UploadBannerClicked = "upload_banner_clicked",
    BannerUploaded = "banner_uploaded",
    GetBannerClicked = "get_banner_clicked",
    CampaignPreviewed = "campaign_previewed",
    CampaignPublished = "campaign_published",
    WebappButtonClicked = "webapp_button_clicked",
    TopUpInitiated = "topup_initiated",
    TopUpCompleted = "topup_completed",
    HomepageButtonClicked = "homepage_button_clicked",
    FeedbackSubmitted = "feedback_submitted",
    OnboardingCompeleted = "onboarding_completed"
}
