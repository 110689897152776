import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

export default function DatePickerDialog(props: any) {
  const { open, handleClose, date, setDate, setDateConfirmed } = props
  const colorCalender: any = '#5a27dd'
  const today: Date = new Date()
  const handleConfirm = () => {
    if (setDateConfirmed) {
      setDateConfirmed(true)
    }
    handleClose()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      sx={{ '.MuiPaper-root': { maxWidth: '775px' } }}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h2" fontSize="18px" sx={{ marginBottom: '5px' }}>
            Select date
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0, pb: 0 }}>
        <DateRangePicker
          preventSnapRefocus={true}
          className="dateCalenderx"
          rangeColors={[colorCalender]}
          color={colorCalender}
          onChange={(item: any) => setDate(item.selection)}
          showDateDisplay={false}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[date]}
          direction="horizontal"
          maxDate={setDateConfirmed ? today : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button id="btnCancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button id="btnConfirm" variant="contained" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
