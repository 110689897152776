import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'

// Components
import Backdrop from '../Elements/Backdrop'
// Assets
import { Box, Button, Grid, IconButton, useTheme } from '@mui/material'
import { HiMenuAlt3 } from 'react-icons/hi'
import AuthModal from '../../../../views/modals/AuthDialog'
import Logo from '../../../MainLayout/LogoSection/Logo'
import SidebarHome from './SidebarHome'
import { homepage_button_clicked, signup_completed } from '../../../../services/mixpanel'
const LiveChat = process.env.PUBLIC_URL + '/images/LiveChat.svg'

// import { sendEmailVerifiedWebhook } from '../../../../services/zapier'

export default function TopNavbar() {
  const theme: any = useTheme()

  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [userEmail, setUserEmail] = useState<string>('')


  const urlParams = new URLSearchParams(window.location.search);
  const emailVerified = urlParams.get('emailVerified');

  const userEmailFromURL = urlParams.get('email') || ''; // Fallback to 'test' if no email param

  useEffect(() => {
    if (emailVerified === 'true') {
      setUserEmail(userEmailFromURL)
      signup_completed({ "$email": userEmailFromURL, signup_mode: 'email', balance: 0, user_role: 'customer' })
      // sendEmailVerifiedWebhook({email: userEmailFromURL})
      setTimeout(() => {
        // Your operation goes here.
        setOpen(true)
      }, 500);

    }
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  return (
    <Box>
      <SidebarHome sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <nav
        className="flexCenter animate lightBg"
        style={{
          height: y > 100 ? '60px' : '80px',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%'
          }}
          className="container flexSpaceCenter"
        >
          <Link offset={-60} className="pointer flexNullCenter" to="home" smooth={true}>
            <Logo />
          </Link>
          <Box
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              borderRadius: '0px',
              color: theme.palette.secondary.dark,
              width: 'auto',
              height: 'auto',
              mr: 0,
              '@media (min-width: 760px)': {
                display: 'none'
              }
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item style={{ display: 'flex' }}>
                <a
                  id="LiveChatLink"
                  href="https://t.me/DotAds_Bot?start=64d0f2cf2f590d0625058864"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', alignItems: 'center',marginRight: '-5px',color: 'black' }}
                  onClick={() => {
                    homepage_button_clicked({ button_name: 'live_chat' })
                  }}
                  >
                  Chat
                  <img
                    src={LiveChat}
                    alt="Live Chat"
                    style={{ width: '30px', height: '30px', marginBottom: '7px', marginLeft: '-3px' }}
                    />
                </a>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <IconButton sx={{color: theme.palette.secondary.dark}}>
                  <HiMenuAlt3 onClick={() => toggleSidebar(!sidebarOpen)}  />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Box
            component={'ul'}
            className="flexNullCenter"
            sx={{
              display: 'flex',
              '@media (max-width: 760px)': {
                display: 'none'
              }
            }}
          >
            <li className="semiBold font15 pointer">
              <Link
                offset={-60}
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="Features"
                id="FeaturesLink"
                spy={true}
                smooth={true}
                onClick={() => { homepage_button_clicked({ button_name: 'features' }) }}
              >
                Features
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                offset={-60}
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="Testimonials"
                id="TestimonialsLink"
                spy={true}
                smooth={true}
                onClick={() => { homepage_button_clicked({ button_name: 'testimonials' }) }}
              >
                Testimonials
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                offset={-60}
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="AboutUs"
                id="AboutUsLink"
                spy={true}
                smooth={true}
                onClick={() => { homepage_button_clicked({ button_name: 'about_us' }) }}
              >
                About Us
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <a
                id="Bookdemo"
                href="https://dotaudiences.com/advertise/demo/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '10px 15px' }}
                onClick={() => { homepage_button_clicked({ button_name: 'book_demo' }) }}
              >
                Book Demo
              </a>
            </li>
            <li className="semiBold font15 pointer">
              <a
                id="MarketplaceLink"
                href="https://marketplace.dotaudiences.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '10px 15px' }}
                onClick={() => { homepage_button_clicked({ button_name: 'marketplace' }) }}
              >
                Marketplace
              </a>
            </li>
            <li className="semiBold font15 pointer">
              <a
                id="LiveChatLink"
                href="https://t.me/DotAds_Bot?start=64d0f2cf2f590d0625058864"
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '10px 15px', display: 'flex', alignItems: 'center' }}
                onClick={() => {
                  homepage_button_clicked({ button_name: 'live_chat' })
                }}
              >
                Live Chat
                <img
                  src={LiveChat}
                  alt="Live Chat"
                  style={{ width: '30px', height: '30px', marginBottom: '7px', marginLeft: '-3px' }}
                />
              </a>
            </li>
          </Box>
          <Box
            component={'ul'}
            sx={{
              '@media (max-width: 760px)': {
                display: 'none'
              }
            }}
            className="flexNullCenter"
          >
            <li>
              <Button
                id="btnLog"
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => {
                  handleClickOpen()
                  setStep(1)
                }}
              >
                Log in
              </Button>
              <Button
                id="btnSignup"
                onClick={() => {
                  handleClickOpen()
                  setStep(2)
                }}
                variant="contained"
              >
                Sign Up
              </Button>
            </li>
          </Box>
        </Box>
      </nav>
      {open && <AuthModal open={open} userEmail={userEmail} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
