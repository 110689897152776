import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail } from '../customization'

export const getCampaignCreated = (client: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCampaignCreatedCounts,
        fetchPolicy: 'no-cache'
      })
      .then((respose: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_CREATED,
          data: respose?.data?.campaignCreated
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message))
      })
  }
}
