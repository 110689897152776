// material-ui
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { RiHome3Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MainCard from '../../layout/ui-components/MainCard'
import { getResetSearch } from '../../store/actions/common'
import { UserType } from '../../util/constants'
import CampaignsList from './CampaignList'
import CreativeGalleryList from './CreativeList'
import UserTable from './User'

// ==============================|| Campaigns PAGE ||============================== //
const SearchResults = () => {
  const searchData = useSelector((state: any) => state.commonReducer.searchData)
  const userDetails = useSelector((state: any) => state.settingReducer.userDetails)

  const dispatch: any = useDispatch()
  const breadcrumbs = [
    <Link key="1" color="inherit" to="/">
      <RiHome3Line />
    </Link>,
    <Typography key="2" color="text.primary">
      Search Results
    </Typography>
  ]
  useEffect(() => {
    let req = {
      resetSearch: null
    }
    dispatch(getResetSearch(req))
  }, [])

  return (
    <MainCard title="Search Results" breadcrumbs={breadcrumbs} subHeader="View Search Results">
      {searchData?.campaigns?.length > 0 && <CampaignsList />}
      {searchData?.creatives?.length > 0 && userDetails?.user_type === UserType.CUSTOMER && <CreativeGalleryList />}
      {searchData?.users?.length > 0 && <UserTable />}
    </MainCard>
  )
}
export default SearchResults
