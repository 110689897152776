// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

export const initialState = {
  users: {},
  selectedFilter: {
    user_type: [],
    balance: false
  }
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GETTING_USERS_SUCCESS:
      return updateObject(state, {
        loading: false,
        users: action?.data
      })
    case actionTypes.GETTING_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        users: {}
      })
    case actionTypes.SET_ROLES:
      return updateObject(state, {
        loading: false,
        selectedFilter: action?.data
      })
    default:
      return state
  }
}

export default userReducer
