// ==============================|| UTILITIES MENU ITEMS ||============================== //

import { FaShoppingCart } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { IoMdHelpBuoy } from 'react-icons/io'
import externalLink from '../../assets/external-link.png'

const utilities = {
  id: 'utilities',
  type: 'group',
  children: [
    {
      id: 'menu-marketplace',
      title: 'Marketplace',
      type: 'item',
      url: '//marketplace.dotaudiences.com/?utm_source=ad_platform',
      icon: <FaShoppingCart />,
      target: '_blank',
      linkIcon: externalLink
    },
    {
      id: 'menu-help',
      title: 'Help',
      type: 'item',
      url: '//help.dotaudiences.com/category/advertisers',
      icon: <IoMdHelpBuoy />,
      target: '_blank',
      linkIcon: null
    },
    {
      id: 'menu-settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: <FiSettings />,
      linkIcon: null
    }
  ]
}

export default utilities
