import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'
import { useSelector } from 'react-redux'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CopyToClipBoard(props: any) {
  const [show, setShow] = React.useState(false)
  const { open, handleClose } = props
  const isUpdate = useSelector((state: any) => state.campaignCreatedReducer.isUpdated)
  const handleChange = () => {
    navigator.clipboard.writeText(isUpdate)
    setShow(true)
  }
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 'auto'
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box>
          <Typography variant="h2" fontSize="18px" sx={{ marginBottom: '5px' }}>
            Copy to Clipboard
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This campaign ID you need to place in the 'AdGroup Name' field when creating campaign.
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <TextField disabled value={isUpdate} size="small" />
        <Button
          id="Copy"
          sx={{ marginLeft: '2px' }}
          variant="contained"
          color={show ? 'success' : 'primary'}
          onClick={handleChange}
        >
          {show ? <span>Copied.!</span> : ' Copy to Clipboard'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
