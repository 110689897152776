import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'js-cookie'
import { getNewTokenFromFirebase } from '../../services/auth'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL
  //   fetch: fetch
})

const authLink = setContext(async (_, { headers }) => {
  const userToken: any = Cookies.get('dot-ad-token')
  const idToken = await getNewTokenFromFirebase()

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${idToken ? idToken : userToken}`

      // authorization: idToken ? 'Bearer ' + idToken : userToken ? 'Bearer ' + userToken : ''
    }
  }
})

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache()
})

const initialState = {
  client: client
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
